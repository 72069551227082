import { Search } from 'react-router-dom';
import { Dayjs, isDayjs } from 'dayjs';
import { formatLocalDate } from './dateUtils';

const normalizeSingleValue = (value: Dayjs | string): string => {
  if (isDayjs(value)) {
    return formatLocalDate(value as Dayjs);
  }
  return value;
};

// Arrays of arrays or arrays of objects are not supported
const normalizeValue = (value: Dayjs | string | Array<Dayjs | string>): string | string[] => {
  if (Array.isArray(value)) {
    return value.map((item) => normalizeSingleValue(item));
  }
  return normalizeSingleValue(value);
};

export const toQueryString = (params: Record<string, any>): string => {
  const queryStringObject = toQueryStringObject(params);
  const entries: string[][] = [];
  Object.entries(queryStringObject).forEach((entry) => {
    const key = entry[0];
    const value = entry[1];
    if (value === null || value === undefined) {
      return;
    }
    if (Array.isArray(value)) {
      value.forEach((arrayValue) => entries.push([key, arrayValue]));
    } else {
      entries.push([key, value]);
    }
  });
  const searchParams = new URLSearchParams(entries);
  return searchParams.toString();
};

export const toQueryStringObject = (params: Record<string, any>): Record<string, any> => {
  const sanitizedParams = Object.entries(params)
    .filter((entry) => entry[1] !== null)
    .filter((entry) => entry[1] !== undefined)
    .filter((entry) => !Array.isArray(entry[1]) || entry[1].length > 0)
    .map((entry) => [entry[0], normalizeValue(entry[1])]);
  return Object.fromEntries(sanitizedParams);
};

export const parseQueryString = (search: Search) => {
  const normalizedSearch = search.startsWith('?') ? search.substring(1) : search;
  const searchParams = new URLSearchParams(normalizedSearch);
  const result: Record<string, any> = {};
  for (const key of searchParams.keys()) {
    const values = searchParams.getAll(key);
    if (values.length > 1) {
      result[key] = values;
    } else if (values[0] !== '') {
      result[key] = values[0];
    }
  }
  return result;
};

export const normalizeParsedQueryFieldValueToArray = (parsedValue: any): Array<any> => {
  if (!parsedValue) {
    return [];
  } else if (Array.isArray(parsedValue)) {
    return parsedValue;
  } else {
    return [parsedValue];
  }
};

export const convertWithDefaultNumber = (value: any, defaultValue: any) => {
  return value && parseInt(value as string, 10) > 0 ? parseInt(value as string, 10) : defaultValue;
};
