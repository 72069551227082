import { LeafMenuEntryType, MenuEntryType } from './menuTypes';
import { Permission } from '../../../security/types';
import { allUserCompanyTypes } from '../../../user/userUtils';
import { MonitorHeart, People, PostAdd, SyncAlt } from '@mui/icons-material';
import {
  getDirectionAdmin,
  getGedSupervision,
  getReassignDemands,
  getTemplatesAdmin,
  getUsersAdmin,
} from '../../../navigation/navigationUrlService';
import ArticleIcon from '@mui/icons-material/Article';
import { useDemandListDefaultUrlBuilder } from '../../../demand/hooks/useDemandListDefaultUrlBuilder';
import { useAppContext } from '../../../AppContext';
import ApartmentIcon from '@mui/icons-material/Apartment';
import { UserCompany } from '../../../user/commonTypes';
import { DirectionLabelUsage } from '../../../components/direction/ImportAgencyTypes';

export const useMenuData = () => {
  const demandsListUrl = useDemandListDefaultUrlBuilder();
  const { directionAdmin } = useAppContext();

  const usersAdmin: LeafMenuEntryType = {
    label: 'Gestion des utilisateurs',
    canBeActivatedByChildrenPages: true,
    permissions: [Permission.ADMINISTRATE_USERS],
    userCompanies: allUserCompanyTypes,
    icon: People,
    getNavigationDefinition: getUsersAdmin,
  };

  const demandsList: LeafMenuEntryType = {
    label: 'Gestion des demandes',
    canBeActivatedByChildrenPages: true,
    permissions: [Permission.READ_DEMAND],
    userCompanies: allUserCompanyTypes,
    icon: ArticleIcon,
    getNavigationDefinition: () => ({
      getUrl: () => demandsListUrl,
    }),
  };

  const templatesAdmin: LeafMenuEntryType = {
    label: 'Gestion des modèles de documents',
    canBeActivatedByChildrenPages: true,
    permissions: [Permission.ADMINISTRATE_TEMPLATES],
    userCompanies: allUserCompanyTypes,
    icon: PostAdd,
    getNavigationDefinition: getTemplatesAdmin,
  };

  const directionAdminMenu: LeafMenuEntryType = {
    label: directionAdmin ? directionAdmin.buildDirectionLabel(DirectionLabelUsage.MENU_TITLE) : '',
    canBeActivatedByChildrenPages: true,
    permissions: [Permission.ADMINISTRATE_DIRECTION],
    userCompanies: [UserCompany.FDJ, UserCompany.PMU],
    icon: ApartmentIcon,
    getNavigationDefinition: getDirectionAdmin,
  };

  const reassignDemandsMenu: LeafMenuEntryType = {
    label: 'Ré-affectation des demandes',
    canBeActivatedByChildrenPages: true,
    permissions: [Permission.ADMINISTRATE_DIRECTION],
    userCompanies: [UserCompany.FDJ, UserCompany.PMU],
    icon: SyncAlt,
    getNavigationDefinition: getReassignDemands,
  };

  const gedSupervision: LeafMenuEntryType = {
    label: "Supervision de l'envoi des avis signés à la GED",
    canBeActivatedByChildrenPages: true,
    permissions: [Permission.SIGNED_VERDICT_SUPERVISION],
    userCompanies: [UserCompany.FDJ],
    icon: MonitorHeart,
    getNavigationDefinition: getGedSupervision,
  };

  const menuData: MenuEntryType[] = [
    usersAdmin,
    demandsList,
    templatesAdmin,
    directionAdminMenu,
    reassignDemandsMenu,
    gedSupervision,
  ];

  return menuData;
};
