import { PasswordApiUpdateCommand, PasswordUpdateFormValues } from './passwordTypes';

export function toFormValues(login: string): PasswordUpdateFormValues {
  return {
    login,
    plainTextCurrentPassword: null,
    plainTextNewPassword: null,
    plainTextNewPasswordConfirmation: null,
  };
}

export function toApiUpdateCommand(formValues: PasswordUpdateFormValues): PasswordApiUpdateCommand {
  return {
    login: formValues.login,
    plainTextCurrentPassword: formValues.plainTextCurrentPassword!,
    plainTextNewPassword: formValues.plainTextNewPassword!,
    plainTextNewPasswordConfirmation: formValues.plainTextNewPasswordConfirmation!,
  };
}

export function validatePassword(password?: string | null): string[] {
  if (!password) {
    return []; // Handled by another validator
  }

  const errors: string[] = [];

  if (password.length < 8) {
    errors.push('Au moins 8 caractères');
  }
  if (!containsOneLowerCase(password)) {
    errors.push('Au moins une minuscule');
  }
  if (!containsOneUpperCase(password)) {
    errors.push('Au moins une majuscule');
  }
  if (!containsOneDigit(password)) {
    errors.push('Au moins un chiffre');
  }
  if (!containsOneSpecialChar(password)) {
    errors.push('Au moins un caractère spécial');
  }
  return errors;
}

export function validatePasswordConfirmation(confirmation?: string | null, allValues?: object | undefined) {
  const formValues = allValues as PasswordUpdateFormValues | undefined;
  const password = formValues?.plainTextNewPassword;
  if (!password || !confirmation || password === confirmation) {
    return undefined;
  }
  return 'Ne correspond pas au mot de passe saisi';
}

function containsOneLowerCase(password: string): boolean {
  return /[a-z]/.test(password);
}

function containsOneUpperCase(password: string): boolean {
  return /[A-Z]/.test(password);
}

function containsOneDigit(password: string): boolean {
  return /\d/.test(password);
}

function containsOneSpecialChar(password: string): boolean {
  return /[-!@#$%^&*()_+|~=\\`{}[\]:";'<>?,./]/.test(password);
}
