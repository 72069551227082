import { createTheme } from '@mui/material';
import { grey, indigo, red } from '@mui/material/colors';

/**
 * common theme that can be overridden by the apps if necessary
 * (for example, with https://mui.com/material-ui/customization/theming/#api)
 */
const baseTheme = createTheme({
  palette: {
    primary: {
      main: indigo[600],
    },
    secondary: {
      main: '#19857b',
    },
    error: {
      main: red.A400,
    },
    background: {
      default: grey[300],
    },
  },
  components: {
    MuiFormControlLabel: {
      styleOverrides: {
        label: ({ theme }) => ({
          '&.Mui-disabled': {
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          '&:not(:first-of-type)': {
            marginTop: 20,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          '&.MuiTableCell-head': {
            fontWeight: 600,
          },
        },
      },
    },
  },
});

export default baseTheme;
