import MainActionButton from '../../components/buttons/MainActionButton';
import React from 'react';
import { csrfToken } from '../../api/apiHelper';

export const LogoutButton = () => {
  // done this way to accommodate for scenarios where we may receive specific redirect responses
  // (e.g.: to the SAML2 IdP for global sign out)
  return (
    <form method="post" action="/logout">
      <MainActionButton dataTestId="logout-button" label="Se déconnecter" type="submit" />
      <input type="hidden" name="_csrf" value={csrfToken()} />
    </form>
  );
};
