export const trim = (str: string | undefined) => str?.trim();
export const uppercase = (str: string | undefined) => str?.toUpperCase();
export const capitalize = (str: string | undefined) => {
  if (!str) {
    return str;
  }
  return str.replaceAll(/((\p{P}|\p{S}|\s|\d)+.)|(^.)/gu, (m) => m.toUpperCase());
};

/**
 * Performs a locale compare that considers e, é, è, É, È, E to represent the same letter, making it suitable
 * for most display purposes.
 * @return -1 if str1 is sorted before str2, 0 when equal, 1 if str1 is sorted after str2
 */
export const caseInsensitiveCompare = (str1: string, str2: string) =>
  str1.localeCompare(str2, 'fr', { sensitivity: 'base' });
