import ApiError from '../../api/ApiError';
import { buildSubmissionErrors } from './formUtils';
import { Severity } from '../../utils/severityUtils';
import { getGenericFormValidationErrorSnackbar } from '../../snackbar/snackbarUtils';
import useSnackbar from '../../snackbar/useSnackbar';
import { captureException } from '@sentry/react';

export const useFormSubmitHelper = () => {
  const { enqueueSnackbar } = useSnackbar();

  async function submitForm(submitAction: () => Promise<void>) {
    try {
      await submitAction();
    } catch (e) {
      const apiError = e as ApiError;
      const submissionErrors = buildSubmissionErrors(e as ApiError);

      if (!submissionErrors) {
        // show with snackbars for those errors without fieldName
        (apiError?.validationErrors ?? [])
          .filter((validationError) => !validationError.fieldName)
          .forEach((validationError) => {
            enqueueSnackbar({ content: validationError.message, severity: Severity.ERROR });
          });
      } else {
        enqueueSnackbar(getGenericFormValidationErrorSnackbar());
      }
      captureException(e);
      return submissionErrors;
    }
  }

  return submitForm;
};
