import { Attachment, Demand } from '../../demandTypes';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Table,
  TableBody,
  TableHead,
  TableRow,
} from '@mui/material';
import { useAppContext } from '../../../AppContext';
import { useEffect, useState } from 'react';
import { attachmentKey, buildAttachmentRenderDataList } from '../../attachmentUtils';
import TableCell from '../../../components/table/TableCell';
import { UpdateAttachmentRowGroup } from './UpdateAttachmentRowGroup';
import { AttachmentRenderData } from '../../attachmentTypes';
import MainActionButton from '../../../components/buttons/MainActionButton';

type Props<T extends Demand> = {
  demand: T;
  open: boolean;
  onClose: () => void;
  // whether the popup should be displayed for the case where user add attachments after the validation of demand
  afterValidationDisplay: boolean;
};

export const UpdateAttachmentsDialog = <T extends Demand>({
  open,
  onClose,
  demand,
  afterValidationDisplay,
}: Props<T>) => {
  const {
    demand: { attachmentHelper },
  } = useAppContext();
  const [renderData, setRenderData] = useState<readonly AttachmentRenderData<any>[]>(() =>
    buildAttachmentRenderDataList(demand, attachmentHelper),
  );
  const [pendingUploads, setPendingUploads] = useState<readonly string[]>([]);
  const canCloseDialog = pendingUploads.length === 0;

  useEffect(() => {
    setRenderData(buildAttachmentRenderDataList(demand, attachmentHelper));
    setPendingUploads([]);
  }, [demand, attachmentHelper]);

  const onAttachmentUploaded = (attachment: Attachment) => {
    setRenderData((prevState) => {
      return prevState.map((renderData) => {
        if (matchesRenderData(renderData, attachment)) {
          return {
            ...renderData,
            attachments: [...renderData.attachments, { info: attachment, uploadedAt: new Date().getTime() }],
          };
        }
        return renderData;
      });
    });
  };

  const onAttachmentRemoved = (attachment: Attachment) => {
    setRenderData((prevState) => {
      return prevState.map((renderData) => {
        if (matchesRenderData(renderData, attachment)) {
          return {
            ...renderData,
            attachments: renderData.attachments.filter((a) => a.info.id !== attachment.id),
          };
        }
        return renderData;
      });
    });
  };

  const onUploadStatusChange = (attachmentKey: string, status: 'pending' | 'idle') => {
    if (status === 'pending') {
      setPendingUploads((prevState) => [...prevState, attachmentKey]);
    } else {
      setPendingUploads((prevState) => prevState.filter((k) => k !== attachmentKey));
    }
  };

  return (
    <Dialog open={open} onClose={canCloseDialog ? onClose : undefined} fullWidth>
      <DialogTitle>Modifier les pièces justificatives</DialogTitle>
      <DialogContent>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Pièce</TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {renderData.map((rd) => (
              <UpdateAttachmentRowGroup
                key={attachmentKey(rd)}
                attachmentData={rd}
                uploadStatus={pendingUploads.includes(attachmentKey(rd)) ? 'pending' : 'idle'}
                onAttachmentUploaded={onAttachmentUploaded}
                onAttachmentRemoved={onAttachmentRemoved}
                onUploadStatusChange={onUploadStatusChange}
                afterValidationDisplay={afterValidationDisplay}
              />
            ))}
          </TableBody>
        </Table>
      </DialogContent>
      <DialogActions>
        <MainActionButton
          label="Fermer"
          action={canCloseDialog ? onClose : () => {}}
          disabled={!canCloseDialog}
          dataTestId="close-dialog-btn"
        />
      </DialogActions>
    </Dialog>
  );
};

const matchesRenderData = (renderData: AttachmentRenderData<any>, attachment: Attachment) => {
  return (
    renderData.attachmentType === attachment.type &&
    renderData.candidateInfo?.index === (attachment.candidateIndex ?? undefined)
  );
};
