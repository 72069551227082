import { useSocieteMereAuthenticatedCurrentUser } from '../../security/hooks';
import { SocieteMereDemand } from '../societeMereDemandTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { agreementTypesForCompany } from '../societeMereDemandUtils';

export const useSocieteMereAgreementTypeOptionsFormHelper = (existingDemand: SocieteMereDemand | undefined) => {
  const { userDetails } = useSocieteMereAuthenticatedCurrentUser();
  const getCompany = () => {
    if (existingDemand) {
      return existingDemand.company;
    } else {
      if (userDetails.company === UserCompany.MINISTRY) {
        throw new Error('ministry cannot create demand');
      }
      return userDetails.company;
    }
  };
  return { agreementTypeOptions: agreementTypesForCompany(getCompany()) };
};
