import { FormMode } from '../../form/helper/FormModeProvider';
import { DemandDetailPage } from './DemandDetailPage';
import { useNavigationHelper } from '../../navigation/useNavigationHelper';
import { getDemandDetail } from '../../navigation/navigationUrlService';

export const DemandEditPage = () => {
  const { navigateToDynamicRoute } = useNavigationHelper();

  return (
    <DemandDetailPage
      mode={FormMode.EDIT}
      onSubmitSuccess={(demand) => navigateToDynamicRoute(getDemandDetail(), demand.id)}
    />
  );
};
