import { TemplateView } from '../templateTypes';
import { Link, TableRow } from '@mui/material';
import TableCell from '../../components/table/TableCell';
import { templateLabels, templateUrl } from '../templateUtils';
import { UploadTemplateButton } from './UploadTemplateButton';
import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';

type TemplateItemProps = {
  templateView: TemplateView;
};

export const TemplateRow = ({ templateView }: TemplateItemProps) => {
  const {
    userDetails: { company },
  } = useAuthenticatedCurrentUser();
  const { template } = templateView;
  const templateLabel = templateLabels(company)[template.filename];

  return (
    <TableRow>
      <TableCell>
        <Link href={templateUrl(template)}>{templateLabel}</Link>
      </TableCell>
      <TableCell align="right">
        <UploadTemplateButton templateView={templateView} />
      </TableCell>
    </TableRow>
  );
};
