import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import { TableCell } from '@eogile/agrements-common/src/components/table/TableCell';
import { Grid, Link, Table, TableBody, TableHead, TableRow } from '@mui/material';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { FieldArray } from 'react-final-form-arrays';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import { SocieteMereDemand } from '../societeMereDemandTypes';
import { SetNonNullable } from 'type-fest';
import { useDataLoader } from '@eogile/agrements-common/src/hooks/useDataLoader';
import { FetchErrorCard } from '@eogile/agrements-common/src/components/cards/FetchErrorCard';
import { NavLink } from 'react-router-dom';
import { getDemandDetail } from '@eogile/agrements-common/src/navigation/navigationUrlService';
import { getRequesterLabel } from '../../utils/societeMereLabelUtils';
import { societeMereAgreementTypeLabels, societeMereNatureTypeLabels } from '../societeMereDemandUtils';
import { formatLocalDateForDisplay } from '@eogile/agrements-common/src/utils/dateUtils';
import dayjs from 'dayjs';
import { demandStatusLabels } from '@eogile/agrements-common/src/demand/demandUtils';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';

type Props = {
  demand: SocieteMereDemand | undefined;
};

export const SocieteMereLinkedDemandsSection = ({ demand }: Props) => {
  const mode = useFormMode();
  return (
    <SectionCard title="Demandes liées">
      {mode === FormMode.READ ? <ReadModeLinkedDemands demand={demand!} /> : <EditModeLinkedDemands />}
    </SectionCard>
  );
};

const ReadModeLinkedDemands = ({ demand }: SetNonNullable<Props, 'demand'>) => {
  const {
    data: linkedDemands,
    loading,
    inError,
  } = useDataLoader<SocieteMereDemand[]>(`/api/demands/${demand.id}/linked`);

  if (inError) {
    return <FetchErrorCard />;
  }

  return (
    <FormGridContainer>
      {!loading && linkedDemands?.length === 0 && (
        <Grid item xs={12}>
          Aucune demande liée
        </Grid>
      )}
      {linkedDemands && linkedDemands.length > 0 && (
        <Grid item xs={12}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>N° Chrono</TableCell>
                <TableCell>Nom / dénomination</TableCell>
                <TableCell>Type d'agrément</TableCell>
                <TableCell>Nature</TableCell>
                <TableCell>Date de validation</TableCell>
                <TableCell>Date de réception</TableCell>
                <TableCell>Statut</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {linkedDemands.map((linkedDemand) => (
                <TableRow key={linkedDemand.id}>
                  <TableCell>{linkedDemand.chronoNumber ?? ''}</TableCell>
                  <TableCell>
                    <Link component={NavLink} to={getDemandDetail().getUrl(linkedDemand.id)}>
                      {getRequesterLabel(linkedDemand.requester)}
                    </Link>
                  </TableCell>
                  <TableCell>
                    <span title={societeMereAgreementTypeLabels[linkedDemand.agreementType]}>
                      {linkedDemand.agreementType}
                    </span>
                  </TableCell>
                  <TableCell>{linkedDemand.nature ? societeMereNatureTypeLabels[linkedDemand.nature] : ''}</TableCell>
                  <TableCell>
                    {linkedDemand.validationDateTime
                      ? formatLocalDateForDisplay(dayjs(linkedDemand.validationDateTime))
                      : ''}
                  </TableCell>
                  <TableCell>
                    {linkedDemand.receptionDateTime
                      ? formatLocalDateForDisplay(dayjs(linkedDemand.receptionDateTime))
                      : ''}
                  </TableCell>
                  <TableCell>{demandStatusLabels[linkedDemand.status]}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Grid>
      )}
    </FormGridContainer>
  );
};

const EditModeLinkedDemands = () => {
  return (
    <FormGridContainer>
      <FieldArray name="linkedDemandIds">
        {({ fields }) => (
          <>
            {fields.map((name) => (
              <FormGridItem key={name}>
                <TextFieldAdapter name={name} label="Demande liée" />
              </FormGridItem>
            ))}
          </>
        )}
      </FieldArray>
    </FormGridContainer>
  );
};
