import { isLeafMenuEntry, isNodeMenuEntry, MenuEntryType } from './menuTypes';
import { hasAnyPermission, hasAnyUserCompany } from '../../../security/securityUtils';
import { CurrentUserDetails, Permission } from '../../../security/types';

/**
 * Return a boolean indicating whether or not the current user can see the given
 * menu entry.
 *
 * For a leaf menu entry, the user must have at least one of the required profile
 * and its user company type (ministry, FDJ, ...) must be compatible with the
 * menu entry.
 *
 * For a node menu entry, the entry is visible if the user has access to at least
 * one of the children entries.
 *
 * @param user The current user.
 * @param menuEntry The menu entry.
 */
export const canSeeMenuEntry = (user: CurrentUserDetails, menuEntry: MenuEntryType): boolean => {
  if (isLeafMenuEntry(menuEntry)) {
    return hasAnyRequiredPermissions(user, menuEntry.permissions) && hasAnyUserCompany(user, menuEntry.userCompanies);
  }
  if (isNodeMenuEntry(menuEntry)) {
    return menuEntry.childrenEntries.some((childMenuEntry) => canSeeMenuEntry(user, childMenuEntry));
  }
  throw new Error('Menu entry is not a node and not a leaf');
};

const hasAnyRequiredPermissions = (user: CurrentUserDetails, permissions: Permission[]) => {
  return permissions.length === 0 || hasAnyPermission(user, permissions);
};

/**
 * Return a boolean indicating whether or not a menu entry is active for the given path.
 *
 * A leaf menu entry is active if the given path starts with the entry URL and if
 * the entry can be activated by children pages. For example, the home page menu
 * entry cannot be activated by children pages.
 *
 * A node menu entry is active if one its children is active for the given path.
 *
 * @param menuEntry The menu entry.
 * @param pathname The path (without the scheme and domain)
 */
export const isActive = (menuEntry: MenuEntryType, pathname: string): boolean => {
  if (isLeafMenuEntry(menuEntry)) {
    const menuEntryUrl = menuEntry.getNavigationDefinition().getUrl();
    if (menuEntry.canBeActivatedByChildrenPages) {
      return pathname.startsWith(menuEntryUrl);
    }
    return pathname === menuEntryUrl;
  }
  if (isNodeMenuEntry(menuEntry)) {
    return menuEntry.childrenEntries.some((childMenuEntry) => isActive(childMenuEntry, pathname));
  }
  throw new Error('Menu entry is not a node and not a leaf');
};

/**
 * Return "padding left" factor for menu entries
 *
 * @param depth the menu entry depth in the menu tree.
 */
export const getPaddingLeftFactor = (depth: number): number => {
  if (depth === 0) {
    return 1;
  }
  if (depth === 1) {
    return 7;
  }
  return 10;
};
