import { PaginationAndSortCommand } from '../components/table/tableTypes';

/**
 * Return a boolean indicating whether the given filter values contains at least one defined
 * filter value.
 *
 * Fields related to pagination and sort are excluded from the algorithm.
 */
export function hasAtLeastOneFilterValue(filterValues: PaginationAndSortCommand<any>): boolean {
  const { page, pageSize, sortOrder, sortField, ...otherFields } = filterValues;
  return Object.values(otherFields).some((value) => Boolean(value) || value === 0); // 0 is a valid filter value.
}
