import React from 'react';
import { CardHeader } from '@mui/material';

type Props = {
  initialFormValues:
    | Readonly<{
        id: string | null;
        firstName: string | null;
        lastName: string | null;
      }>
    | undefined;
};

const UserCardHeader = ({ initialFormValues }: Props) => {
  if (!initialFormValues) {
    return null;
  }
  const { id, firstName, lastName } = initialFormValues;

  if (!id) {
    return <CardHeader title="Nouvel utilisateur" />;
  }
  return <CardHeader title={`${firstName} ${lastName}`} />;
};

export default UserCardHeader;
