import { useEffect, useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import { getDataLoadingErrorSnackbar } from '../snackbar/snackbarUtils';
import useSnackbar from '../snackbar/useSnackbar';

/**
 * Hook that centralizes the logic of loading a resource from the server from a promise.
 *
 * @param promiseGetter A function that returns the promise used to load the data.
 * **Whenever the reference to this function changes, the data will be loaded again. Make sure to wrap this function around useCallback if needed.**
 * @param active If not active, data is not loaded
 */
export function usePromiseLoader<T>(promiseGetter: () => Promise<T>, active: boolean = true) {
  const [data, setData] = useState<T | null>(null);
  const [loading, setLoading] = useState(false);
  const [inError, setInError] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const activePromise = useRef<Promise<T> | null>(null);

  useEffect(() => {
    if (!active) return;
    setLoading(true);

    const promise = promiseGetter();
    activePromise.current = promise;
    // when this effect is triggered multiple times in quick succession, we need
    // to make sure that only the promise for the most recent React state is actually handled
    const outdatedPromise = () => activePromise.current !== promise;
    promise
      .then((t: T) => {
        if (outdatedPromise()) {
          return;
        }
        setData(t);
        setInError(false);
      })
      .catch((e) => {
        if (outdatedPromise()) {
          return;
        }
        setData(null);
        setInError(true);
        enqueueSnackbar(getDataLoadingErrorSnackbar());
        Sentry.captureException(e);
      })
      .finally(() => {
        if (outdatedPromise()) {
          return;
        }
        setLoading(false);
      });
  }, [enqueueSnackbar, promiseGetter, active]);

  return { data, setData, loading, inError };
}
