import { Dialog, DialogActions, DialogContent } from '@mui/material';
import { FormMode, FormModeProvider } from '../../../../form/helper/FormModeProvider';
import { Form } from 'react-final-form';
import { VerdictInputDialogTitle } from './VerdictInputDialogTitle';
import SecondaryActionButton from '../../../../components/buttons/SecondaryActionButton';
import MainActionButton from '../../../../components/buttons/MainActionButton';
import { useVerdictInputFormSubmitHelper } from './useVerdictInputFormSubmitHelper';
import { Demand } from '../../../demandTypes';
import { ReactNode } from 'react';

type VerdictInputDialogWrapperProps<T, C> = {
  demand: Demand;
  open: boolean;
  onClose: () => void;
  initialFormValues: T;
  formValuesToCommand: (formValues: T) => C;
  onSubmitSuccess: () => void;
  children: ReactNode;
};

export function VerdictInputDialogWrapper<T, C>({
  demand,
  open,
  onClose,
  initialFormValues,
  formValuesToCommand,
  onSubmitSuccess,
  children,
}: VerdictInputDialogWrapperProps<T, C>) {
  const submitForm = useVerdictInputFormSubmitHelper(demand.id);

  const onSubmit = async (formValues: T) => {
    return await submitForm(formValuesToCommand(formValues), () => {
      onSubmitSuccess();
      onClose();
    });
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <FormModeProvider mode={FormMode.EDIT}>
        <Form initialValues={initialFormValues} onSubmit={onSubmit}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <VerdictInputDialogTitle />
              <DialogContent>{children}</DialogContent>
              <DialogActions>
                <SecondaryActionButton label="Annuler" action={onClose} />
                <MainActionButton dataTestId="confirm-verdict-input" label="Confirmer" type="submit" />
              </DialogActions>
            </form>
          )}
        </Form>
      </FormModeProvider>
    </Dialog>
  );
}
