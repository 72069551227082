import React from 'react';
import { TableCell, TableHead, TableRow } from '@mui/material';
import SortableTableCell from '@eogile/agrements-common/src/components/table/SortableTableCell';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { SocieteMereUsersSortableFields } from '../societeMereUsersTypes';

type Props = {
  onCommandChange: (newCommand: PaginationAndSortCommand<SocieteMereUsersSortableFields>) => void;
  command: PaginationAndSortCommand<SocieteMereUsersSortableFields>;
};

const SocieteMereUsersTableHeader = ({ onCommandChange, command }: Props) => {
  return (
    <TableHead>
      <TableRow>
        <SortableTableCell<SocieteMereUsersSortableFields>
          label="Login"
          sortField="LOGIN"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<SocieteMereUsersSortableFields>
          label="Nom"
          sortField="LAST_NAME"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<SocieteMereUsersSortableFields>
          label="Prénom"
          sortField="FIRST_NAME"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<SocieteMereUsersSortableFields>
          label="Email"
          sortField="EMAIL"
          onChange={onCommandChange}
          command={command}
        />
        <TableCell>Rôles</TableCell>
        <TableCell>Statut</TableCell>
        <SortableTableCell<SocieteMereUsersSortableFields>
          label="Date maj"
          sortField="UPDATED_AT"
          onChange={onCommandChange}
          command={command}
        />
        <TableCell>Actions</TableCell>
      </TableRow>
    </TableHead>
  );
};

export default SocieteMereUsersTableHeader;
