import React from 'react';
import Header from './layout/header/Header';
import Sidebar from './layout/sidebar/Sidebar';
import { MainContainer } from './MainContainer';
import { SessionWarningContainer } from './sessionwarning/SessionWarningContainer';
import { useAppContext } from './AppContext';

const AuthenticatedUserPage = () => {
  const { sessionWatcher } = useAppContext();

  return (
    <>
      <Header />
      <Sidebar />
      <MainContainer />
      {sessionWatcher && (
        <SessionWarningContainer
          warningLimitInSecond={sessionWatcher.warningLimitInSecond}
          endOfSessionLimitInSecond={sessionWatcher.endOfSessionLimitInSecond}
        />
      )}
    </>
  );
};

export default AuthenticatedUserPage;
