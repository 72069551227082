import { MinistryUser } from '../../usersTypes';
import { MinistryUserApiSaveCommand, MinistryUserFormValues } from './ministryUserTypes';
import { Civility } from '../../../common-types/civilityTypes';
import { MinistryUserProfile } from '../../ministryUserTypes';
import { ministryProfileDisplayLabels } from '../../userUtils';
import { UserCompany } from '../../commonTypes';
import { caseInsensitiveCompare } from '../../../utils/stringUtils';

/**
 * Return the form values matching the given user.
 * @param user
 */
export function toFormValues(user?: MinistryUser): MinistryUserFormValues {
  if (!user) {
    return formValuesForNewUser();
  }
  return {
    id: user.id,
    login: user.login,
    firstName: user.firstName,
    lastName: user.lastName,
    civility: user.civility,
    email: user.email,
    profiles: [...user.profileCodes],
    territorialArea: user.territorialArea,
  };
}

function formValuesForNewUser(): MinistryUserFormValues {
  return {
    id: null,
    login: null,
    firstName: null,
    lastName: null,
    civility: null,
    email: null,
    profiles: [],
    territorialArea: null,
  };
}

/**
 * Transform the valid form values to API request's payload.
 */
export function toApiSaveCommand(formValues: MinistryUserFormValues): MinistryUserApiSaveCommand {
  return {
    login: formValues.login!,
    company: UserCompany.MINISTRY,
    lastName: formValues.lastName!,
    firstName: formValues.firstName!,
    civility: formValues.civility!,
    email: formValues.email!,
    profiles: [...formValues.profiles],
    territorialArea: formValues.territorialArea,
  };
}

/**
 * Return the ministry profiles that cannot be selected.
 *
 * Currently selected profiles are always present in the response.
 */
export function computeUnselectableProfiles(alreadySelectedProfiles: MinistryUserProfile[]) {
  const profiles = [];
  if (alreadySelectedProfiles.includes(MinistryUserProfile.MINISTRY_LOCAL)) {
    profiles.push(MinistryUserProfile.MINISTRY_VALIDATOR);
    profiles.push(MinistryUserProfile.MINISTRY_PREPARATOR);
  }

  if (alreadySelectedProfiles.includes(MinistryUserProfile.MINISTRY_VALIDATOR)) {
    profiles.push(MinistryUserProfile.MINISTRY_LOCAL);
  }

  if (alreadySelectedProfiles.includes(MinistryUserProfile.MINISTRY_PREPARATOR)) {
    profiles.push(MinistryUserProfile.MINISTRY_LOCAL);
  }

  return profiles.filter((p) => !alreadySelectedProfiles.includes(p));
}

/**
 * Civility labels for the ministry. It does not matter if it does not match
 * the companies ones.
 */
export const ministryCivilityLabels: Readonly<Record<Civility, string>> = {
  [Civility.MR]: 'M.',
  [Civility.MS]: 'Mme',
};

/**
 * The profile codes sorted by profile names.
 */
export const sortedProfileCodes = Object.values(MinistryUserProfile).sort((p1, p2) => {
  return caseInsensitiveCompare(ministryProfileDisplayLabels[p1], ministryProfileDisplayLabels[p2]);
});
