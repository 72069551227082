import { LocalDate, ZonedDateTime } from '../../common-types/dateTypes';
import dayjs, { Dayjs } from 'dayjs';
import { FormMode } from './FormModeProvider';
import { DatePickerAdapterProps } from '../components/DatePickerAdapter';
import { formatLocalDate, formatZonedDateTime, januaryFirst1900, today } from '../../utils/dateUtils';
import { notAfterToday } from './validators';
import ApiError from '../../api/ApiError';
import { SubmissionErrors } from 'final-form';
import { BooleanSelectChoice } from '../components/BooleanSelectAdapter';

export const renderFormFieldLabel = (label: string | null | undefined, required: boolean) => {
  if (!label) {
    return label;
  }
  return required ? `${label} *` : label;
};

export const toNullSafeDayJsFormValue = (date: LocalDate | ZonedDateTime | null | undefined) => {
  return date ? dayjs(date) : undefined;
};

export const isDisabledOrReadFormMode = (disabled: boolean | undefined, mode: FormMode | undefined) =>
  disabled || mode === FormMode.READ;

export const buildSubmissionErrors = (apiError: ApiError): SubmissionErrors => {
  let submissionErrors: SubmissionErrors = {};
  if ((apiError.validationErrors?.length ?? 0) > 0) {
    // have standard validation errors
    submissionErrors = apiError
      .validationErrors!.filter((validationError) => validationError.fieldName)
      .reduce(
        (acc, validationError) => ({
          ...acc,
          [validationError.fieldName!]: validationError.message,
        }),
        {},
      );
  }
  return Object.keys(submissionErrors).length > 0 ? submissionErrors : undefined;
};

export const postJanuaryFirst1900AndNotAfterTodayDatePickerProps: Pick<
  DatePickerAdapterProps,
  'minDate' | 'maxDate' | 'validators'
> = {
  minDate: januaryFirst1900,
  maxDate: today(),
  validators: [notAfterToday],
};

export const formatLocalDateUpperBoundAsZonedDateTime = (upperBound: Dayjs | null) => {
  return formatZonedDateTime(upperBound?.startOf('day').add(1, 'day'));
};

export const formatLocalDateUpperBound = (upperBound: Dayjs | null) => {
  return formatLocalDate(upperBound?.startOf('day').add(1, 'day'));
};

/**
 * Suitable for converting a textual boolean ("true", "false") into a Boolean object for api calls.
 * @param choice can be null if the select field is left empty.
 */
export const booleanSelectChoiceToBooleanValue = (choice: BooleanSelectChoice | null) => {
  return choice != null ? choice === 'true' : null;
};
