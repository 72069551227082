import { Demand, DemandStatus } from '../demandTypes';
import ReadOnlyField from '../../form/components/ReadOnlyField';
import dayjs from 'dayjs';
import { demandStatusLabels } from '../demandUtils';
import { FormGridItem } from '../../components/grid/FormGridItem';
import { FormGridContainer } from '../../components/grid/FormGridContainer';
import { userCompanyLabels } from '../../user/userUtils';

type Props = {
  /**
   * when {@code undefined}, this is suitable for the "new demand" form
   */
  demand: Demand | undefined;
  /**
   * if true, then we display a read-only field showing the company this demand is linked to.
   */
  showDemandCompany: boolean;
};

export const DemandCurrentStatus = ({ demand, showDemandCompany }: Props) => {
  return (
    <FormGridContainer>
      {demand?.company && showDemandCompany && (
        <FormGridItem>
          <ReadOnlyField label="Société" value={userCompanyLabels[demand.company]} />
        </FormGridItem>
      )}
      {demand?.validationDateTime && (
        <>
          <FormGridItem>
            <ReadOnlyField label="Validée le" value={dayjs(demand.validationDateTime)} />
          </FormGridItem>
          <FormGridItem>
            <ReadOnlyField label="Validée par" value={demand.validatedByUser} />
          </FormGridItem>
        </>
      )}
      {demand?.trace && (
        <>
          <FormGridItem>
            <ReadOnlyField label="Créée le" value={dayjs(demand.trace.createdAt)} />
          </FormGridItem>
          <FormGridItem>
            <ReadOnlyField label="Créée par" value={demand.trace.createdBy} />
          </FormGridItem>
        </>
      )}
      <FormGridItem>
        <ReadOnlyField
          label="Statut de la demande"
          value={demandStatusLabels[demand?.status ?? DemandStatus.CREATED]}
        />
      </FormGridItem>
    </FormGridContainer>
  );
};
