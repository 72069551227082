import { DialogContent, Grid } from '@mui/material';
import TextFieldAdapter from '../../../../form/components/TextFieldAdapter';
import { EnumSelectAdapter } from '../../../../form/components/EnumSelectAdapter';
import { cancellationReasonLabels } from '../../../demandUtils';
import { useAppContext } from '../../../../AppContext';

export const AskCancellationDialogContent = () => {
  const {
    demand: { availableCancellationReasons },
  } = useAppContext();

  const displayFields = availableCancellationReasons.length > 0;

  return (
    <DialogContent>
      {displayFields && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <EnumSelectAdapter
              label="Motif d'annulation"
              name="reason"
              required
              options={availableCancellationReasons}
              optionName={(opt) => cancellationReasonLabels[opt]}
            />
          </Grid>
          <Grid item xs={12}>
            <TextFieldAdapter label="Commentaire" multiline name="comment" rows={3} />
          </Grid>
        </Grid>
      )}
      {!displayFields && <p>Voulez vous annuler cette demande ?</p>}
    </DialogContent>
  );
};
