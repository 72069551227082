import { SocieteMereVerdictInputCommand, SocieteMereVerdictInputFormValues } from './societeMereVerdictInputFormTypes';
import { RejectionReason, VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import {
  hasAskedCancellation,
  toStandardCancelledVerdictInputCommand,
  toStandardCancelledVerdictInputFormValues,
  toStandardGrantedVerdictInputCommand,
  toStandardGrantedVerdictInputFormValues,
  toStandardRejectedVerdictInputCommand,
  toStandardRejectedVerdictInputFormValues,
} from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputFormUtils';
import { SocieteMereAgreementType, SocieteMereDemand } from '../../../societeMereDemandTypes';
import { trim } from '@eogile/agrements-common/src/utils/stringUtils';

export const buildInitialSocieteMereVerdictInputFormValues = (
  demand: SocieteMereDemand,
): SocieteMereVerdictInputFormValues => {
  if (hasAskedCancellation(demand)) {
    return { verdictType: VerdictType.CANCELLED };
  }
  switch (demand.verdict?.status) {
    case VerdictType.GRANTED:
      return {
        ...toStandardGrantedVerdictInputFormValues(),
        referenceTaxIncome: demand.requester.referenceTaxIncome,
      };
    case VerdictType.CANCELLED:
      return toStandardCancelledVerdictInputFormValues(demand);
    case VerdictType.REJECTED:
      return {
        ...toStandardRejectedVerdictInputFormValues(demand),
        referenceTaxIncome: demand.requester.referenceTaxIncome,
        rejectionComment: demand.verdict.rejectionComment,
      };
    default:
      return { verdictType: undefined };
  }
};

export const buildSocieteMereVerdictInputCommand = (
  formValues: SocieteMereVerdictInputFormValues,
): SocieteMereVerdictInputCommand => {
  switch (formValues.verdictType) {
    case VerdictType.GRANTED:
      return { ...toStandardGrantedVerdictInputCommand(), referenceTaxIncome: formValues.referenceTaxIncome };
    case VerdictType.CANCELLED:
      return toStandardCancelledVerdictInputCommand(formValues);
    case VerdictType.REJECTED:
      return {
        ...toStandardRejectedVerdictInputCommand(formValues),
        referenceTaxIncome: formValues.referenceTaxIncome,
        rejectionComment: trim(formValues.rejectionComment),
      };
    default:
      throw new Error('invalid verdict type for command creation');
  }
};

export const societeMereActiveRejectionReasons: readonly RejectionReason[] = [
  RejectionReason.PUBLIC_ORDER_CONSIDERATIONS,
];

export const subjectToIncomeAgreementTypes: readonly SocieteMereAgreementType[] = [
  SocieteMereAgreementType.PCOU,
  SocieteMereAgreementType.ASSO,
  SocieteMereAgreementType.MAND,
  SocieteMereAgreementType.PORP,
  SocieteMereAgreementType.GERA,
  SocieteMereAgreementType.A,
  SocieteMereAgreementType.P,
  SocieteMereAgreementType.PP,
];
