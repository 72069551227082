import { PasswordSummary, User } from '@eogile/agrements-common/src/user/usersTypes';

export type SocieteMereUser = User &
  Readonly<{
    profileCodes: readonly SocieteMereUserProfile[];
    passwordSummary: PasswordSummary;
  }>;

export enum SocieteMereUserProfile {
  SOCIETE_MERE_DEMAND_READER = 'SOCIETE_MERE_DEMAND_READER',
  SOCIETE_MERE_WRITER = 'SOCIETE_MERE_WRITER',
  SOCIETE_MERE_ADMIN = 'SOCIETE_MERE_ADMIN',
}
