import { AppContextType } from '@eogile/agrements-common/src/AppContext';
import {
  currentUserMinistryTerritorialArea,
  ministryProfileDisplayLabels,
} from '@eogile/agrements-common/src/user/userUtils';
import { societeMereProfileDisplayLabels } from './user/societeMereUserUtils';
import { SocieteMereUserProfile } from './user/societeMereUserTypes';
import { MinistryUserProfile } from '@eogile/agrements-common/src/user/ministryUserTypes';
import { SocieteMereDemand } from './demand/societeMereDemandTypes';
import { getRequesterLabel } from './utils/societeMereLabelUtils';
import { SocieteMereDemandForm } from './demand/components/SocieteMereDemandForm';
import { SocieteMereDemandsList } from './demand/SocieteMereDemandsList';
import { CurrentUserDetails } from '@eogile/agrements-common/src/security/types';
import { initialStatusFilterValueIfLocalMinistryUser } from '@eogile/agrements-common/src/demand/demandsFiltersUtils';
import { SocieteMereDemandsLoadRequestParams } from './demand/societeMereDemandListTypes';
import { CurrentSocieteMereApplicationUserDetails } from './security/types';
import { smCanDoDemandActionPostFilter } from './demand/smDemandActionRightUtils';
import { societeMereAttachmentHelper } from './demand/societeMereDemandAttachmentUtils';
import { SocieteMereVerdictInputDialog } from './demand/components/actions/verdictinput/SocieteMereVerdictInputDialog';
import SocieteMereUsersList from './user/list/components/SocieteMereUsersList';
import SocieteMereUserForm from './user/details/components/SocieteMereUserForm';

const allProfileLabels = { ...ministryProfileDisplayLabels, ...societeMereProfileDisplayLabels };
export const SocietesMeresContext: AppContextType = {
  appName: 'DigiLicences',
  login: {
    saml: false,
  },
  profileDisplayLabel: (profile) => allProfileLabels[profile as MinistryUserProfile | SocieteMereUserProfile],
  demand: {
    title: (demand) => {
      const societeMereDemand = demand as SocieteMereDemand;
      return `Demande: ${[societeMereDemand.societeMereReference, getRequesterLabel(societeMereDemand.requester)]
        .filter(Boolean)
        .join(' - ')}`;
    },
    DemandForm: SocieteMereDemandForm,
    DemandsList: SocieteMereDemandsList,
    canDoActionPostFilter: smCanDoDemandActionPostFilter,
    availableCancellationReasons: [],
    attachmentHelper: societeMereAttachmentHelper,
    attachmentCanBeAddedAfterValidation: false,
    VerdictInputDialog: SocieteMereVerdictInputDialog,
  },
  menu: {
    demandListQueryParameters: (userDetails: CurrentUserDetails) => {
      const appUserDetails = userDetails as CurrentSocieteMereApplicationUserDetails;
      const params: Partial<Record<keyof SocieteMereDemandsLoadRequestParams, string>> = {
        status: initialStatusFilterValueIfLocalMinistryUser(appUserDetails),
        territorialArea: currentUserMinistryTerritorialArea(appUserDetails),
      };
      return params;
    },
  },
  directionAdmin: false,
  users: {
    UsersList: SocieteMereUsersList,
    UserForm: SocieteMereUserForm,
  },
  sessionWatcher: false,
};
