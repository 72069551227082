import React from 'react';
import TableCell from '../../../components/table/TableCell';
import { PasswordSummary } from '../../usersTypes';
import { styled } from '@mui/material';

type Props = {
  user: { passwordSummary: PasswordSummary };
};

const BlockedSpan = styled('span')(({ theme }) => ({
  color: theme.palette.error.main,
}));

const UserStatusCell = ({ user }: Props) => {
  const blocked = user.passwordSummary.blocked;
  return (
    <TableCell>
      {!blocked && 'Actif'}
      {blocked && <BlockedSpan>Bloqué</BlockedSpan>}
    </TableCell>
  );
};

export default UserStatusCell;
