import React from 'react';
import { Card, CardContent, Typography } from '@mui/material';

type ContentCardProps = {
  content: string;
  'data-testid'?: string;
};

export const ContentCard = ({ content, 'data-testid': dataTestId = 'content-card' }: ContentCardProps) => {
  return (
    <Card data-testid={dataTestId}>
      <CardContent>
        <Typography>{content}</Typography>
      </CardContent>
    </Card>
  );
};
