import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { Demand } from '@eogile/agrements-common/src/demand/demandTypes';
import { LocalDate } from '@eogile/agrements-common/src/common-types/dateTypes';
import { Civility } from '@eogile/agrements-common/src/common-types/civilityTypes';

export type SocieteMereDemand = Demand &
  Readonly<{
    company: UserCompany.LE_TROT | UserCompany.FRANCE_GALOP;
    agreementType: SocieteMereAgreementType;
    referralDemand?: string;
    nature?: SocieteMereNatureType;
    societeMereReference: string;
    otherDemandExists: boolean;
    linkedDemandIds: readonly string[];
    requester: SocieteMereDemandRequester;
    requesterPartner: SocieteMereDemandRequesterPartner;
  }>;

export type SocieteMereDemandRequester = Readonly<{
  acquirerType: SocieteMereAcquirerType;
  civility?: Civility;
  denomination?: string;
  firstName?: string;
  lastName?: string;
  birthName?: string;
  birthDate?: LocalDate;
  birthPlace?: string;
  birthCountry?: string;
  nationality?: string;
  firstLegalRepresentativeName?: string;
  secondLegalRepresentativeName?: string;
  address: SocieteMereAddress;
  situation?: SocieteMereDemandRequesterSituation;
  maritalStatusDate?: LocalDate;
  numberOfChildren?: number;
  jobName?: string;
  companyNameAndAddress?: string;
  companyActivity?: string;
  referenceTaxIncome?: number;
  phoneNumbers: SocieteMerePhoneNumbers;
  fax?: string;
  email?: string;
}>;

export type SocieteMereDemandRequesterPartner = Readonly<{
  firstName?: string;
  lastName?: string;
  birthDate?: LocalDate;
  birthPlace?: string;
  nationality?: string;
  jobName?: string;
  companyNameAndAddress?: string;
  companyActivity?: string;
}>;

export type SocieteMereAddress = Readonly<{
  address1: string;
  address2?: string;
  address3?: string;
  zipCode?: string;
  city: string;
  country?: string;
}>;

export type SocieteMerePhoneNumbers = Readonly<{
  phone1: string;
  phone2?: string;
  phone3?: string;
}>;

export enum SocieteMereAgreementType {
  PCOU = 'PCOU',
  ASSO = 'ASSO',
  BAIL = 'BAIL',
  ELEV = 'ELEV',
  ENPU = 'ENPU',
  ENPA = 'ENPA',
  ENPE = 'ENPE',
  AUEN = 'AUEN',
  JOCK = 'JOCK',
  JJOC = 'JJOC',
  APPR = 'APPR',
  STAG = 'STAG',
  CAVL = 'CAVL',
  GENR = 'GENR',
  CAVA = 'CAVA',
  MAND = 'MAND',
  PORP = 'PORP',
  GERA = 'GERA',
  A = 'A',
  AE = 'AE',
  AL = 'AL',
  EP = 'EP',
  JA = 'JA',
  JP = 'JP',
  L = 'L',
  P = 'P',
  PE = 'PE',
  PP = 'PP',
}

export enum SocieteMereNatureType {
  FIRST_DEMAND = 'FIRST_DEMAND',
  RE_EXAMINATION = 'RE_EXAMINATION',
  QUALITY_CHANGE = 'QUALITY_CHANGE',
  CANCELLATION = 'CANCELLATION',
}

export enum SocieteMereAcquirerType {
  COMPANY = 'COMPANY',
  PERSON = 'PERSON',
}

export enum SocieteMereDemandRequesterSituation {
  SINGLE = 'SINGLE',
  PACS = 'PACS',
  MARRIED = 'MARRIED',
  DIVORCED = 'DIVORCED',
  WIDOWED = 'WIDOWED',
}

export enum SocieteMereAttachmentType {
  PieceIdentite = 'PieceIdentite',
  AvisImposition = 'AvisImposition',
  ExtraitKbis = 'ExtraitKbis',
  StatutsSociete = 'StatutsSociete',
  AutresPieces = 'AutresPieces',
  ContratAssociationOuLocation = 'ContratAssociationOuLocation',
  Bilan = 'Bilan',
}
