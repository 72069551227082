import { useAppContext } from '../../AppContext';
import { usePageTitle } from '../../layout/usePageTitle';
import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';
import { Permission } from '../../security/types';
import ForbiddenCard from '../../components/cards/ForbiddenCard';
import React from 'react';
import { ReassignDemandAgencies } from '../../demand/components/reassign/ReassignDemandAgencies';

export const ReassignDemandsPage = () => {
  const { reassignDemands } = useAppContext();

  usePageTitle(reassignDemands ? 'Ré-affectation des demandes' : '');
  const currentUser = useAuthenticatedCurrentUser();

  if (!currentUser.userDetails.permissions.includes(Permission.ADMINISTRATE_DIRECTION) || !reassignDemands) {
    return <ForbiddenCard />;
  }

  return <ReassignDemandAgencies />;
};
