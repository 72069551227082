import { Demand } from '../../demandTypes';
import { FormMode } from '../../../form/helper/FormModeProvider';
import { ReactNode } from 'react';
import { CurrentUserDetails } from '../../../security/types';

export enum DemandActionType {
  SAVE = 'SAVE',
  EDIT = 'EDIT',
  VALIDATE = 'VALIDATE',
  ASK_CANCELLATION = 'ASK_CANCELLATION',
  DELETE = 'DELETE',
  UPDATE_ATTACHMENTS = 'UPDATE_ATTACHMENTS',
  RECEIVE = 'RECEIVE',
  SET_VERDICT = 'SET_VERDICT',
  VALIDATE_VERDICT = 'VALIDATE_VERDICT',
  PRINT_DEMAND = 'PRINT_DEMAND',
  PRINT_RECEIPT = 'PRINT_RECEIPT',
  UPLOAD_SIGNED_VERDICT = 'UPLOAD_SIGNED_VERDICT',
  PRINT_REFERRAL = 'PRINT_REFERRAL',
  PRINT_OUTLET_VERDICT = 'PRINT_OUTLET_VERDICT',
  PRINT_VERDICT_REJECTED_REASON = 'PRINT_VERDICT_REJECTED_REASON',
  PRINT_VERDICT = 'PRINT_VERDICT',
  CLONE = 'CLONE',
}

export type CanDoDemandActionContext =
  | { mode: FormMode.CREATE; userDetails: CurrentUserDetails; demand?: undefined }
  | {
      mode: FormMode.EDIT | FormMode.READ;
      userDetails: CurrentUserDetails;
      demand: Demand;
    };

/**
 * Used to apply project-specific checks in addition to the common ones
 * (in most cases, we will only need to check if the demand's agency is the same as the current user)
 */
export type CanDoDemandActionPostFilter = (actionType: DemandActionType, ctx: CanDoDemandActionContext) => boolean;

export type DemandAction = {
  type: DemandActionType;
  renderComponent: ((demand: Demand, refreshDemand: () => Promise<void>) => ReactNode) | (() => ReactNode);
  canDoAction: (ctx: CanDoDemandActionContext) => boolean;
};
