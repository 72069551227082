import React, { useMemo } from 'react';
import { Checkbox, FormControlLabel, FormGroup, FormHelperText, InputLabel } from '@mui/material';
import { useField } from 'react-final-form';

type Props<ProfileCode extends string> = {
  /**
   * All the existing profiles.
   */
  profileCodes: ProfileCode[];
  /**
   * The profile's name from the profile's code.
   */
  profileNames: Record<ProfileCode, string>;
  /**
   * Some profiles are incompatible with each other (eg: ministry local and ministry
   * validator).
   *
   * This function should return the profiles that cannot be selected based on
   * the currently selected profiles.
   *
   * For compatibility reasons, currently selected profiles should never be included in
   * the response.
   */
  computeUnselectableProfiles: (alreadySelectedProfiles: ProfileCode[]) => ProfileCode[];
};

/**
 * At least one profile is required to save a user.
 */
function validateProfiles<ProfileCode>(profileCodes: ProfileCode[] | undefined): string | undefined {
  if ((profileCodes ?? []).length > 0) {
    return undefined;
  }
  return 'Veuillez sélectionner au moins un rôle';
}
/**
 * Component in charge of displaying the form component to select profiles.
 */
const UserProfileCheckboxes = <ProfileCode extends string>({
  computeUnselectableProfiles,
  profileCodes,
  profileNames,
}: Props<ProfileCode>) => {
  const { input, meta } = useField<ProfileCode[]>('profiles', {
    validate: validateProfiles,
  });
  const displayError = Boolean((meta.error || meta.submitError) && meta.touched);
  const impossibleProfiles = useMemo(
    () => computeUnselectableProfiles(input.value ?? []),
    [computeUnselectableProfiles, input.value],
  );

  /**
   * If the clicked profile is already present in the selected profiles, it is unselected.
   * Otherwise, it is selected.
   */
  const onProfileClick = (profileCode: ProfileCode) => () => {
    if (input.value.includes(profileCode)) {
      input.onChange(input.value.filter((p) => p !== profileCode));
    } else {
      input.onChange([...input.value, profileCode]);
    }
    input.onBlur(); // Touching the form field.
  };

  return (
    <FormGroup>
      <InputLabel shrink error={displayError}>
        Rôles *
      </InputLabel>
      {profileCodes.map((profileCode) => (
        <FormControlLabel
          key={profileCode}
          control={
            <Checkbox
              checked={input.value.includes(profileCode)}
              onClick={onProfileClick(profileCode)}
              disabled={impossibleProfiles.includes(profileCode)}
            />
          }
          label={profileNames[profileCode]}
        />
      ))}
      {displayError && <FormHelperText error>{meta.error || meta.submitError}</FormHelperText>}
    </FormGroup>
  );
};

export default UserProfileCheckboxes;
