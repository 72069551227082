import React, { useMemo } from 'react';
import { CollapsableCard } from '@eogile/agrements-common/src/components/card/CollapsableCard';
import { Grid } from '@mui/material';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { Form } from 'react-final-form';
import FormFiltersListener from '@eogile/agrements-common/src/form/components/FormFiltersListener';
import { formValuesToQueryString } from '@eogile/agrements-common/src/utils/tableUtils';
import { MinistryUsersFilterValues } from '../ministryUsersTypes';
import MinistryTerritorialAreaSelect from '../../../../../components/ministry-territorial-area/ministryTerritorialAreaSelect';
import { hasAtLeastOneFilterValue } from '../../../../../utils/filtersUtils';

type Props = {
  filterValues: MinistryUsersFilterValues;
};

/**
 * Component in charge of displaying the filters and updating
 * the query string when values change.
 */
const MinistryUsersFilters = ({ filterValues }: Props) => {
  const defaultOpened = useMemo(() => hasAtLeastOneFilterValue(filterValues), [filterValues]);
  return (
    <CollapsableCard defaultOpened={defaultOpened} title="Critères">
      <Form initialValues={filterValues} onSubmit={() => {}}>
        {() => (
          <Grid container columnSpacing={2}>
            <FormFiltersListener transformValues={formValuesToQueryString(filterValues)} />
            <Grid item container sm={6} xs={12} rowSpacing={2}>
              <Grid item xs={12}>
                <TextFieldAdapter name="login" label="Login" />
              </Grid>
              <Grid item xs={12}>
                <TextFieldAdapter name="lastName" label="Nom" />
              </Grid>
            </Grid>
            <Grid item container sm={6} xs={12} rowSpacing={2} alignContent="flex-start">
              <Grid item xs={12}>
                <TextFieldAdapter name="email" label="Email" />
              </Grid>
              <Grid item xs={12}>
                <MinistryTerritorialAreaSelect fieldName="territorialArea" />
              </Grid>
            </Grid>
          </Grid>
        )}
      </Form>
    </CollapsableCard>
  );
};

export default MinistryUsersFilters;
