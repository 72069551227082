import { CanDoDemandActionContext } from './demandActionTypes';
import { FormMode, useFormMode } from '../../../form/helper/FormModeProvider';
import { Demand } from '../../demandTypes';
import React from 'react';
import { Card, CardActions, Grid } from '@mui/material';
import SecondaryActionButton from '../../../components/buttons/SecondaryActionButton';
import { getDemandDetail } from '../../../navigation/navigationUrlService';
import { useNavigationHelper } from '../../../navigation/useNavigationHelper';
import { useAuthenticatedCurrentUser } from '../../../security/CurrentUserContext';
import { useAppContext } from '../../../AppContext';
import { demandActionDefinitions } from './demandActionDefinitions';
import ApiHelper from '../../../api/apiHelper';

// it is safe to assume demand to not be undefined when mode !== CREATED below.
type DemandActionsProps<T extends Demand> = {
  demand: T | undefined;
  onDemandRefresh: (demand: T) => void;
};

export const DemandActions = <T extends Demand>({ demand, onDemandRefresh }: DemandActionsProps<T>) => {
  const mode = useFormMode()!;
  const { userDetails } = useAuthenticatedCurrentUser();
  const { navigateToDynamicRoute } = useNavigationHelper();
  const {
    demand: { canDoActionPostFilter },
  } = useAppContext();
  const demandActionContext: CanDoDemandActionContext =
    mode === FormMode.CREATE ? { mode, userDetails } : { mode, demand: demand!, userDetails };
  const allowedActions = demandActionDefinitions
    .filter((action) => action.canDoAction(demandActionContext))
    .filter((action) => canDoActionPostFilter == null || canDoActionPostFilter(action.type, demandActionContext));

  const refreshDemand = async () => {
    if (demand) {
      onDemandRefresh(await ApiHelper.get(`/api/demands/${demand.id}`));
    }
  };

  if (allowedActions.length === 0) {
    return null;
  }

  return (
    <Card>
      <CardActions>
        <Grid container spacing={1}>
          {allowedActions.map((action) => {
            return (
              <Grid item key={action.type}>
                {action.renderComponent(demand!, refreshDemand)}
              </Grid>
            );
          })}
          {mode === FormMode.EDIT && (
            <Grid item sx={{ marginLeft: 'auto' }}>
              <SecondaryActionButton
                action={() => navigateToDynamicRoute(getDemandDetail(), demand!.id)}
                label="Retour à la demande"
              />
            </Grid>
          )}
        </Grid>
      </CardActions>
    </Card>
  );
};
