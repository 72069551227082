import React from 'react';
import useUserFormHelper from '@eogile/agrements-common/src/user/details/hooks/useUserFormHelper';
import { SocieteMereUser } from '../../societeMereUserTypes';
import { SocieteMereUserFormValues } from '../societeMereUserFormTypes';
import {
  computeUnselectableProfiles,
  sortedProfileCodes,
  toApiSaveCommand,
  toFormValues,
} from '../societeMereUserFormUtils';
import { Card, CardContent, Grid } from '@mui/material';
import UserCardHeader from '@eogile/agrements-common/src/user/details/components/UserCardHeader';
import { Form } from 'react-final-form';
import UserCommonFields from '@eogile/agrements-common/src/user/details/components/UserCommonFields';
import UserProfileCheckboxes from '@eogile/agrements-common/src/user/details/components/UserProfileCheckboxes';
import UserCardActions from '@eogile/agrements-common/src/user/details/components/UserCardActions';
import UserGeneratedPasswordDialog from '@eogile/agrements-common/src/user/details/components/UserGeneratedPasswordDialog';
import { societeMereCivilityLabels } from '../../../utils/societeMereLabelUtils';
import { societeMereProfileDisplayLabels } from '../../societeMereUserUtils';

const SocieteMereUserForm = () => {
  const { initialValues, submitUserForm, plainTextPassword, closePasswordDialog } = useUserFormHelper<
    SocieteMereUser,
    SocieteMereUserFormValues
  >({
    computeInitialValues: toFormValues,
    toApiUserSaveCommand: toApiSaveCommand,
  });

  const onSubmit = (values: SocieteMereUserFormValues) => {
    return submitUserForm(values);
  };

  return (
    <Card>
      <UserCardHeader initialFormValues={initialValues} />
      <Form<SocieteMereUserFormValues> initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Grid container columnSpacing={6}>
                <Grid container item rowSpacing={2} direction="column" xs={12} sm={6}>
                  <UserCommonFields civilityLabels={societeMereCivilityLabels} />
                </Grid>
                <Grid container item rowSpacing={2} direction="column" xs={12} sm={6}>
                  <Grid item>
                    <UserProfileCheckboxes
                      profileCodes={sortedProfileCodes}
                      profileNames={societeMereProfileDisplayLabels}
                      computeUnselectableProfiles={computeUnselectableProfiles}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <UserCardActions />
            <UserGeneratedPasswordDialog plainTextPassword={plainTextPassword} onClose={closePasswordDialog} />
          </form>
        )}
      </Form>
    </Card>
  );
};

export default SocieteMereUserForm;
