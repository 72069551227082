import React from 'react';
import { TableSortLabel } from '@mui/material';
import TableCell from './TableCell';
import { PaginationAndSortCommand, SortOrder } from './tableTypes';

type SortableTableCellProps<T> = {
  label: string;
  sortField: T;
  onChange: (newCommand: PaginationAndSortCommand<T>) => void;
  command: PaginationAndSortCommand<T>;
  initialActiveDirection?: SortOrder;
};

const SortableTableCell = <T,>({
  sortField,
  label,
  onChange,
  command,
  initialActiveDirection,
}: SortableTableCellProps<T>) => {
  const active = sortField === command.sortField;

  let direction: 'asc' | 'desc' = 'asc';

  if (active) {
    direction = command.sortOrder.toLowerCase() as 'asc' | 'desc';
  }

  function onClick() {
    if (!active) {
      onChange({ ...command, sortField, sortOrder: initialActiveDirection ?? 'ASC' });
    } else {
      const newDirection = direction === 'asc' ? 'DESC' : 'ASC';
      onChange({ ...command, sortField, sortOrder: newDirection });
    }
  }

  return (
    <TableCell onClick={onClick} data-testid={`${sortField}-sortable-table-cell`}>
      <TableSortLabel active={active} direction={direction}>
        {label}
      </TableSortLabel>
    </TableCell>
  );
};

export default SortableTableCell;
