import React, { forwardRef } from 'react';
import { IMaskInput } from 'react-imask';

type MaskInputProps = {
  mask: string;
  lazy: boolean;
  onChange: (value?: string) => void;
};

export const MaskInput = forwardRef<any, MaskInputProps>((props, ref) => {
  const { mask, onChange, lazy, ...other } = props;
  return (
    <IMaskInput
      {...other}
      lazy={lazy}
      mask={mask}
      inputRef={ref}
      unmask
      onAccept={onChange as any}
      placeholderChar={'_'}
      overwrite
    />
  );
});
