import React from 'react';
import MainActionButton from '../../../components/buttons/MainActionButton';
import { CardActions } from '@mui/material';

const UserCardActions = () => (
  <CardActions sx={{ display: 'flex', justifyContent: 'flex-end', padding: '15px' }}>
    <MainActionButton label="Enregistrer" type="submit" />
  </CardActions>
);

export default UserCardActions;
