import { Dayjs } from 'dayjs';

export const validateDepartmentNumber = (departmentNumber: string | null): string | undefined => {
  let regExp = new RegExp('^2[AaBb]$|^\\d{2}$|^\\d{3}$', 'i');

  if (departmentNumber && !departmentNumber.match(regExp)) {
    return 'Veuillez saisir un bon numéro de département';
  }
  return undefined;
};

export const validateDateRangeFilter = (lowerBound: Dayjs | null, upperBound: Dayjs | null): string | undefined => {
  if (lowerBound && upperBound && lowerBound.isAfter(upperBound)) {
    return 'La date de fin doit être postérieure à la date de début';
  }
  return undefined;
};

export const noResultsTitle = 'Aucune demande ne correspond à la recherche';
export const numberOfDemandResults = (nb: number) => (nb > 1 ? `${nb} demande(s)` : `${nb} demande`);
