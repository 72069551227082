import { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

export type LayoutState = {
  pageTitle: string;
  isSidebarOpenedOnSmallDevice: boolean;
};

export type LayoutStateContext = {
  state: LayoutState;
  openSidebar: () => void;
  closeSidebar: () => void;
  updatePageTitle: (pageTitle: string) => void;
};

const Ctx = createContext<LayoutStateContext | null>(null);

export const LayoutStateProvider = ({ children }: { children: ReactNode }) => {
  const [state, setState] = useState<LayoutState>({ pageTitle: '', isSidebarOpenedOnSmallDevice: false });
  const openSidebar = useCallback(
    () => setState((prevState) => ({ ...prevState, isSidebarOpenedOnSmallDevice: true })),
    [],
  );
  const closeSidebar = useCallback(
    () => setState((prevState) => ({ ...prevState, isSidebarOpenedOnSmallDevice: false })),
    [],
  );
  const updatePageTitle = useCallback((pageTitle: string) => {
    setState((prevState) => ({ ...prevState, pageTitle }));
    document.title = pageTitle;
  }, []);

  const layoutStateContext: LayoutStateContext = useMemo(
    () => ({
      state,
      openSidebar,
      closeSidebar,
      updatePageTitle,
    }),
    [state, openSidebar, closeSidebar, updatePageTitle],
  );

  return <Ctx.Provider value={layoutStateContext}>{children}</Ctx.Provider>;
};

export const useLayoutState = () => useContext(Ctx)!;
