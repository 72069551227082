import React, { useState } from 'react';
import { User } from '../../usersTypes';
import ApiHelper from '../../../api/apiHelper';
import useSnackbar from '../../../snackbar/useSnackbar';
import { Severity } from '../../../utils/severityUtils';
import UserActionWithDialog from './UserActionWithDialog';
import { Password } from '@mui/icons-material';
import UserGeneratedPasswordDialog from '../../details/components/UserGeneratedPasswordDialog';

type Props = {
  user: User;
};

type ServerResponse = Readonly<{
  plainTextPassword: string;
}>;

const GeneratePasswordUserAction = ({ user }: Props) => {
  const { enqueueSnackbar } = useSnackbar();
  const [plainTextPassword, setPlainTextPassword] = useState<string | null>(null);

  const closePasswordDialog = () => {
    setPlainTextPassword(null);
  };

  const generatePassword = async () => {
    const response: ServerResponse = await ApiHelper.postJson(`/api/users/${user.id}/generate-password`, null);
    setPlainTextPassword(response.plainTextPassword);
    enqueueSnackbar({
      content: 'Un nouveau mot de passe a été généré',
      severity: Severity.SUCCESS,
    });
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <UserActionWithDialog
        dialogTitle="Générer un nouveau mot de passe"
        dialogContentText={`Souhaitez-vous générer un mot de passe pour l'utilisateur ${user.login} (${user.firstName} ${user.lastName}) ?`}
        Icon={Password}
        iconTitle="Générer un nouveau mot de passe"
        confirmAction={generatePassword}
      />
      <UserGeneratedPasswordDialog plainTextPassword={plainTextPassword} onClose={closePasswordDialog} />
    </span>
  );
};

export default GeneratePasswordUserAction;
