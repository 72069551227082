import React from 'react';
import useUsersListHelper from '../hooks/useUsersListHelper';
import SocieteMereUsersFilters from './SocieteMereUsersFilters';
import SocieteMereUsersTable from './SocieteMereUsersTable';

/**
 * Component in charge of displaying the list of SM users with
 * its filters.
 */
const SocieteMereUsersList = () => {
  const { filterValues, onPaginationOrSortChange, paginatedUsers, reloadUsers } = useUsersListHelper();
  return (
    <>
      <SocieteMereUsersFilters filterValues={filterValues} />
      <SocieteMereUsersTable
        command={filterValues}
        onCommandChange={onPaginationOrSortChange}
        paginatedUsers={paginatedUsers}
        reloadUsers={reloadUsers}
      />
    </>
  );
};

export default SocieteMereUsersList;
