import dayjs, { Dayjs } from 'dayjs';
import { ZonedDateTime } from '../common-types/dateTypes';

export const today = () => dayjs().startOf('day');
export const januaryFirst1900 = dayjs('1900-01-01');

const LOCAL_DATE_FORMAT = 'YYYY-MM-DD';

// Typescript overloads to allow usage of 'formatLocalDate(non-null Moment)'
// without having to cast the return value from "string | null" to "string"

export function formatLocalDate(date: null | undefined): null;
export function formatLocalDate(date: Dayjs): string;
export function formatLocalDate(date: Dayjs | null | undefined): string | null;

/**
 * @param date dayjs date
 * @returns the date formatted as 'YYYY-MM-DD' string, or <code>null</code> if date is null or undefined.
 */
export function formatLocalDate(date: Dayjs | null | undefined) {
  return date?.format(LOCAL_DATE_FORMAT) ?? null;
}

export const parseLocalDate = (dateString: string) => dayjs(dateString, LOCAL_DATE_FORMAT);

const LOCAL_DATE_DISPLAY_FORMAT = 'DD/MM/YYYY';

export function formatLocalDateForDisplay(date: null | undefined): null;
export function formatLocalDateForDisplay(date: Dayjs): string;
export function formatLocalDateForDisplay(date: Dayjs | null): string | null;

/**
 * @param date dayjs date
 * @returns the date formatted as 'DD/MM/YYYY' string, or <code>null</code> if date is null or undefined.
 */
export function formatLocalDateForDisplay(date: Dayjs | null | undefined) {
  return date?.format(LOCAL_DATE_DISPLAY_FORMAT) ?? null;
}

export function getDiffInYears(past: Dayjs, future: Dayjs) {
  return future.diff(past, 'year');
}

export function getDiffInYearsRelativeToNow(date: Dayjs) {
  return getDiffInYears(date, dayjs());
}

export function formatZonedDateTime(date: null | undefined): null;
export function formatZonedDateTime(date: Dayjs): string;
export function formatZonedDateTime(date: Dayjs | null | undefined): string | null;

/**
 * @param date dayjs date
 * @returns the date formatted as 'YYYY-MM-DDTHH:mm:ss[Z]' string, or <code>null</code> if date is null or undefined.
 */
export function formatZonedDateTime(date: Dayjs | null | undefined) {
  return date?.format() ?? null;
}

export const formatDateTimeForFrenchDisplay = (date: ZonedDateTime | null | undefined) => {
  return date ? ' le ' + dayjs(date).format('DD/MM/YYYY à HH:mm') : '';
};

const ZONED_DATE_TIME_DISPLAY_FORMAT = 'DD/MM/YYYY HH:mm';

export function formatZonedDateTimeForDisplay(date: null | undefined): null;
export function formatZonedDateTimeForDisplay(date: Dayjs): string;
export function formatZonedDateTimeForDisplay(date: Dayjs | null): string | null;

/**
 * @param date dayjs date
 * @returns the date formatted as 'DD/MM/YYYY HH:mm' string, or <code>null</code> if date is null or undefined.
 */
export function formatZonedDateTimeForDisplay(date: Dayjs | null | undefined) {
  return date?.format(ZONED_DATE_TIME_DISPLAY_FORMAT) ?? null;
}
