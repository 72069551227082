import { getUserCreate } from '../../../navigation/navigationUrlService';
import { Fab, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { useAuthenticatedCurrentUser } from '../../../security/CurrentUserContext';
import { Permission } from '../../../security/types';
import { useNavigationHelper } from '../../../navigation/useNavigationHelper';

const UserAddButton = () => {
  const currentUser = useAuthenticatedCurrentUser();
  const { navigateToStaticRoute } = useNavigationHelper();

  if (!currentUser.userDetails.permissions.includes(Permission.ADMINISTRATE_USERS)) {
    return null;
  }

  const goToCreatePage = () => {
    navigateToStaticRoute(getUserCreate());
  };

  return (
    <Tooltip title="Créer un nouvel utilisateur">
      <Fab color="primary" onClick={goToCreatePage}>
        <AddIcon />
      </Fab>
    </Tooltip>
  );
};

export default UserAddButton;
