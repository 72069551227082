import React from 'react';
import { PasswordSummary, User } from '../../usersTypes';
import ApiHelper from '../../../api/apiHelper';
import useSnackbar from '../../../snackbar/useSnackbar';
import { Severity } from '../../../utils/severityUtils';
import UserActionWithDialog from './UserActionWithDialog';
import { LockOpen } from '@mui/icons-material';

type Props = {
  user: User & { passwordSummary: PasswordSummary };
  reloadUsers: () => Promise<void>;
};

const UnblockUserAction = ({ user, reloadUsers }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const unblockUser = async () => {
    await ApiHelper.postJson(`/api/users/${user.id}/unblock`, null);
    enqueueSnackbar({
      content: "L'utilisateur a bien été débloqué",
      severity: Severity.SUCCESS,
    });
    await reloadUsers();
  };

  if (!user.passwordSummary.blocked) {
    return null;
  }

  return (
    <UserActionWithDialog
      dialogTitle="Débloquer l'utilisateur"
      dialogContentText={`Souhaitez-vous débloquer l'utilisateur ${user.login} (${user.firstName} ${user.lastName}) ?`}
      Icon={LockOpen}
      iconTitle="Débloquer"
      confirmAction={unblockUser}
    />
  );
};

export default UnblockUserAction;
