import MainActionButton from '../../../components/buttons/MainActionButton';
import { useNavigationHelper } from '../../../navigation/useNavigationHelper';
import { getDemandEdition } from '../../../navigation/navigationUrlService';
import { Demand } from '../../demandTypes';

type Props = {
  demand: Demand;
};

export const EditDemandButton = ({ demand }: Props) => {
  const { navigateToDynamicRoute } = useNavigationHelper();
  const handleClick = () => {
    navigateToDynamicRoute(getDemandEdition(), demand.id);
  };

  return <MainActionButton action={handleClick} label="Modifier" />;
};
