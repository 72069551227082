import { FieldState } from 'final-form';
import { Dayjs } from 'dayjs';
import { formatLocalDateForDisplay, today } from '../../utils/dateUtils';

/**
 * Note that if you use this type to create "complex" validators that use 'allValues', you can safely
 * assume 'allValues' to not be undefined.
 *
 * It is optional in the type declaration to make it easier to write simple
 * validators that don't need any other parameter than value.
 */
export type FieldValidator<T = any, F = object> = (
  value?: T,
  allValues?: F,
  meta?: FieldState<T>,
) => any | Promise<any>;

/**
 * Compose multiple validators into a single one that chains their execution.
 * The validators must all be synchronous and return either 'undefined' if valid, or an error message if invalid.
 *
 * If a chained validator check fails, then the following validator functions are not executed.
 * @param validators the validators to compose
 */
export const composeValidators =
  (validators: (FieldValidator | false | undefined)[]) =>
  (...args: Parameters<FieldValidator>) => {
    const actualValidators = validators.filter((v) => v) as FieldValidator[];
    return actualValidators.reduce((error, validator) => {
      if (error) {
        return error;
      }
      return validator(...args);
    }, undefined);
  };

/**
 * Compose multiple validators into a single one that chains their execution.
 * The validators can either be synchronous or asynchronous
 * (in which case they should return a promise that resolves to either 'undefined' or an error message).
 *
 * This is separated from the "composeValidators" function because defaulting to promise resolutions
 * seems to kill performance the more fields get added to the form.
 *
 * If a chained validator check fails, then the following validator functions are not executed.
 * @param validators the validators to compose
 */
export const composeValidatorsAsync =
  (validators: (FieldValidator | false | undefined)[]) =>
  (...args: Parameters<FieldValidator>) => {
    const actualValidators = validators.filter((v) => v) as FieldValidator[];
    return actualValidators.reduce(async (error, validator) => {
      if (await error) {
        return error;
      }
      return await validator(...args);
    }, Promise.resolve(undefined));
  };

export const mandatory = (value?: any) => {
  if (!value) {
    return 'Champ obligatoire';
  } else if (typeof value === 'string') {
    return /^\s*$/.test(value) ? 'Champ obligatoire' : undefined;
  }
  return undefined;
};

// Provided by MDN: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email
const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

// The following validators pass if the value is "falsy", they are meant to be composed with 'mandatory'
// when the field is mandatory.

export const maxLength = (max: number) => (value?: string) =>
  (value ?? '').trim().length > max ? `${max} caractères maximum` : undefined;
export const minLength = (min: number) => (value?: string) =>
  value && value.trim().length < min ? `${min} caractères minimum` : undefined;
export const fixedLength = (length: number) => (value?: string) =>
  !value || value.trim().length === length ? undefined : `${length} caractères requis`;
export const onlyDigits = (value?: string) =>
  /^\d*$/.test(value ?? '') ? undefined : 'Veuillez fournir seulement des chiffres';
export const phone = (value?: string) =>
  // we only check that the masked input is filled: 10 chars for French phone number + 4 padding spaces
  !value || value.trim().length === 14 ? undefined : `Veuillez renseigner un numéro de téléphone valide`;
export const noDigits = (value?: string) =>
  /^\D*$/.test(value ?? '') ? undefined : 'Les chiffres ne sont pas autorisés';
export const minDate = (min: Dayjs) => (value?: Dayjs) =>
  !value || value.isAfter(min.subtract(1, 'day'), 'date')
    ? undefined
    : `La date ne doit pas être avant le ${formatLocalDateForDisplay(min)}`;
export const maxDate = (max: Dayjs) => (value?: Dayjs) =>
  !value || value.isBefore(max.add(1, 'day'), 'date')
    ? undefined
    : `La date ne doit pas être après le ${formatLocalDateForDisplay(max)}`;
export const notAfterToday = (value?: Dayjs) =>
  maxDate(today())(value) ? "La date ne doit pas être après aujourd'hui" : undefined;
export const validDate = (value: Dayjs | undefined | null) =>
  !value || value.isValid() ? undefined : 'Veuillez renseigner une date valide';
export const email = (value?: string) =>
  !value || emailRegex.test(value) ? undefined : 'Veuillez fournir une adresse électronique valide';
export const maxFileSize = (max: number, readableMax: string) => (value?: File) =>
  !value || value.size <= max
    ? undefined
    : `Le fichier ne doit pas dépasser la taille maximale autorisée (${readableMax})`;
