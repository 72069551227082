import { CanDoDemandActionPostFilter } from '@eogile/agrements-common/src/demand/components/actions/demandActionTypes';
import { CurrentSocieteMereApplicationUserDetails } from '../security/types';
import { SocieteMereDemand } from './societeMereDemandTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';

export const smCanDoDemandActionPostFilter: CanDoDemandActionPostFilter = (actionType, ctx) => {
  const castedUserDetails = ctx.userDetails as CurrentSocieteMereApplicationUserDetails;
  return (
    !Boolean(ctx.demand) ||
    castedUserDetails.company === UserCompany.MINISTRY ||
    castedUserDetails.company === (ctx.demand as SocieteMereDemand).company
  );
};
