import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import { SocieteMereAcquirerType, SocieteMereDemand } from '../societeMereDemandTypes';
import { withFieldNamePrefix } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { Civility } from '@eogile/agrements-common/src/common-types/civilityTypes';
import { societeMereCivilityLabels } from '../../utils/societeMereLabelUtils';
import { societeMereAcquirerTypeLabels } from '../societeMereDemandUtils';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { DatePickerAdapter } from '@eogile/agrements-common/src/form/components/DatePickerAdapter';
import { EnumSelectAdapter } from '@eogile/agrements-common/src/form/components/EnumSelectAdapter';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { postJanuaryFirst1900AndNotAfterTodayDatePickerProps } from '@eogile/agrements-common/src/form/helper/formUtils';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import { RequesterLinkedDemands } from '@eogile/agrements-common/src/demand/components/candidate/RequesterLinkedDemands';

type SocieteMereDemandPersonSectionProps = {
  demand: SocieteMereDemand | undefined;
};

export const SocieteMereDemandPersonSection = ({ demand }: SocieteMereDemandPersonSectionProps) => {
  const mode = useFormMode();
  const fieldName = withFieldNamePrefix('requester');

  return (
    <SectionCard title="Personne">
      <FormGridContainer>
        <FormGridItem>
          <EnumSelectAdapter
            name={fieldName('acquirerType')}
            label="Catégorie"
            options={Object.values(SocieteMereAcquirerType)}
            optionName={(opt) => societeMereAcquirerTypeLabels[opt]}
            required
          />
        </FormGridItem>
        <FormGridItem>
          <EnumSelectAdapter
            name={fieldName('civility')}
            label="Civilité"
            options={Object.values(Civility)}
            optionName={(opt) => societeMereCivilityLabels[opt]}
          />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('denomination')} label="Dénomination" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('lastName')} label="Nom" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('firstName')} label="Prénom" cssCapitalize />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('birthName')} label="Nom de jeune fille" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <DatePickerAdapter
            name={fieldName('birthDate')}
            label="Date de naissance"
            {...postJanuaryFirst1900AndNotAfterTodayDatePickerProps}
          />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('birthPlace')} label="Lieu de naissance" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('birthCountry')} label="Pays de naissance" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('nationality')} label="Nationalité" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter
            name={fieldName('firstLegalRepresentativeName')}
            label="Représentant légal 1 (Prénom et Nom)"
            cssUppercase
          />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter
            name={fieldName('secondLegalRepresentativeName')}
            label="Représentant légal 2 (Prénom et Nom)"
            cssUppercase
          />
        </FormGridItem>
      </FormGridContainer>
      {mode === FormMode.READ && (
        <RequesterLinkedDemands
          demandId={demand!.id}
          requesterIndex={0}
          requesterSearchCriteria={{
            firstName: demand!.requester.firstName,
            lastName: demand!.requester.lastName,
            birthDate: demand!.requester.birthDate,
          }}
        />
      )}
    </SectionCard>
  );
};
