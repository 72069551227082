import React, { ReactNode, useState } from 'react';
import { Card, CardActions, CardContent, CardHeader, Collapse } from '@mui/material';
import CollapsableCardHeaderAction from './CollapsableCardHeaderAction';

type CollapsableCardProps = {
  additionalAction?: ReactNode;
  cardActions?: ReactNode;
  defaultOpened: boolean;
  headerDataTestId?: string;
  onOpenToggle?: (opened: boolean) => void;
  title: string | ReactNode;
  children: ReactNode;
};

export const CollapsableCard = ({
  additionalAction,
  cardActions,
  children,
  defaultOpened,
  headerDataTestId = 'collapsable-card-header',
  onOpenToggle,
  title,
}: CollapsableCardProps) => {
  const [opened, setOpened] = useState(defaultOpened);
  const toggleOpened = () => {
    setOpened(!opened);
    if (onOpenToggle) {
      onOpenToggle(!opened);
    }
  };

  return (
    <Card>
      <CardHeader
        sx={{ cursor: 'pointer', padding: '5px 10px' }}
        data-testid={headerDataTestId}
        onClick={toggleOpened}
        title={title}
        action={
          <CollapsableCardHeaderAction
            additionalAction={additionalAction}
            opened={opened}
            toggleOpened={toggleOpened}
          />
        }
      />
      <Collapse in={opened} data-testid="collapsable-card-content">
        <CardContent>{children}</CardContent>
      </Collapse>
      {cardActions && <CardActions>{cardActions}</CardActions>}
    </Card>
  );
};
