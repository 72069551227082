import { useMemo } from 'react';
import { toApiUpdateCommand, toFormValues } from '../passwordUtils';
import { PasswordApiUpdateCommand, PasswordUpdateFormValues } from '../passwordTypes';
import { useFormSubmitHelper } from '../../../form/helper/useFormSubmitHelper';
import useSnackbar from '../../../snackbar/useSnackbar';
import { SnackbarRequest } from '../../../snackbar/snackbarUtils';
import { Severity } from '../../../utils/severityUtils';
import ApiHelper from '../../../api/apiHelper';
import { useCurrentUser, useCurrentUserRefresher } from '../../CurrentUserContext';
import { AuthenticationStatus } from '../../types';

export function usePasswordFormHelper(login: string) {
  const delegateSubmit = useFormSubmitHelper();
  const currentUserRefresher = useCurrentUserRefresher();
  const { enqueueSnackbar } = useSnackbar();
  const currentUser = useCurrentUser();
  const initialValues = useMemo(() => toFormValues(login), [login]);

  async function submitForm(formValues: PasswordUpdateFormValues) {
    const payload = toApiUpdateCommand(formValues);
    return await delegateSubmit(async () => {
      await sendPasswordHttpRequest(payload);
      enqueueSnackbar(successfulSnackbar);
      if (currentUser.authenticationStatus === AuthenticationStatus.UNAUTHENTICATED) {
        await sendAuthenticateHttpRequest(payload);
        await currentUserRefresher();
      }
    });
  }

  /**
   * Execute the HTTP request that updates the user's password.
   */
  async function sendPasswordHttpRequest(payload: PasswordApiUpdateCommand) {
    await ApiHelper.putJson(`/api/user/password`, payload);
  }

  /**
   * The snackbar command to display when the user has been successfully saved.
   */
  const successfulSnackbar: SnackbarRequest = {
    content: 'Votre mot de passe a été mis à jour',
    severity: Severity.SUCCESS,
  };

  /**
   * Execute the HTTP request that authenticates the user./
   */
  async function sendAuthenticateHttpRequest(updateCommand: PasswordApiUpdateCommand) {
    const payload = {
      login: updateCommand.login,
      password: updateCommand.plainTextNewPassword,
    };
    await ApiHelper.postJson(`/login`, payload);
  }

  return {
    initialValues,
    submitForm,
  };
}
