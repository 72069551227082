import * as Sentry from '@sentry/react';
import { SentryConfiguration } from './sentryTypes';
import ApiHelper from '../../api/apiHelper';

export const initializeSentry = async () => {
  try {
    const sentryConfiguration: SentryConfiguration = await ApiHelper.get('/api/sentry');
    Sentry.init({
      dsn: sentryConfiguration.dsn,
      environment: sentryConfiguration.environment,
      release: sentryConfiguration.version,
      autoSessionTracking: false,
    });
  } catch (e) {
    console.error('Error while getting Sentry configuration', e);
  }
};
