import { Permission } from '../../security/types';
import ForbiddenCard from '../../components/cards/ForbiddenCard';
import React from 'react';
import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';
import { usePageTitle } from '../../layout/usePageTitle';
import { TemplatesAdministration } from '../../template/components/TemplatesAdministration';

export const TemplatesAdminPage = () => {
  usePageTitle('Gestion des modèles de documents');
  const currentUser = useAuthenticatedCurrentUser();

  if (!currentUser.userDetails.permissions.includes(Permission.ADMINISTRATE_TEMPLATES)) {
    return <ForbiddenCard />;
  }

  return <TemplatesAdministration />;
};
