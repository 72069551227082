import React from 'react';
import { AppBar, Box, IconButton, Toolbar, Typography } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { toolbarHeight } from '../layoutUtils';
import HeaderAvatar from './HeaderAvatar';
import { useLayoutState } from '../LayoutContext';

const Header = () => {
  const {
    state: { pageTitle },
    openSidebar,
  } = useLayoutState();

  return (
    <Toolbar sx={{ backgroundColor: (theme) => theme.palette.background.default }}>
      <AppBar position="fixed" sx={{ backgroundColor: (theme) => theme.palette.common.white }}>
        <Toolbar
          sx={{
            backgroundColor: (theme) => theme.palette.common.white,
            color: (theme) => theme.palette.text.primary,
          }}
        >
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={openSidebar}
            edge="start"
            sx={{
              marginRight: 2,
            }}
            size="large"
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h5" sx={{ flexGrow: 1 }}>
            {pageTitle}
          </Typography>
          <Box
            sx={{
              backgroundImage: 'url("/images/logo.png")',
              backgroundPosition: 'center',
              backgroundSize: 'contain',
              backgroundRepeat: 'no-repeat',
              height: toolbarHeight + 'px',
              width: '100px',
              marginRight: 1,
            }}
          />
          <HeaderAvatar />
        </Toolbar>
      </AppBar>
    </Toolbar>
  );
};

export default Header;
