import React, { useCallback } from 'react';
import { DEFAULT_PAGE, PaginationAndSortCommand } from './tableTypes';
import { Box, Button, ButtonGroup, Pagination } from '@mui/material';
import baseTheme from '../../baseTheme';

type PaginationProps<T> = {
  numberOfResults: number;
  onCommandChange: (newCommand: PaginationAndSortCommand<T>) => void;
  command: PaginationAndSortCommand<T>;
};

const TablePagination = <T,>({ numberOfResults, onCommandChange, command }: PaginationProps<T>) => {
  const numberOfPages = Math.ceil(numberOfResults / command.pageSize);

  const onPageChange = useCallback(
    (e: object, page: number) => {
      onCommandChange({ ...command, page: page - 1 });
    },
    [command, onCommandChange],
  );

  const onPageSizeChange = (pageSize: number) => () => {
    onCommandChange({ ...command, page: DEFAULT_PAGE, pageSize });
  };

  return (
    <Box
      data-testid="pagination"
      sx={{
        '& > *': {
          marginTop: 2,
        },
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <Pagination
        count={numberOfPages}
        hideNextButton
        hidePrevButton
        page={command.page + 1}
        onChange={onPageChange}
        shape="rounded"
        showFirstButton
        showLastButton
        variant="outlined"
      />

      <ButtonGroup color="inherit" size="small">
        {[10, 20, 50, 100].map((pageSize) => (
          <Button
            sx={{
              backgroundColor: pageSize === command.pageSize ? baseTheme.palette.action.selected : undefined,
              borderColor: baseTheme.palette.background.default,
            }}
            data-testid={`${pageSize}-page-size-button`}
            key={pageSize}
            onClick={onPageSizeChange(pageSize)}
          >
            {pageSize}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default TablePagination;
