import React from 'react';
import { ListItemIcon } from '@mui/material';
import { MenuEntryType } from './menuTypes';

const MenuEntryIcon = ({ menuEntry }: { menuEntry: MenuEntryType }) => {
  if (!menuEntry.icon) {
    return null;
  }
  return (
    <ListItemIcon sx={{ minWidth: '35px' }}>
      <menuEntry.icon fontSize="small" />
    </ListItemIcon>
  );
};

export default MenuEntryIcon;
