import React, { ReactNode } from 'react';
import { DatePicker } from '@mui/x-date-pickers';
import { useField } from 'react-final-form';
import ReadOnlyField from './ReadOnlyField';
import { PickersActionBarAction } from '@mui/x-date-pickers/PickersActionBar/PickersActionBar';
import { isDisabledOrReadFormMode } from '../helper/formUtils';
import { Dayjs } from 'dayjs';
import { useFormMode } from '../helper/FormModeProvider';
import { EnhancedTextField, EnhancedTextFieldProps } from './EnhancedTextField';
import {
  composeValidators,
  FieldValidator,
  mandatory,
  maxDate as maxDateValidator,
  minDate as minDateValidator,
  validDate,
} from '../helper/validators';
import { TextFieldProps } from '@mui/material';
import { FormFieldLabel } from './FormFieldLabel';

export type DatePickerAdapterProps = {
  disabled?: boolean;
  label?: string;
  maxDate?: Dayjs;
  minDate?: Dayjs;
  name: string;
  required?: boolean;
  warningText?: string;
  validators?: FieldValidator<Dayjs | undefined>[];
  infoTooltipContent?: ReactNode;
};

export const DatePickerAdapter = ({
  disabled = false,
  label,
  maxDate,
  minDate,
  name,
  required = false,
  warningText,
  validators = [],
  infoTooltipContent,
}: DatePickerAdapterProps) => {
  const { input, meta } = useField(name, {
    validate: composeValidators([
      required && mandatory,
      ...validators,
      validDate,
      minDate && minDateValidator(minDate),
      maxDate && maxDateValidator(maxDate),
    ]),
  });
  const formMode = useFormMode();
  const dataTestId = `${name}-field`;

  if (isDisabledOrReadFormMode(disabled, formMode)) {
    return (
      <ReadOnlyField
        label={label}
        value={input.value}
        required={required}
        infoTooltipContent={infoTooltipContent}
        dataTestId={dataTestId + '-readonly'}
      />
    );
  }

  const selectDate = (date: Dayjs | null) => {
    input.onChange(date ? date.startOf('day') : date);
  };

  const touchField = () => {
    input.onBlur();
  };

  const displayError = Boolean((meta.error || meta.submitError) && meta.touched);

  let actions: PickersActionBarAction[];
  if (required) {
    actions = ['cancel', 'accept'];
  } else {
    actions = ['clear', 'cancel', 'accept'];
  }

  const textFieldSlotProps = (
    renderInputParams: TextFieldProps,
  ): Partial<EnhancedTextFieldProps> & { ['data-testid']: string } => ({
    ...renderInputParams,
    variant: 'standard',
    fullWidth: true,
    InputLabelProps: { shrink: true },
    error: displayError,
    helperText: (displayError && (meta.error || meta.submitError)) || warningText,
    onBlur: touchField,
    'data-testid': dataTestId,
    warning: Boolean(warningText),
    inputProps: {
      ...renderInputParams.inputProps,
      placeholder: 'JJ/MM/AAAA',
    },
  });

  return (
    <DatePicker
      closeOnSelect
      label={label && <FormFieldLabel label={label} required={required} infoTooltipContent={infoTooltipContent} />}
      maxDate={maxDate}
      minDate={minDate}
      onChange={selectDate}
      onClose={touchField}
      value={input.value || null}
      renderInput={(params) => <EnhancedTextField {...textFieldSlotProps(params)} />}
      componentsProps={{ actionBar: { actions } }}
    />
  );
};
