import {
  MinistryUsersApiSearchCommand,
  MinistryUsersFilterValues,
  MinistryUsersSortableFields,
} from './ministryUsersTypes';
import { convertWithDefaultNumber, parseQueryString } from '../../../../utils/queryStringUtils';
import { MinistryTerritorialArea } from '../../../ministryUserTypes';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, SortOrder } from '../../../../components/table/tableTypes';
import { UserCompany } from '../../../commonTypes';

/**
 * Transform the given query string into valid filter form values.
 */
export function queryStringToFilterValues(search: string): MinistryUsersFilterValues {
  const params = parseQueryString(search);
  return {
    login: (params.login as string) ?? null,
    lastName: (params.lastName as string) ?? null,
    email: (params.email as string) ?? null,
    territorialArea: (params.territorialArea as MinistryTerritorialArea) ?? null,
    page: convertWithDefaultNumber(params.page, DEFAULT_PAGE),
    pageSize: convertWithDefaultNumber(params.pageSize, DEFAULT_PAGE_SIZE),
    sortField: (params.sortField as MinistryUsersSortableFields) ?? 'LOGIN',
    sortOrder: (params.sortOrder as SortOrder) ?? 'ASC',
  };
}

/**
 * Transform th given filter values into a search command that can
 * be understood by the API.
 */
export function toApiSearchCommand(filterValues: MinistryUsersFilterValues): MinistryUsersApiSearchCommand {
  return {
    ...filterValues,
    company: UserCompany.MINISTRY,
  };
}
