import { AttachmentRenderData, AttachmentUploadStatus } from '../../attachmentTypes';
import { Attachment } from '../../demandTypes';
import { IconButton, Stack, TableRow } from '@mui/material';
import { attachmentDownloadLink, attachmentKey } from '../../attachmentUtils';
import TableCell from '../../../components/table/TableCell';
import { UploadAttachmentButton } from './UploadAttachmentButton';
import ApiHelper from '../../../api/apiHelper';
import useSnackbar from '../../../snackbar/useSnackbar';
import { Severity } from '../../../utils/severityUtils';
import { AttachmentName } from './AttachmentName';
import { Delete, Download } from '@mui/icons-material';
import { captureException } from '@sentry/react';
import { AttachmentCommentTextArea } from './AttachmentCommentTextArea';
import { useAppContext } from '../../../AppContext';

type Props = {
  attachmentData: AttachmentRenderData<any>;
  attachment: Attachment | undefined;
  uploadStatus: AttachmentUploadStatus;
  onAttachmentUploaded: (attachment: Attachment) => void;
  onAttachmentRemoved: (attachment: Attachment) => void;
  onUploadStatusChange: (attachmentKey: string, status: 'pending' | 'idle') => void;

  // whether the popup should be displayed for the case where user add attachments after the validation of demand
  afterValidationDisplay: boolean;
};

export const UpdateAttachmentRow = ({
  attachmentData,
  attachment,
  uploadStatus,
  onAttachmentUploaded,
  onAttachmentRemoved,
  onUploadStatusChange,
  afterValidationDisplay,
}: Props) => {
  const {
    demand: { attachmentCanBeAddedAfterValidation },
  } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const rowAttachmentKey = attachmentKey(attachmentData);
  const demandId = attachmentData.demandId;
  const handleAttachmentUploaded = (uploaded: Attachment) => {
    onAttachmentUploaded(uploaded);
  };

  const handleAttachmentRemoval = async () => {
    if (
      attachment &&
      window.confirm('Êtes-vous sûr de vouloir supprimer cette pièce justificative ? Cette action est irréversible.')
    ) {
      try {
        await ApiHelper.delete(`/api/demands/${demandId}/attachments/${attachment.id}`);
        onAttachmentRemoved(attachment);
        enqueueSnackbar({
          content: 'La pièce justificative a bien été supprimée.',
          severity: Severity.SUCCESS,
        });
      } catch (e) {
        enqueueSnackbar({
          content: "La pièce justificative n'a pas pu être supprimée.",
          severity: Severity.ERROR,
        });
        captureException(e);
      }
    }
  };

  return (
    <TableRow data-testid="update-attachment-row">
      <TableCell>
        <Stack spacing={1}>
          <AttachmentName attachmentData={attachmentData} attachment={attachment} renderInfoTooltip />
          {attachment && attachmentCanBeAddedAfterValidation && (
            <AttachmentCommentTextArea demandId={demandId} attachment={attachment} />
          )}
        </Stack>
      </TableCell>
      <TableCell align="right">
        {!attachment ? (
          <UploadAttachmentButton
            demandId={demandId}
            attachmentKey={rowAttachmentKey}
            uploadStatus={uploadStatus}
            onAttachmentUploaded={handleAttachmentUploaded}
            onUploadStatusChange={onUploadStatusChange}
          />
        ) : (
          <Stack direction="row" justifyContent="flex-end">
            <IconButton
              color="secondary"
              target="_blank"
              href={attachmentDownloadLink(demandId, attachment.id)}
              data-testid="download-attachment-button"
            >
              <Download />
            </IconButton>
            {!afterValidationDisplay && (
              <IconButton color="error" onClick={handleAttachmentRemoval} data-testid="delete-attachment-button">
                <Delete />
              </IconButton>
            )}
          </Stack>
        )}
      </TableCell>
    </TableRow>
  );
};
