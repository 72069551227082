import { usePageTitle } from '../../layout/usePageTitle';
import { Box } from '@mui/material';
import { useAppContext } from '../../AppContext';

export const DemandsListPage = () => {
  const {
    demand: { DemandsList },
  } = useAppContext();
  usePageTitle('Gestion des demandes');

  return (
    <Box>
      <DemandsList />
    </Box>
  );
};
