import { StaticNavigationRoute } from '../../../navigation/navigationTypes';
import { SvgIconComponent } from '@mui/icons-material';
import { Permission } from '../../../security/types';
import { UserCompany } from '../../../user/commonTypes';

export type MenuEntryType = {
  readonly label: string;
  readonly icon?: SvgIconComponent;
};

/**
 * A node menu entry.
 *
 * It has no link. It is a category of menu entries.
 */
export type NodeMenuEntryType = MenuEntryType & {
  readonly childrenEntries: MenuEntryType[];
};

/**
 * A leaf menu entry.
 *
 * It has a link.
 */
export type LeafMenuEntryType = MenuEntryType & {
  readonly canBeActivatedByChildrenPages: boolean;
  readonly getNavigationDefinition: () => StaticNavigationRoute;
  readonly permissions: Permission[];
  readonly userCompanies: UserCompany[];
};

/**
 * Type guard for NodeMenuEntryType.
 */
export function isNodeMenuEntry(menuMenu: MenuEntryType): menuMenu is NodeMenuEntryType {
  return (menuMenu as NodeMenuEntryType).childrenEntries !== undefined;
}

/**
 * Type guard for LeafMenuEntryType.
 */
export function isLeafMenuEntry(menuMenu: MenuEntryType): menuMenu is LeafMenuEntryType {
  return (menuMenu as LeafMenuEntryType).permissions !== undefined;
}
