import { createContext, ReactNode, useContext } from 'react';

export enum FormMode {
  READ = 'READ',
  EDIT = 'EDIT',
  CREATE = 'CREATE',
}

const Ctx = createContext<FormMode>(FormMode.CREATE);

export const FormModeProvider = ({ mode, children }: { mode: FormMode; children: ReactNode }) => (
  <Ctx.Provider value={mode}>{children}</Ctx.Provider>
);

/**
 * @return the current form mode, or undefined if there is no form mode context in the component tree
 */
export const useFormMode = (): FormMode | undefined => {
  return useContext(Ctx);
};
