import React from 'react';
import { TableHead, TableRow } from '@mui/material';
import SortableTableCell from '@eogile/agrements-common/src/components/table/SortableTableCell';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { SocieteMereDemandsSortableFields } from '../societeMereDemandListTypes';
import { useAuthenticatedCurrentUser } from '@eogile/agrements-common/src/security/CurrentUserContext';
import { isMinistryUser } from '@eogile/agrements-common/src/user/userUtils';

type DemandsTableHeaderProps = {
  onCommandChange: (newCommand: PaginationAndSortCommand<SocieteMereDemandsSortableFields>) => void;
  command: PaginationAndSortCommand<SocieteMereDemandsSortableFields>;
};

const SocieteMereDemandsTableHeader = ({ onCommandChange, command }: DemandsTableHeaderProps) => {
  const { userDetails } = useAuthenticatedCurrentUser();
  return (
    <TableHead>
      <TableRow>
        {isMinistryUser(userDetails) && (
          <SortableTableCell<SocieteMereDemandsSortableFields>
            label="Société"
            sortField="COMPANY"
            onChange={onCommandChange}
            command={command}
          />
        )}
        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="No Chrono"
          sortField="CHRONO_NUMBER"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Dénomination"
          sortField="DENOMINATION_NAME"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Nom"
          sortField="REQUESTER_LAST_NAME"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Prénom"
          sortField="REQUESTER_FIRST_NAME"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Date de naissance"
          sortField="REQUESTER_BIRTHDAY"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Pays"
          sortField="REQUESTER_COUNTRY"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Type d'agrément"
          sortField="TYPE_AGREMENT"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Nature"
          sortField="TYPE_NATURE"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Date de validation"
          sortField="VALIDATION_DATE"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Date de réception"
          sortField="RECEPTION_DATE"
          onChange={onCommandChange}
          command={command}
        />
        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Statut"
          sortField="STATUS"
          onChange={onCommandChange}
          command={command}
        />

        <SortableTableCell<SocieteMereDemandsSortableFields>
          label="Date d'avis"
          sortField="AVIS_DATE"
          onChange={onCommandChange}
          command={command}
        />
      </TableRow>
    </TableHead>
  );
};

export default SocieteMereDemandsTableHeader;
