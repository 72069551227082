import { CurrentMinistryUserDetails, CurrentUserDetails } from '../security/types';
import { UserCompany } from './commonTypes';
import { MinistryTerritorialArea, MinistryUserProfile } from './ministryUserTypes';

export const fullName = (userDetails: CurrentUserDetails) => `${userDetails.firstName} ${userDetails.lastName}`;

export const allUserCompanyTypes = Object.values(UserCompany);
export const allUserCompanyTypesExceptMinistry = allUserCompanyTypes.filter((c) => c !== UserCompany.MINISTRY);

export const ministryProfileDisplayLabels: Record<MinistryUserProfile, string> = {
  [MinistryUserProfile.MINISTRY_ADMIN]: 'Administrateur ministère',
  [MinistryUserProfile.MINISTRY_VALIDATOR]: 'Ministère valideur',
  [MinistryUserProfile.MINISTRY_LOCAL]: 'Ministère local',
  [MinistryUserProfile.MINISTRY_PREPARATOR]: 'Ministère préparateur',
};

export const userCompanyLabels: Record<UserCompany, string> = {
  [UserCompany.PMU]: 'PMU',
  [UserCompany.FDJ]: 'FDJ',
  [UserCompany.LE_TROT]: 'Le Trot',
  [UserCompany.FRANCE_GALOP]: 'France Galop',
  [UserCompany.MINISTRY]: 'Ministère',
};

export const isMinistryUser = (user: CurrentUserDetails): user is CurrentMinistryUserDetails =>
  user.company === UserCompany.MINISTRY;

export const currentUserMinistryTerritorialArea = (
  userDetails: CurrentUserDetails,
): MinistryTerritorialArea | undefined => {
  if (isMinistryUser(userDetails)) {
    return userDetails.territorialArea ?? undefined;
  }
  return undefined;
};
