import { VerdictTypeSelectAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/VerdictTypeSelectAdapter';
import { VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import { RejectionReasonSelectAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/RejectionReasonSelectAdapter';
import { SocieteMereDemand } from '../../../societeMereDemandTypes';
import { useStandardVerdictTypeFormHelper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useStandardVerdictTypeFormHelper';
import { societeMereActiveRejectionReasons } from './societeMereVerdictInputFormUtils';
import { useSocieteMereRejectedVerdictFormHelper } from './useSocieteMereRejectedVerdictFormHelper';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { CancellationCommentTextFieldAdapter } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/CancellationCommentTextFieldAdapter';
import { useStandardCancelledVerdictFormHelper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useStandardCancelledVerdictFormHelper';
import { useReferenceTaxIncomeVerdictFormHelper } from './useReferenceTaxIncomeVerdictFormHelper';
import { NumberFieldAdapter } from '@eogile/agrements-common/src/form/components/NumberFieldAdapter';
import { Grid } from '@mui/material';
import { hasAskedCancellation } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputFormUtils';
import { VerdictInputCancellationAskedText } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/VerdictInputCancellationAskedText';

export const SocieteMereVerdictInputFormContent = ({ demand }: { demand: SocieteMereDemand }) => {
  const { currentVerdictType, availableVerdictTypes } = useStandardVerdictTypeFormHelper(demand);
  const { activeRejectionReasons } = useSocieteMereRejectedVerdictFormHelper(societeMereActiveRejectionReasons);
  const { supportsMinistryCancellationFields } = useStandardCancelledVerdictFormHelper(demand);
  const { supportsReferenceTaxIncomeField } = useReferenceTaxIncomeVerdictFormHelper(demand);

  return (
    <Grid container spacing={2}>
      {hasAskedCancellation(demand) && (
        <Grid item xs={12}>
          <VerdictInputCancellationAskedText />
        </Grid>
      )}
      <Grid item xs={12}>
        <VerdictTypeSelectAdapter availableVerdictTypes={availableVerdictTypes} />
      </Grid>
      {supportsReferenceTaxIncomeField && (
        <Grid item xs={12}>
          <NumberFieldAdapter label="Revenu fiscal de référence (€)" name="referenceTaxIncome" />
        </Grid>
      )}
      {currentVerdictType === VerdictType.REJECTED && (
        <>
          <Grid item xs={12}>
            <RejectionReasonSelectAdapter availableRejectionReasons={activeRejectionReasons} />
          </Grid>
          <Grid item xs={12}>
            <TextFieldAdapter name="rejectionComment" label="Motivation de l'avis" multiline rows={3} />
          </Grid>
        </>
      )}
      {currentVerdictType === VerdictType.CANCELLED && supportsMinistryCancellationFields && (
        <Grid item xs={12}>
          <CancellationCommentTextFieldAdapter label="Motif (texte libre)" />
        </Grid>
      )}
    </Grid>
  );
};
