import { Box } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  getDemandCreate,
  getDemandDetail,
  getDemandEdition,
  getDemandsList,
  getDirectionAdmin,
  getReassignDemands,
  getTemplatesAdmin,
  getUserCreate,
  getUserEdition,
  getUsersAdmin,
} from './navigation/navigationUrlService';
import { UsersAdministrationListPage } from './pages/user/UsersAdministrationListPage';
import { DemandCreatePage } from './pages/demand/DemandCreatePage';
import { DemandsListPage } from './pages/demand/DemandsListPage';
import { DemandReadPage } from './pages/demand/DemandReadPage';
import { DemandEditPage } from './pages/demand/DemandEditPage';
import UserPage from './pages/user/UserPage';
import { useAuthenticatedCurrentUser } from './security/CurrentUserContext';
import { hasPermission } from './security/securityUtils';
import { Permission } from './security/types';
import { TemplatesAdminPage } from './pages/template/TemplatesAdminPage';
import { useDemandListDefaultUrlBuilder } from './demand/hooks/useDemandListDefaultUrlBuilder';
import { DirectionAdminPage } from './pages/direction/DirectionAdminPage';
import { ReassignDemandsPage } from './pages/demand/ReassignDemandsPage';
import { useAppContext } from './AppContext';

export const MainContainer = () => {
  const { appSpecificRoutes } = useAppContext();
  return (
    <Box component="main">
      <Box sx={{ margin: 3 }}>
        <Routes>
          <Route path="/" element={<RedirectHome />} />
          {usersAdministrationRoutes()}
          {appAdministrationRoutes()}
          {demandRoutes()}
          {appSpecificRoutes}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Box>
    </Box>
  );
};

const RedirectHome = () => {
  const { userDetails } = useAuthenticatedCurrentUser();
  const canAdminUsers = hasPermission(userDetails, Permission.ADMINISTRATE_USERS);
  const canReadDemands = hasPermission(userDetails, Permission.READ_DEMAND);
  const demandsListUrl = useDemandListDefaultUrlBuilder();
  const redirectTarget = () => {
    if (canReadDemands) {
      return demandsListUrl;
    } else if (canAdminUsers) {
      return getUsersAdmin().getUrl();
    }
    return demandsListUrl;
  };
  return <Navigate to={redirectTarget()} replace />;
};

const usersAdministrationRoutes = () => (
  <>
    <Route path={getUsersAdmin().getUrl()} element={<UsersAdministrationListPage />} />
    <Route path={getUserCreate().getUrl()} element={<UserPage />} />
    <Route path={getUserEdition().routerPath} element={<UserPage />} />
  </>
);

const demandRoutes = () => (
  <>
    <Route path={getDemandDetail().routerPath} element={<DemandReadPage />} />
    <Route path={getDemandEdition().routerPath} element={<DemandEditPage />} />
    <Route path={getDemandCreate().getUrl()} element={<DemandCreatePage />} />
    <Route path={getDemandsList().getUrl()} element={<DemandsListPage />} />
  </>
);

const appAdministrationRoutes = () => (
  <>
    <Route path={getTemplatesAdmin().getUrl()} element={<TemplatesAdminPage />} />
    <Route path={getDirectionAdmin().getUrl()} element={<DirectionAdminPage />} />
    <Route path={getReassignDemands().getUrl()} element={<ReassignDemandsPage />} />
  </>
);
