import { PaginationAndSortCommand } from '../../../../../components/table/tableTypes';
import { PaginatedListResponse } from '../../../../../api/apiTypes';
import { Card, CardContent, Table, TableBody } from '@mui/material';
import UsersTableHeader from '../../../components/UsersTableHeader';
import NoUsersFound from '../../../components/NoUsersFound';
import TablePagination from '../../../../../components/table/TablePagination';
import React from 'react';
import { MinistryUsersSortableFields } from '../ministryUsersTypes';
import { MinistryUser } from '../../../../usersTypes';
import MinistryUsersTableHeader from './MinistryUsersTableHeader';
import MinistryUsersTableRow from './MinistryUsersTableRow';

type Props = {
  onCommandChange: (newCommand: PaginationAndSortCommand<MinistryUsersSortableFields>) => void;
  command: PaginationAndSortCommand<MinistryUsersSortableFields>;
  paginatedUsers: PaginatedListResponse<MinistryUser> | null;
  reloadUsers: () => Promise<void>;
};

const MinistryUsersTable = ({ command, onCommandChange, paginatedUsers, reloadUsers }: Props) => {
  if (!paginatedUsers) {
    return null;
  }

  const { numberOfResults, results } = paginatedUsers;
  return (
    <Card>
      <UsersTableHeader numberOfUsers={numberOfResults} />
      <CardContent>
        {numberOfResults === 0 && <NoUsersFound />}
        {numberOfResults > 0 && (
          <>
            <Table>
              <MinistryUsersTableHeader onCommandChange={onCommandChange} command={command} />
              <TableBody>
                {results.map((user) => (
                  <MinistryUsersTableRow key={user.id} user={user} reloadUsers={reloadUsers} />
                ))}
              </TableBody>
            </Table>
            <TablePagination<MinistryUsersSortableFields>
              numberOfResults={numberOfResults}
              onCommandChange={onCommandChange}
              command={command}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default MinistryUsersTable;
