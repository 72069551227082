import { useCurrentUser } from './security/CurrentUserContext';
import { AuthenticationStatus } from './security/types';
import UnauthenticatedUserPage from './UnauthenticatedUserPage';
import AuthenticatedUserPage from './AuthenticatedUserPage';

const AuthenticationDispatch = () => {
  const { authenticationStatus } = useCurrentUser();

  switch (authenticationStatus) {
    case AuthenticationStatus.AUTHENTICATED:
      return <AuthenticatedUserPage />;
    case AuthenticationStatus.UNAUTHENTICATED:
      return <UnauthenticatedUserPage />;
    case AuthenticationStatus.PENDING_AUTHENTICATION:
      return null;
  }
};

export default AuthenticationDispatch;
