import { FileUploadFieldAdapter } from '../../../form/components/FileUploadFieldAdapter';
import { maxFileSize } from '../../../form/helper/validators';
import { MAX_UPLOAD_FILE_SIZE } from '../../attachmentUtils';

export const SignedVerdictFileUploadFieldAdapter = ({ disabled }: { disabled: boolean }) => {
  return (
    <FileUploadFieldAdapter
      name="signedVerdictFile"
      label="Document PDF de l'avis signé"
      required
      accept=".pdf"
      disabled={disabled}
      validators={[maxFileSize(MAX_UPLOAD_FILE_SIZE, '50 Mo')]}
    />
  );
};
