import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { LeafMenuEntryType } from './menuTypes';
import { Link, ListItemButton, ListItemText } from '@mui/material';
import { getPaddingLeftFactor, isActive } from './menuUtils';
import MenuEntryIcon from './MenuEntryIcon';
import { useLayoutState } from '../../LayoutContext';

type LeafMenuEntryProps = {
  menuEntry: LeafMenuEntryType;
  depth: number;
};

const LeafMenuEntry = ({ menuEntry, depth }: LeafMenuEntryProps) => {
  const location = useLocation();
  const active = isActive(menuEntry, location.pathname);
  const navigationDefinition = menuEntry.getNavigationDefinition();
  const { closeSidebar } = useLayoutState();

  return (
    <Link
      sx={{
        textDecoration: 'none',
        color: (theme) => theme.palette.text.primary,
      }}
      component={NavLink}
      to={navigationDefinition.getUrl()}
      onClick={closeSidebar}
    >
      <ListItemButton
        sx={{
          paddingLeft: (theme) => theme.spacing(getPaddingLeftFactor(depth)),
          paddingBottom: depth > 0 ? 1 : 2,
          paddingTop: depth > 0 ? 1 : 2,
        }}
        selected={active}
      >
        <MenuEntryIcon menuEntry={menuEntry} />
        <ListItemText
          sx={{
            fontSize: 14,
            lineHeight: '18px',
          }}
          primary={menuEntry.label}
        />
      </ListItemButton>
    </Link>
  );
};

export default LeafMenuEntry;
