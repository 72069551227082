import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { ButtonProps } from './buttonTypes';

const MainActionButton = ({
  action,
  className,
  colorVariant = 'NORMAL',
  disabled = false,
  dataTestId,
  href,
  label,
  type,
  sx,
  pendingSpinner,
  fileInput,
  target,
}: ButtonProps) => {
  if (!pendingSpinner) {
    return (
      <Button
        className={className}
        color={colorVariant === 'NORMAL' ? 'primary' : 'error'}
        data-testid={dataTestId}
        disabled={disabled}
        href={href}
        variant="contained"
        onClick={action}
        type={type !== 'upload' ? type : undefined}
        component={type === 'upload' ? 'label' : (undefined as any)}
        sx={sx}
        target={target}
      >
        {fileInput}
        {label}
      </Button>
    );
  } else {
    return (
      <Button sx={sx} variant="contained" color={colorVariant === 'NORMAL' ? 'primary' : 'error'}>
        <Box sx={{ visibility: 'hidden' }}>{label}</Box>
        <CircularProgress sx={{ position: 'absolute' }} size="1.7em" color="inherit" />
      </Button>
    );
  }
};

export default MainActionButton;
