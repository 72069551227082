import { Card, CardContent, CardHeader, Stack } from '@mui/material';
import { useAppContext } from '../../../AppContext';
import MainActionButton from '../../../components/buttons/MainActionButton';
import { ChangeEvent, useState } from 'react';
import { MAX_UPLOAD_FILE_SIZE } from '../../attachmentUtils';
import { Severity } from '../../../utils/severityUtils';
import ApiHelper from '../../../api/apiHelper';
import { captureException } from '@sentry/react';
import useSnackbar from '../../../snackbar/useSnackbar';
import SecondaryActionButton from '../../../components/buttons/SecondaryActionButton';
import { downloadBinaryResponse } from '../../../utils/downloadUtils';

export const ReassignDemandAgencies = () => {
  const { reassignDemands } = useAppContext();
  const [pendingUpload, setPendingUpload] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      enqueueSnackbar({
        severity: Severity.ERROR,
        content: 'Le fichier dépasse la taille maximale de fichier autorisée (50 Mo).',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    try {
      setPendingUpload(true);
      const binaryResponse = await ApiHelper.post(
        `/api/demands/reassign-agencies`,
        formData,
        undefined,
        undefined,
        true,
      );
      downloadBinaryResponse(binaryResponse);
    } catch (e) {
      enqueueSnackbar({
        severity: Severity.ERROR,
        content: "Une erreur technique s'est produite pendant le traitement.",
      });
      captureException(e);
    } finally {
      setPendingUpload(false);
    }
  };

  return (
    <Card>
      <CardHeader title={reassignDemands!.cardTitle} />
      <CardContent>
        <Stack direction="row" spacing={2}>
          <MainActionButton
            type="upload"
            label="Choisir le fichier à importer"
            pendingSpinner={pendingUpload}
            fileInput={<input hidden accept={'.csv'} type="file" onChange={handleUpload} />}
          />
          <SecondaryActionButton
            label="Télécharger un fichier d'exemple"
            href="/api/demands/reassign-agencies/example-file"
          />
        </Stack>
      </CardContent>
    </Card>
  );
};
