import { useReferralDemandFormHelper } from '../hooks/useReferralDemandFormHelper';
import { FormGridContainer } from '../../components/grid/FormGridContainer';
import { FormGridItem } from '../../components/grid/FormGridItem';
import TextFieldAdapter from '../../form/components/TextFieldAdapter';
import MainActionButton from '../../components/buttons/MainActionButton';
import { useNavigationHelper } from '../../navigation/useNavigationHelper';
import { getDemandsList } from '../../navigation/navigationUrlService';

export const ReferralDemandFormGridContainer = () => {
  const { referralDemandValue, canSearchReferralDemand } = useReferralDemandFormHelper();
  const { navigateToStaticRoute } = useNavigationHelper();

  const searchReferralDemand = () => {
    navigateToStaticRoute(getDemandsList(), {
      chronoNumber: referralDemandValue,
    });
  };

  return (
    <FormGridContainer>
      <FormGridItem>
        <TextFieldAdapter name="referralDemand" label="Demande référente" />
      </FormGridItem>
      {canSearchReferralDemand && (
        <FormGridItem>
          <MainActionButton dataTestId="search-referral-demand" label="Trouver" action={searchReferralDemand} />
        </FormGridItem>
      )}
    </FormGridContainer>
  );
};
