import { OptionsObject, useSnackbar as useNotistackSnackbar } from 'notistack';
import { Snackbar } from './Snackbar';
import { SnackbarRequest } from './snackbarUtils';
import { useCallback } from 'react';

const useSnackbar = () => {
  const { enqueueSnackbar: notistackEnqueueSnackbar, closeSnackbar } = useNotistackSnackbar();

  /**
   * This function is safe to be passed as dependency to useEffect/useMemo/etc...
   */
  const enqueueSnackbar = useCallback(
    ({ content, severity }: SnackbarRequest, options?: OptionsObject) => {
      return notistackEnqueueSnackbar(content, {
        content: (key, msg) => <Snackbar id={key} message={msg} severity={severity} />,
        ...options,
      });
    },
    [notistackEnqueueSnackbar],
  );

  return { enqueueSnackbar, closeSnackbar };
};

export default useSnackbar;
