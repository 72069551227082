import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { queryStringToFilterValues, toApiSearchCommand } from '../ministryUsersUtils';
import { MinistryUsersFilterValues, MinistryUsersSortableFields } from '../ministryUsersTypes';
import { useDataLoader } from '../../../../../hooks/useDataLoader';
import { PaginatedListResponse } from '../../../../../api/apiTypes';
import { MinistryUser } from '../../../../usersTypes';
import { PaginationAndSortCommand } from '../../../../../components/table/tableTypes';
import { mergePaginationAndSortCommandWithParams } from '../../../../../utils/tableUtils';
import { toQueryString } from '../../../../../utils/queryStringUtils';
import ApiHelper from '../../../../../api/apiHelper';

/**
 * Hook that offers utilities for the users list:
 *   - Transform the query string to filter values;
 *   - Load users.
 */
export default function useUsersListHelper() {
  const location = useLocation();
  const filterValues = useMemo(() => queryStringToFilterValues(location.search), [location.search]);
  const { paginatedUsers, reloadUsers } = useUsersLoader(filterValues);
  const onPaginationOrSortChange = useSortAndPagination(filterValues);

  return {
    filterValues,
    onPaginationOrSortChange,
    paginatedUsers,
    reloadUsers,
  };
}

function useUsersLoader(filterValues: MinistryUsersFilterValues) {
  const apiParams = useMemo(() => toApiSearchCommand(filterValues), [filterValues]);
  const { data, setData } = useDataLoader<PaginatedListResponse<MinistryUser>>('/api/users', apiParams);
  const reloadUsers = useCallback(async () => {
    const response = await ApiHelper.get('/api/users', apiParams);
    setData(response);
  }, [setData, apiParams]);
  return {
    paginatedUsers: data,
    reloadUsers,
  };
}

/**
 * Function to call to update pagination and sort values in the query string.
 */
function useSortAndPagination(filterValues: MinistryUsersFilterValues) {
  const navigate = useNavigate();
  return (newCommand: PaginationAndSortCommand<MinistryUsersSortableFields>) => {
    const values = mergePaginationAndSortCommandWithParams(filterValues, newCommand);
    const newSearch = toQueryString(values);
    navigate({ search: newSearch }, { replace: false });
  };
}
