import React from 'react';
import { Box, InputLabel } from '@mui/material';
import { DatePickerAdapter } from './DatePickerAdapter';

type DateRangePickerAdapterProps = {
  lowerBoundFieldName: string;
  label: string;
  upperBoundFieldName: string;
};

const DateRangePickerAdapter = ({ lowerBoundFieldName, label, upperBoundFieldName }: DateRangePickerAdapterProps) => {
  return (
    <Box sx={{ width: '100%' }}>
      <InputLabel shrink>{label}</InputLabel>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', columnGap: 4 }}>
        <Box sx={{ flexGrow: 1 }}>
          <DatePickerAdapter name={lowerBoundFieldName} />
        </Box>

        <Box sx={{ flexGrow: 1 }}>
          <DatePickerAdapter name={upperBoundFieldName} />
        </Box>
      </Box>
    </Box>
  );
};

export default DateRangePickerAdapter;
