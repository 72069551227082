import { useNavigate } from 'react-router-dom';
import { DynamicNavigationRoute, StaticNavigationRoute } from './navigationTypes';
import { toQueryString } from '../utils/queryStringUtils';

export const useNavigationHelper = () => {
  const navigate = useNavigate();

  // @ts-ignore
  const navigateToInternal = (url: string) => navigate(url);

  const buildUrl = (url: string, queryParams?: Record<string, any>): string => {
    if (!queryParams) {
      return url;
    }
    return `${url}?${toQueryString(queryParams)}`;
  };

  const navigateToStaticRoute = (route: StaticNavigationRoute, queryParams?: Record<string, any>) => {
    navigateToInternal(buildUrl(route.getUrl(), queryParams));
  };

  const navigateToDynamicRoute = <T>(route: DynamicNavigationRoute<T>, id: T, queryParams?: Record<string, any>) => {
    navigateToInternal(buildUrl(route.getUrl(id), queryParams));
  };

  const navigateTo = (url: string, queryParams?: Record<string, any>) => {
    navigateToInternal(buildUrl(url, queryParams));
  };
  return { navigateTo, navigateToStaticRoute, navigateToDynamicRoute };
};
