import { RequesterMissingCriteriaField, RequesterSearchCriteria } from './requesterLinkedDemandsTypes';
import { Box, List, ListItem, Typography } from '@mui/material';

type RequesterLinkedDemandsMissingCriteriaProps = {
  missingCriteria: RequesterMissingCriteriaField;
};

const missingCriteriaLabels: Record<keyof RequesterSearchCriteria, string> = {
  firstName: "Le prénom n'est pas renseigné",
  lastName: "Le nom n'est pas renseigné",
  birthDate: "La date de naissance n'est pas renseignée",
};

export const RequesterLinkedDemandsMissingCriteria = ({
  missingCriteria,
}: RequesterLinkedDemandsMissingCriteriaProps) => {
  return (
    <Box sx={{ color: 'error.main' }}>
      <Typography>Impossible de rechercher les demandes :</Typography>
      <List sx={{ listStyleType: 'disc', listStylePosition: 'inside' }}>
        {missingCriteria.map((c) => (
          <ListItem sx={{ display: 'list-item' }} key={c}>
            {missingCriteriaLabels[c]}
          </ListItem>
        ))}
      </List>
    </Box>
  );
};
