import { Demand } from '../demandTypes';
import ApiHelper from '../../api/apiHelper';
import { useFormSubmitHelper } from '../../form/helper/useFormSubmitHelper';
import { FormMode } from '../../form/helper/FormModeProvider';
import useSnackbar from '../../snackbar/useSnackbar';
import { Severity } from '../../utils/severityUtils';

export function useDemandFormSubmitHelper<D extends Demand>(mode: FormMode, demandId: string | undefined) {
  const internalSubmitForm = useFormSubmitHelper();
  const { enqueueSnackbar } = useSnackbar();

  async function submitForm<T>(command: T, onSubmitSuccess: (updatedDemand: D) => void) {
    return await internalSubmitForm(async () => {
      const updatedDemand: D =
        mode === FormMode.CREATE
          ? await ApiHelper.postJson('/api/demands', command)
          : await ApiHelper.putJson(`/api/demands/${demandId!}`, command);
      if (mode === FormMode.CREATE) {
        enqueueSnackbar({ content: 'La demande a été créée avec succès', severity: Severity.SUCCESS });
      } else {
        enqueueSnackbar({ content: 'La demande a été modifiée avec succès', severity: Severity.SUCCESS });
      }
      onSubmitSuccess(updatedDemand);
    });
  }

  return submitForm;
}
