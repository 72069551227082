import React from 'react';
import { CardHeader } from '@mui/material';
import UserAddButton from './UserAddButton';

type Props = {
  numberOfUsers: number;
};

const UsersTableHeader = ({ numberOfUsers }: Props) => {
  const title = `${numberOfUsers} utilisateur(s)`;
  return <CardHeader title={title} action={<UserAddButton />} />;
};

export default UsersTableHeader;
