import { VerdictType } from '../../../demandTypes';
import { EnumSelectAdapter } from '../../../../form/components/EnumSelectAdapter';
import { verdictTypeLabels } from '../../../demandUtils';

export const VerdictTypeSelectAdapter = ({ availableVerdictTypes }: { availableVerdictTypes: VerdictType[] }) => (
  <EnumSelectAdapter<VerdictType>
    label="Avis"
    name="verdictType"
    required
    options={availableVerdictTypes}
    optionName={(v) => verdictTypeLabels[v]}
  />
);
