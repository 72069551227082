import React from 'react';
import { usePageTitle } from '../../layout/usePageTitle';
import MinistryUserForm from '../../user/details/ministry/components/MinistryUserForm';
import { useAppContext } from '../../AppContext';
import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';
import { UserCompany } from '../../user/commonTypes';
import { Permission } from '../../security/types';
import ForbiddenCard from '../../components/cards/ForbiddenCard';

const UserPage = () => {
  usePageTitle('Utilisateur');
  const currentUser = useAuthenticatedCurrentUser();
  const { company } = currentUser.userDetails;
  const {
    users: { UserForm },
  } = useAppContext();

  if (!currentUser.userDetails.permissions.includes(Permission.ADMINISTRATE_USERS)) {
    return <ForbiddenCard />;
  }
  if (company === UserCompany.MINISTRY) {
    return <MinistryUserForm />;
  }
  return <UserForm />;
};

export default UserPage;
