import { useStandardRejectedVerdictFormHelper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useStandardRejectedVerdictFormHelper';
import { RejectionReason, VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import { useEffect } from 'react';
import { useForm } from 'react-final-form';
import { useVerdictTypeFormValue } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useVerdictTypeFormValue';

export const useSocieteMereRejectedVerdictFormHelper = (activeRejectionReasons: readonly RejectionReason[]) => {
  const standardHook = useStandardRejectedVerdictFormHelper(activeRejectionReasons);
  const { change } = useForm();
  const currentVerdictType = useVerdictTypeFormValue().value;

  useEffect(() => {
    if (currentVerdictType !== VerdictType.REJECTED) {
      change('rejectionComment', undefined);
    }
  }, [change, currentVerdictType]);

  return standardHook;
};
