import * as Sentry from '@sentry/react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@mui/material';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { DemandReceptionFormValues } from './demandReceptionFormTypes';
import { FormMode, FormModeProvider } from '../../../../form/helper/FormModeProvider';
import MainActionButton from '../../../../components/buttons/MainActionButton';
import { Demand } from '../../../demandTypes';
import useSnackbar from '../../../../snackbar/useSnackbar';
import ApiHelper from '../../../../api/apiHelper';
import SecondaryActionButton from '../../../../components/buttons/SecondaryActionButton';
import TextFieldAdapter from '../../../../form/components/TextFieldAdapter';
import { Severity } from '../../../../utils/severityUtils';
import { trim } from '../../../../utils/stringUtils';
import { download } from '../../../../utils/downloadUtils';
import { printReferralUrl } from '../demandActionUtils';

type DemandReceptionButtonProps<T extends Demand> = {
  demand: T;
  refreshDemand: () => void;
};

export const DemandReceptionButton = <T extends Demand>({ demand, refreshDemand }: DemandReceptionButtonProps<T>) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const closeDialog = () => setDialogOpened(false);

  const confirmReception = async (values: DemandReceptionFormValues) => {
    try {
      await ApiHelper.postJson(`/api/demands/${demand.id}/receive`, {
        ministryReference: trim(values.ministryReference),
      });
      enqueueSnackbar({
        content: 'La demande a été réceptionnée avec succès',
        severity: Severity.SUCCESS,
      });
      closeDialog();
      download(printReferralUrl(demand.id));
      refreshDemand();
    } catch (e) {
      enqueueSnackbar({
        content: "Une erreur s'est produite pendant la réception",
        severity: Severity.ERROR,
      });
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <MainActionButton dataTestId="receive-button" action={() => setDialogOpened(true)} label="Réceptionner" />
      <Dialog open={dialogOpened} onClose={closeDialog}>
        <FormModeProvider mode={FormMode.EDIT}>
          <Form<DemandReceptionFormValues> onSubmit={confirmReception}>
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <DialogTitle>Informations de référence</DialogTitle>
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <TextFieldAdapter label="Référence" name="ministryReference" required />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <SecondaryActionButton label="Annuler" action={closeDialog} />
                  <MainActionButton label="Réceptionner" type="submit" />
                </DialogActions>
              </form>
            )}
          </Form>
        </FormModeProvider>
      </Dialog>
    </>
  );
};
