import MainActionButton from '../../../components/buttons/MainActionButton';
import { useNavigationHelper } from '../../../navigation/useNavigationHelper';
import { getDemandsList } from '../../../navigation/navigationUrlService';
import { Demand } from '../../demandTypes';
import { useState } from 'react';
import useSnackbar from '../../../snackbar/useSnackbar';
import ApiHelper from '../../../api/apiHelper';
import { getDemandDeletionErrorSnackbar, getDemandDeletionSuccessSnackbar } from '../../../snackbar/snackbarUtils';
import * as Sentry from '@sentry/react';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import SecondaryActionButton from '../../../components/buttons/SecondaryActionButton';

type Props = {
  demand: Demand;
};

export const DeleteDemandButton = ({ demand }: Props) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const { navigateToStaticRoute } = useNavigationHelper();
  const { enqueueSnackbar } = useSnackbar();

  const closeDialog = () => setDialogOpened(false);

  const confirmDeletion = async () => {
    try {
      await ApiHelper.delete(`/api/demands/${demand.id}`);
      enqueueSnackbar(getDemandDeletionSuccessSnackbar());
      closeDialog();
      navigateToStaticRoute(getDemandsList());
    } catch (e) {
      enqueueSnackbar(getDemandDeletionErrorSnackbar());
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <MainActionButton dataTestId="deletion-button" action={() => setDialogOpened(true)} label="Supprimer" />
      <Dialog open={dialogOpened}>
        <DialogTitle>Suppression de la demande</DialogTitle>
        <DialogContent>
          <p>Voulez-vous supprimer cette demande ?</p>
        </DialogContent>
        <DialogActions>
          <SecondaryActionButton label="Annuler" action={closeDialog} />
          <MainActionButton label="Confirmer" action={confirmDeletion} />
        </DialogActions>
      </Dialog>
    </>
  );
};
