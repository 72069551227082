import React from 'react';
import { canSeeMenuEntry } from './menuUtils';
import { isLeafMenuEntry, MenuEntryType, NodeMenuEntryType } from './menuTypes';
import LeafMenuEntry from './LeafMenuEntry';
import NodeMenuEntry from './NodeMenuEntry';
import { CurrentUserDetails } from '../../../security/types';

type MenuEntryProps = {
  currentUser: CurrentUserDetails;
  depth: number;
  menuEntry: MenuEntryType;
};

const MenuEntry = ({ currentUser, menuEntry, depth }: MenuEntryProps) => {
  if (!canSeeMenuEntry(currentUser, menuEntry)) {
    return null;
  }

  if (isLeafMenuEntry(menuEntry)) {
    return <LeafMenuEntry menuEntry={menuEntry} depth={depth} />;
  }

  return <NodeMenuEntry currentUser={currentUser} depth={depth} menuEntry={menuEntry as NodeMenuEntryType} />;
};

export default MenuEntry;
