import React from 'react';
import TableCell from '../../../components/table/TableCell';

type Props<ProfileCode> = {
  profileCodes: readonly ProfileCode[];
  getProfileName: (profileCode: ProfileCode) => string;
};

const UserProfilesTableCell = <ProfileCode,>({ profileCodes, getProfileName }: Props<ProfileCode>) => {
  return (
    <TableCell>
      {profileCodes
        .map((profileCode) => getProfileName(profileCode))
        .sort()
        .join(' - ')}
    </TableCell>
  );
};
export default UserProfilesTableCell;
