import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toQueryString } from '../../utils/queryStringUtils';
import { FormSpy } from 'react-final-form';
import { FormState } from 'final-form';

type FormFiltersListenerProps<T> = {
  transformValues: (values: T) => Record<string, string>;
};

const FormFiltersListener = <T,>({ transformValues }: FormFiltersListenerProps<T>) => {
  const [initialized, setInitialized] = useState(false);
  useEffect(() => {
    setInitialized(true);
  }, []);
  const location = useLocation();
  const navigate = useNavigate();

  const onFiltersChange = useCallback(
    ({ values, valid, pristine }: FormState<any>) => {
      if (!initialized || !valid) {
        return;
      }

      const currentSearch = location.search;
      const newSearch = toQueryString(transformValues(values));
      if (currentSearch !== `?${newSearch}` && currentSearch !== newSearch && !pristine) {
        navigate({ search: newSearch }, { replace: false });
      }
    },
    [initialized, location.search, navigate, transformValues],
  );

  return <FormSpy subscription={{ values: true, valid: true, pristine: true }} onChange={onFiltersChange} />;
};

export default FormFiltersListener;
