import { SnackbarProvider as NotistackSnackbarProvider, SnackbarProviderProps } from 'notistack';
import { Box } from '@mui/material';
import { useCurrentUser } from '../security/CurrentUserContext';
import { AuthenticationStatus } from '../security/types';
import { toolbarHeight } from '../layout/layoutUtils';

const SnackbarProvider = (props: SnackbarProviderProps) => {
  const { authenticationStatus } = useCurrentUser();
  return (
    <Box
      sx={
        authenticationStatus === AuthenticationStatus.AUTHENTICATED
          ? {
              '& .SnackbarContainer-root': {
                top: `${toolbarHeight}px !important`,
              },
            }
          : undefined
      }
    >
      <NotistackSnackbarProvider
        autoHideDuration={3000}
        disableWindowBlurListener
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        {...props}
      />
    </Box>
  );
};

export default SnackbarProvider;
