import { AttachmentRenderData } from '../../attachmentTypes';
import { Attachment } from '../../demandTypes';
import { Box, Link, Tooltip } from '@mui/material';
import { Info } from '@mui/icons-material';
import { attachmentDownloadLink } from '../../attachmentUtils';

type Props = {
  attachmentData: AttachmentRenderData<any>;
  attachment: Attachment | undefined;
  renderInfoTooltip: boolean;
  downloadLink?: boolean;
};

export const AttachmentName = ({ attachmentData, attachment, renderInfoTooltip, downloadLink }: Props) => {
  const renderName = () => {
    const withCandidateInfo = (name: string) => {
      if (attachmentData.candidateInfo) {
        return `${name} de ${attachmentData.candidateInfo.candidate.firstNames} ${attachmentData.candidateInfo.candidate.lastName}`;
      }
      return name;
    };
    const withMandatoryStatus = (name: string) => {
      if (attachmentData.attachmentPresence === 'MANDATORY') {
        return `${name} (*)`;
      }
      return name;
    };

    if (attachmentData.allowMultiple && attachment) {
      return withMandatoryStatus(withCandidateInfo(`${attachmentData.attachmentTypeLabel} (${attachment.filename})`));
    }
    return withMandatoryStatus(withCandidateInfo(attachmentData.attachmentTypeLabel));
  };

  const component = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {renderName()}
      {renderInfoTooltip && attachmentData.infoTooltip && (
        <Tooltip title={attachmentData.infoTooltip} placement="top">
          <Info sx={{ marginLeft: 1 }} />
        </Tooltip>
      )}
    </Box>
  );

  if (downloadLink && attachment) {
    return (
      <Link target="_blank" href={attachmentDownloadLink(attachmentData.demandId, attachment.id)}>
        {component}
      </Link>
    );
  }
  return component;
};
