import { usePageTitle } from '../../layout/usePageTitle';
import { Box } from '@mui/material';
import { useAppContext } from '../../AppContext';
import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';
import { UserCompany } from '../../user/commonTypes';
import MinistryUsersList from '../../user/list/ministry/list/components/MinistryUsersList';
import { Permission } from '../../security/types';
import ForbiddenCard from '../../components/cards/ForbiddenCard';
import React from 'react';

export const UsersAdministrationListPage = () => {
  usePageTitle('Gestion des utilisateurs');
  const currentUser = useAuthenticatedCurrentUser();
  const { company } = currentUser.userDetails;
  const {
    users: { UsersList },
  } = useAppContext();

  if (!currentUser.userDetails.permissions.includes(Permission.ADMINISTRATE_USERS)) {
    return <ForbiddenCard />;
  }
  return <Box>{company === UserCompany.MINISTRY ? <MinistryUsersList /> : <UsersList />}</Box>;
};
