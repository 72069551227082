import { LocalDate, ZonedDateTime } from '../common-types/dateTypes';
import { Trace } from '../common-types/traceTypes';
import { MergeDeep, SetRequired } from 'type-fest';
import { Civility } from '../common-types/civilityTypes';
import { UserCompany } from '../user/commonTypes';

export type Demand = Readonly<{
  id: string;
  status: DemandStatus;
  validationDateTime?: ZonedDateTime;
  receptionDateTime?: ZonedDateTime;
  validatedByUser?: string;
  chronoNumber?: string;
  ministryReference?: string;
  verdict?: Verdict;
  comment?: string;
  attachments: readonly Attachment[];
  trace: Trace;
  company: UserCompany;
}>;

export enum DemandStatus {
  CREATED = 'CREATED',
  VALIDATED = 'VALIDATED',
  CANCELLATION_ASKED = 'CANCELLATION_ASKED',
  RECEIVED_BY_MINISTRY = 'RECEIVED_BY_MINISTRY',
  WAITING_MINISTRY_VERDICT = 'WAITING_MINISTRY_VERDICT',
  GRANTED_VERDICT = 'GRANTED_VERDICT',
  GRANTED_VERDICT_OUTSIDE_TIME_LIMIT = 'GRANTED_VERDICT_OUTSIDE_TIME_LIMIT',
  REJECTED_VERDICT = 'REJECTED_VERDICT',
  COMPLETE_CASE_VERDICT = 'COMPLETE_CASE_VERDICT',
  CANCELLED_VERDICT = 'CANCELLED_VERDICT',
}

export type Verdict = Readonly<{
  status?: VerdictType;
  dateTime?: ZonedDateTime;
  validationDateTime?: ZonedDateTime;
  rejectionReason?: RejectionReason;
  rejectionComment?: string;
  cancellationReason?: CancellationReason;
  cancellationComment?: string;
  cancellationAskedDateTime?: ZonedDateTime;
  signedVerdictFilePath?: string;
}>;

export enum VerdictType {
  GRANTED = 'GRANTED',
  GRANTED_OUTSIDE_TIME_LIMIT = 'GRANTED_OUTSIDE_TIME_LIMIT',
  REJECTED = 'REJECTED',
  COMPLETE_CASE = 'COMPLETE_CASE',
  CANCELLED = 'CANCELLED',
}

export enum RejectionReason {
  NO_BUDGET_JUSTIFICATION = 'NO_BUDGET_JUSTIFICATION',
  OTHER = 'OTHER',
  PUBLIC_ORDER_CONSIDERATIONS = 'PUBLIC_ORDER_CONSIDERATIONS',
  SOCIETES_MERES_MEETINGS_MISSED = 'SOCIETES_MERES_MEETINGS_MISSED',
  SOCIETES_MERES_MISSING_DOCUMENTS = 'SOCIETES_MERES_MISSING_DOCUMENTS',
  PMU_MEETINGS_MISSED = 'PMU_MEETINGS_MISSED',
  PMU_MISSING_DOCUMENTS = 'PMU_MISSING_DOCUMENTS',
}

export enum CancellationReason {
  NO_PRESENTATION = 'NO_PRESENTATION',
  FILE_ERROR = 'FILE_ERROR',
  FDJ_OTHER = 'FDJ_OTHER',
  INCOMPLETE_CASE = 'INCOMPLETE_CASE',
  DID_NOT_DEFER = 'DID_NOT_DEFER',
  MINISTRY_OTHER = 'MINISTRY_OTHER',
}

export type Attachment = Readonly<{
  id: string;
  type: AttachmentType;
  candidateIndex: number | null;
  path: string;
  filename: string;
  uploaderLogin: string;
  uploadedAt: ZonedDateTime;
  commenterLogin: string;
  commentedAt: ZonedDateTime;
  comment: string;
}>;

export enum AttachmentType {
  PieceIdentite = 'PieceIdentite',
  AvisImposition = 'AvisImposition',
  LivretFamille = 'LivretFamille',
  ActeAchat = 'ActeAchat',
  Bail = 'Bail',
  PromesseVente = 'PromesseVente',
  Adjudication = 'Adjudication',
  ContratLocationGerance = 'ContratLocationGerance',
  ConventionOccupation = 'ConventionOccupation',
  AccordPret = 'AccordPret',
  AccordPretBrasseur = 'AccordPretBrasseur',
  JustificationApports = 'JustificationApports',
  ExtraitK = 'ExtraitK',
  ExtraitKbis = 'ExtraitKbis',
  StatutsSociete = 'StatutsSociete',
  LiasseFiscale = 'LiasseFiscale',
  CessionParts = 'CessionParts',
  NonChangementOrganesDirection = 'NonChangementOrganesDirection',
  JustificationFondsFinancementLocationGerance = 'JustificationFondsFinancementLocationGerance',
  AutresPieces = 'AutresPieces',
  PlanFinancement = 'PlanFinancement',
  ContratAchatExclusifBoissons = 'ContratAchatExclusifBoissons',
  AttestationNonChangementOrganesDirection = 'AttestationNonChangementOrganesDirection',
  AttestationSurHonneur = 'AttestationSurHonneur',
  PlanCadastral = 'PlanCadastral',
  ContratAssociationOuLocation = 'ContratAssociationOuLocation',
  Bilan = 'Bilan',
  AttestationZoneProtegee = 'AttestationZoneProtegee',
  PlanDeFinancement = 'PlanDeFinancement',
  AttestationDemandeur = 'AttestationDemandeur',
}

export type Outlet = Readonly<{
  name: string;
  code: string;
  operatingMode: OperatingMode;
  acquisitionDate: LocalDate;
  address: OutletAddress;
  phoneNumbers: PhoneNumbers;
}>;

export enum OperatingMode {
  DIRECT_EXPLOITATION = 'DIRECT_EXPLOITATION',
  LEASE_MANAGEMENT = 'LEASE_MANAGEMENT',
  OCCUPATION = 'OCCUPATION',
}

export type Address = Readonly<{
  address1?: string;
  address2?: string;
  zipCode?: string;
  city?: string;
  country?: string;
}>;

export type PhoneNumbers = Readonly<{
  phone1: string;
  phone2?: string;
}>;

export type OutletAddress = Readonly<
  MergeDeep<
    Omit<Address, 'country'>,
    {
      address1: string;
      zipCode: string;
      city: string;
    }
  >
>;

export type Candidate<T> = Readonly<{
  type: T;
  civility: Civility;
  lastName: string;
  birthName?: string;
  firstNames: string;
  birthCity: string;
  birthDepartmentOrCountry: string;
  birthDate: LocalDate;
  email?: string;
  address: CandidateAddress;
  phoneNumbers: PhoneNumbers;
}>;

export type CandidateAddress = Readonly<
  MergeDeep<
    Address,
    {
      address1: string;
      zipCode: string;
      city: string;
    }
  >
>;

export type Acquirer = Readonly<
  | {
      type: AcquirerType.COMPANY;
      companyType: AcquirerCompanyType;
    }
  | { type: AcquirerType.IN_OWN_NAME; eirl: boolean | null }
>;

export enum AcquirerType {
  COMPANY = 'COMPANY',
  IN_OWN_NAME = 'IN_OWN_NAME',
}

export enum AcquirerCompanyType {
  SNC = 'SNC',
  SARL = 'SARL',
  SCS = 'SCS',
  SA = 'SA',
  SAS = 'SAS',
  SASU = 'SASU',
  SCA = 'SCA',
  EURL = 'EURL',
  SCP = 'SCP',
  OTHER = 'OTHER',
  EIRL = 'EIRL',
}

export type DefinedVerdict = SetRequired<Verdict, 'status'>;

export type DemandWithVerdict = MergeDeep<
  Demand,
  {
    verdict: DefinedVerdict;
  }
>;

export type DemandWithCandidates = Demand & { candidates: Candidate<any>[] };
