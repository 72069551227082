import '@fontsource/roboto';
import 'dayjs/locale/fr';
import { CurrentUserProvider } from './security/CurrentUserContext';
import { CssBaseline, Theme, ThemeProvider } from '@mui/material';
import { frFR, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AppContextProvider, AppContextType } from './AppContext';
import React from 'react';
import AuthenticationDispatch from './AuthenticationDispatch';
import { LayoutStateProvider } from './layout/LayoutContext';
import { BrowserRouter } from 'react-router-dom';
import SnackbarProvider from './snackbar/SnackbarProvider';

export type AppConfiguration = {
  appContext: AppContextType;
  theme: Theme;
};

const AppRoot = ({ appContext, theme }: AppConfiguration) => {
  return (
    <AppContextProvider ctx={appContext}>
      <BrowserRouter>
        <CurrentUserProvider>
          <ThemeProvider theme={theme}>
            <LayoutStateProvider>
              <SnackbarProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDayjs}
                  adapterLocale="fr"
                  localeText={{
                    ...frFR.components.MuiLocalizationProvider.defaultProps.localeText,
                    clearButtonLabel: 'Ré-initialiser',
                  }}
                >
                  <CssBaseline />
                  <AuthenticationDispatch />
                </LocalizationProvider>
              </SnackbarProvider>
            </LayoutStateProvider>
          </ThemeProvider>
        </CurrentUserProvider>
      </BrowserRouter>
    </AppContextProvider>
  );
};

export default AppRoot;
