import { useAppContext } from '../../AppContext';
import { usePageTitle } from '../../layout/usePageTitle';
import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';
import { Permission } from '../../security/types';
import ForbiddenCard from '../../components/cards/ForbiddenCard';
import React from 'react';
import { DirectionLabelUsage } from '../../components/direction/ImportAgencyTypes';

export const DirectionAdminPage = () => {
  const { directionAdmin } = useAppContext();

  usePageTitle(directionAdmin ? directionAdmin.buildDirectionLabel(DirectionLabelUsage.MENU_TITLE) : '');
  const currentUser = useAuthenticatedCurrentUser();

  if (!currentUser.userDetails.permissions.includes(Permission.ADMINISTRATE_DIRECTION) || directionAdmin === false) {
    return <ForbiddenCard />;
  }

  const { AppDirectionAdminPage } = directionAdmin;
  return <AppDirectionAdminPage />;
};
