import { usePageTitle } from '../../layout/usePageTitle';
import { useParams } from 'react-router-dom';
import { useDataLoader } from '../../hooks/useDataLoader';
import { FetchErrorCard } from '../../components/cards/FetchErrorCard';
import { Demand } from '../../demand/demandTypes';
import { useAppContext } from '../../AppContext';
import { pageTitle } from '../../demand/demandUtils';
import { FormMode } from '../../form/helper/FormModeProvider';

type Props =
  | { mode: FormMode.READ; onSubmitSuccess?: undefined }
  | { mode: FormMode.EDIT; onSubmitSuccess: (demand: Demand) => void };

export const DemandDetailPage = (props: Props) => {
  const { mode } = props;
  usePageTitle(pageTitle(mode));
  const {
    demand: { DemandForm },
  } = useAppContext();
  const { demandId } = useParams();
  const { data: demand, inError, setData, loading } = useDataLoader<Demand>(`/api/demands/${demandId}`);

  if (inError) {
    return <FetchErrorCard />;
  }

  if (!demand || loading) {
    return null;
  }

  return <DemandForm demand={demand} onDemandRefresh={setData} {...props} />;
};
