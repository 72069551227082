import { Card, CardContent, CardHeader, Grid, Typography } from '@mui/material';
import { useCallback, useMemo } from 'react';
import { usePromiseLoader } from '../../../hooks/usePromiseLoader';
import ApiHelper from '../../../api/apiHelper';
import { Demand } from '../../demandTypes';
import { RequesterMissingCriteriaField, RequesterSearchCriteria } from './requesterLinkedDemandsTypes';
import { RequesterLinkedDemandsMissingCriteria } from './RequesterLinkedDemandsMissingCriteria';
import { RequesterLinkedDemandsTable } from './RequesterLinkedDemandsTable';

type RequesterLinkedDemandsProps = {
  demandId: string;
  requesterIndex: number;
  requesterSearchCriteria: RequesterSearchCriteria;
};

export const RequesterLinkedDemands = ({
  demandId,
  requesterIndex,
  requesterSearchCriteria,
}: RequesterLinkedDemandsProps) => {
  const missingCriteria: RequesterMissingCriteriaField = useMemo(() => {
    let array: RequesterMissingCriteriaField = [];
    if (!Boolean(requesterSearchCriteria.firstName)) {
      array.push('firstName');
    }
    if (!Boolean(requesterSearchCriteria.lastName)) {
      array.push('lastName');
    }
    if (!Boolean(requesterSearchCriteria.birthDate)) {
      array.push('birthDate');
    }
    return array;
  }, [requesterSearchCriteria.firstName, requesterSearchCriteria.lastName, requesterSearchCriteria.birthDate]);
  const linkedDemandsLoader = useCallback(() => {
    if (missingCriteria.length === 0) {
      return ApiHelper.get(`/api/demands/${demandId}/requesters/${requesterIndex}/existing-demands`);
    } else {
      return Promise.resolve([]);
    }
  }, [demandId, missingCriteria, requesterIndex]);
  const { data: linkedDemands, inError } = usePromiseLoader<readonly Demand[]>(linkedDemandsLoader);

  return (
    <Card variant="outlined">
      <CardHeader title="Demandes associées à ce requérant" titleTypographyProps={{ variant: 'h6' }} />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography color="warning.main">
              La recherche se base sur le nom, le prénom et la date de naissance. Des incohérences sont donc possibles.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {missingCriteria.length > 0 ? (
              <RequesterLinkedDemandsMissingCriteria missingCriteria={missingCriteria} />
            ) : (
              <RequesterLinkedDemandsTable linkedDemands={linkedDemands} inError={inError} />
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
