import React from 'react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { PasswordResetForm } from './PasswordResetForm';
import { useAuthenticatedCurrentUser } from '../../CurrentUserContext';

type Props = {
  opened: boolean;
  onClose: () => void;
};

const PasswordUpdateDialog = ({ opened, onClose }: Props) => {
  const { userDetails: currentUser } = useAuthenticatedCurrentUser();
  return (
    <Dialog open={opened} onClose={onClose}>
      <DialogTitle>Modification du mot de passe</DialogTitle>
      <DialogContent>
        <PasswordResetForm login={currentUser.login} onSuccess={onClose} />
      </DialogContent>
    </Dialog>
  );
};

export default PasswordUpdateDialog;
