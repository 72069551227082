export type SortOrder = 'ASC' | 'DESC';

// zero_based page index
export const DEFAULT_PAGE = 0;
export const DEFAULT_PAGE_SIZE = 50;

export type PaginationAndSortCommand<T> = {
  page: number;
  pageSize: number;
  sortField: T;
  sortOrder: SortOrder;
};
