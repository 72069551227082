import { useLocation, useNavigate } from 'react-router-dom';
import React, { useMemo } from 'react';
import {
  parseSocieteMereDemandsListQueryString,
  toSocieteMereDemandsListApiParams,
} from './filters/societeMereDemandsFiltersUtils';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { toQueryString } from '@eogile/agrements-common/src/utils/queryStringUtils';
import { useDataLoader } from '@eogile/agrements-common/src/hooks/useDataLoader';
import { PaginatedListResponse } from '@eogile/agrements-common/src/api/apiTypes';
import SocieteMereDemandsFilters from './filters/SocieteMereDemandsFilters';
import {
  extractFormValuesFromLoadRequestParams,
  extractPaginationAndSortCommand,
  formValuesToQueryString,
  mergePaginationAndSortCommandWithParams,
} from '@eogile/agrements-common/src/utils/tableUtils';
import {
  SocieteMereDemandsLoadRequestParams,
  SocieteMereDemandsSortableFields,
  SocieteMereSimpleDemandView,
} from './societeMereDemandListTypes';
import SocieteMereDemandsTableCard from './table/SocieteMereDemandsTableCard';
import { useSocieteMereAuthenticatedCurrentUser } from '../security/hooks';

export const SocieteMereDemandsList = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { userDetails } = useSocieteMereAuthenticatedCurrentUser();

  const requestParams: SocieteMereDemandsLoadRequestParams = useMemo(() => {
    return parseSocieteMereDemandsListQueryString(location.search);
  }, [location.search]);

  const onPaginationOrSortChange = (newCommand: PaginationAndSortCommand<SocieteMereDemandsSortableFields>) => {
    const newSearch = toQueryString(mergePaginationAndSortCommandWithParams(requestParams, newCommand));
    navigate({ search: newSearch }, { replace: false });
  };

  const apiParams = useMemo(
    () => toSocieteMereDemandsListApiParams(requestParams, userDetails),
    [requestParams, userDetails],
  );
  const { data } = useDataLoader<PaginatedListResponse<SocieteMereSimpleDemandView>>('/api/demands', apiParams);

  const filterValues = extractFormValuesFromLoadRequestParams(requestParams);

  return (
    <>
      <SocieteMereDemandsFilters
        initialValues={filterValues}
        formValuesToQueryString={formValuesToQueryString(requestParams)}
      />
      {data && (
        <SocieteMereDemandsTableCard
          apiParams={apiParams}
          data={data}
          command={extractPaginationAndSortCommand(requestParams)}
          onCommandChange={onPaginationOrSortChange}
        />
      )}
    </>
  );
};
