import React, { ReactNode } from 'react';
import { IconButton, styled, Tooltip } from '@mui/material';
import { ExpandMore } from '@mui/icons-material';
import baseTheme from '../../baseTheme';

type CollapsableCardHeaderActionProps = {
  additionalAction?: ReactNode;
  opened: boolean;
  toggleOpened: () => void;
};

const StyledDiv = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const expandMoreCssVars = {
  '--transform': 'rotate(0deg)',
} as React.CSSProperties;

const expandLessCssVars = {
  '--transform': 'rotate(180deg)',
} as React.CSSProperties;

const CustomIconButton = styled(IconButton)(() => ({
  transform: 'var(--transform)',
  transition: baseTheme.transitions.create('transform', {
    duration: baseTheme.transitions.duration.shortest,
  }),
}));

const CollapsableCardHeaderAction = (props: CollapsableCardHeaderActionProps) => {
  const vars: React.CSSProperties = props.opened ? expandLessCssVars : expandMoreCssVars;

  return (
    <StyledDiv>
      {props.additionalAction && props.additionalAction}
      <Tooltip data-testid="toggle-collapsable-card" title={props.opened ? 'Fermer' : 'Ouvrir'}>
        <CustomIconButton style={vars} onClick={props.toggleOpened} size="large">
          <ExpandMore />
        </CustomIconButton>
      </Tooltip>
    </StyledDiv>
  );
};

export default CollapsableCardHeaderAction;
