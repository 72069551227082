import React from 'react';
import UserLoginTableCell from '@eogile/agrements-common/src/user/list/components/UserLoginTableCell';
import UserLastNameTableCell from '@eogile/agrements-common/src/user/list/components/UserLastNameTableCell';
import UserFirstNameTableCell from '@eogile/agrements-common/src/user/list/components/UserFirstNameTableCell';
import UserEmailTableCell from '@eogile/agrements-common/src/user/list/components/UserEmailTableCell';
import UserProfilesTableCell from '@eogile/agrements-common/src/user/list/components/UserProfilesTableCell';
import UserUpdatedAtTableCell from '@eogile/agrements-common/src/user/list/components/UserUpdatedAtTableCell';
import { SocieteMereUser } from '../../societeMereUserTypes';
import { societeMereProfileDisplayLabels } from '../../societeMereUserUtils';
import UserTableRow from '@eogile/agrements-common/src/user/list/components/UserTableRow';
import { TableCell } from '@mui/material';
import DeleteUserAction from '@eogile/agrements-common/src/user/list/components/DeleteUserAction';
import UserStatusCell from '@eogile/agrements-common/src/user/list/components/UserStatusCell';
import UnblockUserAction from '@eogile/agrements-common/src/user/list/components/UnblockUserAction';
import GeneratePasswordUserAction from '@eogile/agrements-common/src/user/list/components/GeneratePasswordUserAction';

type Props = {
  user: SocieteMereUser;
  reloadUsers: () => Promise<void>;
};

const SocieteMereUsersTableRow = ({ user, reloadUsers }: Props) => {
  return (
    <UserTableRow userId={user.id}>
      <UserLoginTableCell user={user} />
      <UserLastNameTableCell user={user} />
      <UserFirstNameTableCell user={user} />
      <UserEmailTableCell user={user} />
      <UserProfilesTableCell
        profileCodes={user.profileCodes}
        getProfileName={(profileCode) => societeMereProfileDisplayLabels[profileCode]}
      />
      <UserStatusCell user={user} />
      <UserUpdatedAtTableCell user={user} />
      <TableCell>
        <DeleteUserAction user={user} reloadUsers={reloadUsers} />
        <GeneratePasswordUserAction user={user} />
        <UnblockUserAction user={user} reloadUsers={reloadUsers} />
      </TableCell>
    </UserTableRow>
  );
};

export default SocieteMereUsersTableRow;
