import React from 'react';
import { User } from '../../usersTypes';
import ApiHelper from '../../../api/apiHelper';
import useSnackbar from '../../../snackbar/useSnackbar';
import { Severity } from '../../../utils/severityUtils';
import UserActionWithDialog from './UserActionWithDialog';
import { Delete } from '@mui/icons-material';

type Props = {
  user: User;
  reloadUsers: () => Promise<void>;
};

const DeleteUserAction = ({ user, reloadUsers }: Props) => {
  const { enqueueSnackbar } = useSnackbar();

  const deleteUser = async () => {
    await ApiHelper.delete(`/api/users/${user.id}`);
    enqueueSnackbar({
      content: "L'utilisateur a bien été supprimé",
      severity: Severity.SUCCESS,
    });
    await reloadUsers();
  };

  return (
    <UserActionWithDialog
      dialogTitle="Suppression de l'utilisateur"
      dialogContentText={`Souhaitez-vous supprimer l'utilisateur ${user.login} (${user.firstName} ${user.lastName}) ?`}
      Icon={Delete}
      iconTitle="Supprimer"
      confirmAction={deleteUser}
    />
  );
};

export default DeleteUserAction;
