import React, { useState } from 'react';
import { Collapse, ListItemButton, ListItemText } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { NodeMenuEntryType } from './menuTypes';
import MenuEntry from './MenuEntry';
import { useLocation } from 'react-router-dom';
import { getPaddingLeftFactor, isActive } from './menuUtils';
import MenuEntryIcon from './MenuEntryIcon';
import { CurrentUserDetails } from '../../../security/types';

type NodeMenuEntryProps = {
  currentUser: CurrentUserDetails;
  menuEntry: NodeMenuEntryType;
  depth: number;
};

const NodeMenuEntry = ({ currentUser, menuEntry, depth }: NodeMenuEntryProps) => {
  const location = useLocation();
  const active = isActive(menuEntry, location.pathname);
  const [opened, setOpened] = useState(active);
  const handleClick = () => setOpened(!opened);

  return (
    <>
      <ListItemButton
        sx={{
          paddingLeft: (theme) => theme.spacing(getPaddingLeftFactor(depth)),
          cursor: 'pointer',
          opacity: 1,
        }}
        onClick={handleClick}
        selected={active}
      >
        <MenuEntryIcon menuEntry={menuEntry} />
        <ListItemText
          sx={{
            fontSize: 14,
            lineHeight: '18px',
          }}
          primary={menuEntry.label}
        />
        {opened ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={opened} timeout="auto" unmountOnExit>
        {menuEntry.childrenEntries.map((childEntry) => (
          <MenuEntry currentUser={currentUser} depth={depth + 1} menuEntry={childEntry} key={childEntry.label} />
        ))}
      </Collapse>
    </>
  );
};

export default NodeMenuEntry;
