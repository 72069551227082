import React from 'react';
import useUserFormHelper from '../../hooks/useUserFormHelper';
import { Card, CardContent, Grid } from '@mui/material';
import { Form } from 'react-final-form';
import {
  computeUnselectableProfiles,
  ministryCivilityLabels,
  sortedProfileCodes,
  toApiSaveCommand,
  toFormValues,
} from '../ministryUserUtils';
import UserProfileCheckboxes from '../../components/UserProfileCheckboxes';
import UserMinistryTerritorialAreaField from './UserMinistryTerritorialAreaField';
import { ministryProfileDisplayLabels } from '../../../userUtils';
import UserCardHeader from '../../components/UserCardHeader';
import UserCardActions from '../../components/UserCardActions';
import { MinistryUserFormValues } from '../ministryUserTypes';
import { MinistryUser } from '../../../usersTypes';
import UserCommonFields from '../../components/UserCommonFields';
import UserGeneratedPasswordDialog from '../../components/UserGeneratedPasswordDialog';

const MinistryUserForm = () => {
  const { initialValues, submitUserForm, plainTextPassword, closePasswordDialog } = useUserFormHelper<MinistryUser, MinistryUserFormValues>({
    computeInitialValues: toFormValues,
    toApiUserSaveCommand: toApiSaveCommand,
  });

  const onSubmit = (values: MinistryUserFormValues) => {
    return submitUserForm(values);
  };

  return (
    <Card>
      <UserCardHeader initialFormValues={initialValues} />
      <Form<MinistryUserFormValues> initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <CardContent>
              <Grid container columnSpacing={6}>
                <Grid container item rowSpacing={2} direction="column" xs={12} sm={6}>
                  <UserCommonFields civilityLabels={ministryCivilityLabels} />
                </Grid>
                <Grid container item rowSpacing={2} direction="column" xs={12} sm={6}>
                  <Grid item>
                    <UserProfileCheckboxes
                      profileCodes={sortedProfileCodes}
                      profileNames={ministryProfileDisplayLabels}
                      computeUnselectableProfiles={computeUnselectableProfiles}
                    />
                  </Grid>
                  <Grid item>
                    <UserMinistryTerritorialAreaField />
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <UserCardActions />
            <UserGeneratedPasswordDialog plainTextPassword={plainTextPassword} onClose={closePasswordDialog} />
          </form>
        )}
      </Form>
    </Card>
  );
};

export default MinistryUserForm;
