import React, { ReactNode, useState } from 'react';
import { useField } from 'react-final-form';
import ReadOnlyField from './ReadOnlyField';
import { isDisabledOrReadFormMode } from '../helper/formUtils';
import { useFormMode } from '../helper/FormModeProvider';
import { EnhancedTextField } from './EnhancedTextField';
import { composeValidators, FieldValidator, mandatory } from '../helper/validators';
import { MaskInput } from './MaskInput';
import { FormFieldLabel } from './FormFieldLabel';

export type TextFieldAdapterProps = {
  name: string;
  label: string;
  disabled?: boolean;
  multiline?: boolean;
  required?: boolean;
  mask?: string;
  inputRef?: React.RefObject<HTMLInputElement>;
  password?: boolean;
  rows?: number;
  warningText?: string;
  cssUppercase?: boolean;
  cssCapitalize?: boolean;
  validators?: FieldValidator<string>[];
  placeholder?: string;
  onBlur?: () => void;
  /** provides a way to force an error text to be rendered independently of the field state */
  errorText?: string;
  infoTooltipContent?: ReactNode;
};

const TextFieldAdapter = ({
  disabled = false,
  label,
  multiline = false,
  name,
  mask,
  required = false,
  inputRef,
  password,
  warningText,
  cssUppercase,
  cssCapitalize,
  rows,
  validators = [],
  placeholder,
  onBlur,
  errorText,
  infoTooltipContent,
}: TextFieldAdapterProps) => {
  const { input, meta } = useField(name, {
    validate: composeValidators([required && mandatory, ...validators]),
  });
  const formMode = useFormMode();
  const [showMaskPlaceholder, setShowMaskPlaceholder] = useState(false);
  const dataTestId = `${name}-field`;

  if (isDisabledOrReadFormMode(disabled, formMode)) {
    return (
      <ReadOnlyField
        label={label}
        value={input.value}
        required={required}
        infoTooltipContent={infoTooltipContent}
        dataTestId={dataTestId + '-readonly'}
      />
    );
  }

  const displayError = Boolean(((meta.error || meta.submitError) && meta.touched) || errorText);
  const handleOnBlur = () => {
    input.onBlur();
    setShowMaskPlaceholder(false);
    onBlur?.();
  };
  const onFocus = () => setShowMaskPlaceholder(Boolean(mask));

  return (
    <EnhancedTextField
      inputRef={inputRef}
      type={password ? 'password' : undefined}
      variant="standard"
      fullWidth
      label={<FormFieldLabel label={label} required={required} infoTooltipContent={infoTooltipContent} />}
      InputLabelProps={{
        shrink: true,
      }}
      InputProps={
        mask
          ? {
              inputComponent: MaskInput as any,
              inputProps: {
                mask,
                lazy: !showMaskPlaceholder,
                onChange: input.onChange,
                onFocus: (e) => {
                  // small hack to prevent the caret position to be at the end of the masked input
                  // when navigating the fields with 'tab'
                  setTimeout(() => e.target.setSelectionRange(0, Number.MAX_SAFE_INTEGER), 0);
                },
              },
            }
          : undefined
      }
      placeholder={placeholder}
      multiline={multiline}
      rows={rows}
      data-testid={dataTestId}
      value={input.value}
      onChange={!mask ? input.onChange : undefined}
      onBlur={handleOnBlur}
      onFocus={onFocus}
      error={displayError}
      warning={Boolean(warningText)}
      helperText={(displayError && (errorText || meta.error || meta.submitError)) || warningText}
      cssUppercase={cssUppercase}
      cssCapitalize={cssCapitalize}
    />
  );
};

export default TextFieldAdapter;
