import ApiHelper from '../../../../api/apiHelper';
import { getDemandValidationErrorSnackbar } from '../../../../snackbar/snackbarUtils';
import * as Sentry from '@sentry/react';
import useSnackbar from '../../../../snackbar/useSnackbar';
import { getDemandDetail } from '../../../../navigation/navigationUrlService';
import { Severity } from '../../../../utils/severityUtils';
import { Demand } from '../../../demandTypes';
import { useNavigationHelper } from '../../../../navigation/useNavigationHelper';
import React from 'react';
import MainActionButton from '../../../../components/buttons/MainActionButton';

type Props = {
  demand: Demand;
};

export const DemandCloneButton = ({ demand }: Props) => {
  const { navigateToDynamicRoute } = useNavigationHelper();
  const { enqueueSnackbar } = useSnackbar();

  const handleClick = async () => {
    try {
      const clonedDemand = await ApiHelper.post(`/api/demands/${demand.id}/clone`, undefined);
      enqueueSnackbar({
        severity: Severity.SUCCESS,
        content: 'La demande a été clonée avec succès',
      });
      navigateToDynamicRoute(getDemandDetail(), clonedDemand.id);
    } catch (e) {
      enqueueSnackbar(getDemandValidationErrorSnackbar());
      Sentry.captureException(e);
    }
  };

  return <MainActionButton dataTestId="clone-button" action={handleClick} label="Cloner la demande" />;
};
