import { ReactNode } from 'react';
import { Box, Tooltip } from '@mui/material';
import { renderFormFieldLabel } from '../helper/formUtils';
import { Info } from '@mui/icons-material';

type Props = {
  label: string;
  required: boolean;
  infoTooltipContent?: ReactNode;
};

export const FormFieldLabel = ({ label, required = false, infoTooltipContent }: Props) => {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      {renderFormFieldLabel(label, required)}
      {infoTooltipContent && (
        <Tooltip title={infoTooltipContent} placement="top">
          <Info sx={{ marginLeft: 1 }} />
        </Tooltip>
      )}
    </Box>
  );
};
