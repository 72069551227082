import { BinaryResponse, RequestMethod, RequestParams } from './apiTypes';
import ApiError from './ApiError';
import { toQueryString } from '../utils/queryStringUtils';
import { refreshLastSuccessDate } from '../sessionwarning/sessionHelper';

const responseFileName = (response: Response, defaultFileName?: string) => {
  const disposition = response.headers.get('Content-Disposition');
  if (disposition && disposition.indexOf('attachment') !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches !== null && matches[1]) {
      return matches[1].replace(/['"]/g, '');
    }
  }
  return defaultFileName;
};

/**
 * Gets the XOR CSRF token from the cookies that will be compared with the value stored in the session.
 */
export const csrfToken = () => {
  const { cookie } = document;
  if (cookie.indexOf('CSRF-TOKEN') < 0) {
    return '';
  }

  /*
   * The regular expression was found here:
   * https://developer.mozilla.org/en-US/docs/Web/API/Document/cookie
   */
  return cookie.replace(/(?:^|.*;\s*)CSRF-TOKEN\s*=\s*([^;]*).*$|^.*$/, '$1');
};

/**
 * Return the HTTP headers to include to the HTTP request.
 */
const generateHeaders = (customHeaders: HeadersInit, withToken = true) => {
  const headers = new Headers(customHeaders);

  if (withToken) {
    headers.append('X-CSRF-TOKEN', csrfToken());
  }

  return headers;
};

/**
 * Returns the path with query string that matches the given parameters.
 */
export const pathWithQueryString = (path: string = '', params: RequestParams = {}) => {
  const queryString = toQueryString(params);
  return queryString ? `${path}?${queryString}` : path;
};

const isJsonResponseContentType = (response: Response) => {
  return (response.headers.get('Content-Type') || '').indexOf('application/json') === 0;
};

const execute = async (
  path: string,
  method: RequestMethod,
  params: RequestParams,
  body: BodyInit | null,
  headers: HeadersInit,
  binary: boolean = false,
) => {
  const response = await fetch(pathWithQueryString(path, params), {
    method,
    credentials: 'same-origin',
    body,
    headers: generateHeaders(headers),
  });
  if (response.status >= 200 && response.status < 300) {
    refreshLastSuccessDate();
  }

  if (response.status === 204) {
    return;
  }
  if (response.status === 401 && path.startsWith('/api')) {
    console.error(`Request ${path} ended with status 401. Triggering authentication`);
    // @ts-ignore
    window.location = '/';
  }
  const responseBody = await (binary
    ? response.blob().then(
        (blob): BinaryResponse => ({
          blob,
          fileName: responseFileName(response),
        }),
      )
    : isJsonResponseContentType(response)
    ? response.json()
    : response.text());
  if (response.ok) {
    return responseBody;
  }
  console.error('API error:', responseBody);
  throw new ApiError(response.status, responseBody);
};

const get = (path: string, params: RequestParams = {}, headers: HeadersInit = {}) =>
  execute(path, 'GET', params, null, headers);

const post = (path: string, body: any, params: RequestParams = {}, headers: HeadersInit = {}, binary = false) =>
  execute(path, 'POST', params, body, headers, binary);

const put = (path: string, body: any, params: RequestParams = {}, headers: HeadersInit = {}) =>
  execute(path, 'PUT', params, body, headers);

const postJson = (path: string, body: any, params: RequestParams = {}, headers: HeadersInit = {}) =>
  execute(path, 'POST', params, JSON.stringify(body), {
    ...headers,
    'Content-Type': 'application/json',
  });

const putJson = (path: string, body: any, params: RequestParams = {}, headers: HeadersInit = {}) =>
  execute(path, 'PUT', params, JSON.stringify(body), {
    ...headers,
    'Content-Type': 'application/json',
  });

const del = (path: string, params: RequestParams = {}, headers: HeadersInit = {}) =>
  execute(path, 'DELETE', params, null, headers);

//eslint-disable-next-line import/no-anonymous-default-export
export default {
  delete: del,
  get,
  post,
  put,
  postJson,
  putJson,
};
