import React from 'react';
import { Grid } from '@mui/material';
import TextFieldAdapter from '../../../form/components/TextFieldAdapter';
import { EnumSelectAdapter } from '../../../form/components/EnumSelectAdapter';
import { Civility } from '../../../common-types/civilityTypes';
import { email, minLength } from '../../../form/helper/validators';

function validateLoginIsTrimmed(login: string | null | undefined): string | undefined {
  if (!login) {
    return undefined; // Managed by another validator
  }
  if (/^\s/.test(login)) {
    return 'Le login ne peut pas commencer par un espace';
  }
  if (/\s$/.test(login)) {
    return 'Le login ne peut pas finir par un espace';
  }
  return undefined;
}

type Props = {
  civilityLabels: Record<Civility, string>;
};

/**
 * Component displaying fields that are shared to all users.
 */
const UserCommonFields = ({ civilityLabels }: Props) => {
  return (
    <>
      <Grid item>
        <TextFieldAdapter name="login" label="Login" required validators={[minLength(4), validateLoginIsTrimmed]} />
      </Grid>
      <Grid item>
        <EnumSelectAdapter
          name="civility"
          label="Civilité"
          options={Object.values(Civility)}
          optionName={(civility) => civilityLabels[civility]}
          required
        />
      </Grid>
      <Grid item>
        <TextFieldAdapter name="lastName" label="Nom" required />
      </Grid>
      <Grid item>
        <TextFieldAdapter name="firstName" label="Prénom" required />
      </Grid>
      <Grid item>
        <TextFieldAdapter name="email" label="Email" required validators={[email]} />
      </Grid>
    </>
  );
};

export default UserCommonFields;
