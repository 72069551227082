import {
  AttachmentHelper,
  AttachmentPresenceRule,
  AttachmentPresenceRules,
} from '@eogile/agrements-common/src/demand/attachmentTypes';
import { SocieteMereAcquirerType, SocieteMereAttachmentType, SocieteMereDemand } from './societeMereDemandTypes';
import { attachmentTypeDisplayOrderWeightsFromOrderedList } from '@eogile/agrements-common/src/demand/attachmentUtils';

const conditionsUtil = (demand: SocieteMereDemand) => ({
  isPerson: () => demand.requester.acquirerType === SocieteMereAcquirerType.PERSON,
  isCompany: () => demand.requester.acquirerType === SocieteMereAcquirerType.COMPANY,
});

const companyOnlyCondition: (
  type:
    | SocieteMereAttachmentType.StatutsSociete
    | SocieteMereAttachmentType.ExtraitKbis
    | SocieteMereAttachmentType.Bilan,
) => AttachmentPresenceRule<SocieteMereDemand> = (type) => (demand: SocieteMereDemand) => {
  const { isCompany } = conditionsUtil(demand);
  if (!isCompany()) {
    return 'INCOMPATIBLE';
  }
  return type !== SocieteMereAttachmentType.Bilan ? 'MANDATORY' : 'FACULTATIVE';
};

const societeMereAttachmentPresenceRules: AttachmentPresenceRules<SocieteMereDemand, SocieteMereAttachmentType> = {
  [SocieteMereAttachmentType.PieceIdentite]: () => 'MANDATORY',
  [SocieteMereAttachmentType.AvisImposition]: (demand) =>
    conditionsUtil(demand).isPerson() ? 'FACULTATIVE' : 'MANDATORY',
  [SocieteMereAttachmentType.ContratAssociationOuLocation]: (demand) => {
    const { isPerson } = conditionsUtil(demand);
    if (!isPerson()) {
      return 'INCOMPATIBLE';
    }
    return 'FACULTATIVE';
  },
  [SocieteMereAttachmentType.StatutsSociete]: companyOnlyCondition(SocieteMereAttachmentType.StatutsSociete),
  [SocieteMereAttachmentType.ExtraitKbis]: companyOnlyCondition(SocieteMereAttachmentType.ExtraitKbis),
  [SocieteMereAttachmentType.Bilan]: companyOnlyCondition(SocieteMereAttachmentType.Bilan),
  [SocieteMereAttachmentType.AutresPieces]: () => 'FACULTATIVE',
};

const societeMereAttachmentTypeLabels: Record<SocieteMereAttachmentType, string> = {
  [SocieteMereAttachmentType.PieceIdentite]: "Pièce d'identité",
  [SocieteMereAttachmentType.AvisImposition]: "Avis d'imposition",
  [SocieteMereAttachmentType.ContratAssociationOuLocation]: "Contrat d'association ou de location",
  [SocieteMereAttachmentType.StatutsSociete]: 'Statuts de la société ou projet',
  [SocieteMereAttachmentType.ExtraitKbis]: 'Extrait Kbis',
  [SocieteMereAttachmentType.Bilan]: 'Bilan/Compte de résultat',
  [SocieteMereAttachmentType.AutresPieces]: 'Autres pièces',
};

export const societeMereAttachmentHelper: AttachmentHelper<SocieteMereDemand, SocieteMereAttachmentType> = {
  attachmentTypeDisplayOrderWeights: attachmentTypeDisplayOrderWeightsFromOrderedList([
    SocieteMereAttachmentType.PieceIdentite,
    SocieteMereAttachmentType.AvisImposition,
    SocieteMereAttachmentType.ContratAssociationOuLocation,
    SocieteMereAttachmentType.StatutsSociete,
    SocieteMereAttachmentType.ExtraitKbis,
    SocieteMereAttachmentType.Bilan,
    SocieteMereAttachmentType.AutresPieces,
  ]),
  attachmentPresenceRules: societeMereAttachmentPresenceRules,
  attachmentTypeInfoTooltips: {},
  attachmentTypeLabels: societeMereAttachmentTypeLabels,
  attachmentTypesAllowMultiple: [SocieteMereAttachmentType.AutresPieces],
  attachmentTypesForCandidate: [],
};
