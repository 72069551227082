import { useField } from 'react-final-form';
import { FormMode, useFormMode } from '../../form/helper/FormModeProvider';

export const useReferralDemandFormHelper = () => {
  const formMode = useFormMode();
  const { input } = useField('referralDemand');

  return {
    canSearchReferralDemand: formMode === FormMode.READ && Boolean(input.value),
    referralDemandValue: input.value as string | undefined,
  };
};
