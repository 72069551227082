import { Card, CardContent, Table, TableBody } from '@mui/material';
import { useDataLoader } from '../../hooks/useDataLoader';
import { TemplateRow } from './TemplateRow';
import { TemplateView } from '../templateTypes';
import { useMemo } from 'react';
import { caseInsensitiveCompare } from '../../utils/stringUtils';
import { templateLabels } from '../templateUtils';
import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';

export const TemplatesAdministration = () => {
  const { data: templateViews } = useDataLoader<readonly TemplateView[]>('/api/templates');
  const {
    userDetails: { company },
  } = useAuthenticatedCurrentUser();
  const sortedTemplateViews = useMemo(() => {
    if (!templateViews) {
      return [];
    }
    const computedTemplateLabels = templateLabels(company);
    let sortedTemplates = [...templateViews];
    sortedTemplates.sort((t1, t2) =>
      caseInsensitiveCompare(
        computedTemplateLabels[t1.template.filename],
        computedTemplateLabels[t2.template.filename],
      ),
    );
    return sortedTemplates;
  }, [templateViews, company]);

  return (
    <Card>
      <CardContent>
        <Table>
          <TableBody>
            {sortedTemplateViews.map((templateView) => (
              <TemplateRow key={templateView.template.filename} templateView={templateView} />
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};
