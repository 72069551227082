import { Box, FormControl, FormHelperText, InputBase, InputLabel } from '@mui/material';
import { useField } from 'react-final-form';
import { composeValidators, FieldValidator, mandatory } from '../helper/validators';
import { ChangeEvent, ReactNode } from 'react';
import { FormFieldLabel } from './FormFieldLabel';
import { isDisabledOrReadFormMode } from '../helper/formUtils';
import { useFormMode } from '../helper/FormModeProvider';

type FileUploadFieldAdapterProps = {
  name: string;
  label: string;
  accept: string;
  disabled?: boolean;
  required?: boolean;
  validators?: FieldValidator<File>[];
  infoTooltipContent?: ReactNode;
};

export const FileUploadFieldAdapter = ({
  name,
  label,
  disabled,
  required = false,
  validators = [],
  infoTooltipContent,
  accept,
}: FileUploadFieldAdapterProps) => {
  const formMode = useFormMode();
  const { input, meta } = useField(name, {
    validate: composeValidators([required && mandatory, ...validators]),
  });
  const displayError = Boolean((meta.error || meta.submitError) && meta.touched);

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    input.onChange(file);
  };

  const onBlur = () => input.onBlur();

  return (
    <FormControl
      data-testid={`${name}-field`}
      disabled={isDisabledOrReadFormMode(disabled, formMode)}
      error={displayError}
      variant="standard"
      fullWidth
    >
      <InputLabel shrink={false}>
        <FormFieldLabel label={label} required={required} infoTooltipContent={infoTooltipContent} />
      </InputLabel>
      <Box sx={{ marginTop: 6 }}>
        <InputBase fullWidth type="file" onChange={onChange} onBlur={onBlur} inputProps={{ accept }} />
      </Box>
      {displayError && <FormHelperText>{meta.error || meta.submitError}</FormHelperText>}
    </FormControl>
  );
};
