import { Attachment, Demand } from '../../../demandTypes';
import { useAppContext } from '../../../../AppContext';
import { useMemo } from 'react';
import { attachmentKey, buildAttachmentRenderDataList } from '../../../attachmentUtils';
import { Table, TableBody, TableHead, TableRow, Theme } from '@mui/material';
import { AttachmentRenderData } from '../../../attachmentTypes';
import TableCell from '../../../../components/table/TableCell';
import { AttachmentName } from '../../attachment/AttachmentName';

type AttachmentSummary = {
  attachment: Attachment | undefined;
  attachmentRenderData: AttachmentRenderData<string>;
};

export const DemandAttachmentsValidationSummary = ({ demand }: { demand: Demand }) => {
  const {
    demand: { attachmentHelper },
  } = useAppContext();
  const attachmentSummaries = useMemo(
    () =>
      buildAttachmentRenderDataList(demand, attachmentHelper).flatMap((rd): AttachmentSummary[] => {
        if (rd.attachments.length > 0) {
          return rd.attachments.map((a) => ({ attachment: a.info, attachmentRenderData: rd }));
        }
        return [{ attachment: undefined, attachmentRenderData: rd }];
      }),
    [demand, attachmentHelper],
  );

  const summaryText = ({ attachment, attachmentRenderData }: AttachmentSummary) => {
    if (attachment) {
      return 'Pièce présente';
    } else if (attachmentRenderData.attachmentPresence === 'MANDATORY') {
      return 'Pièce manquante obligatoire';
    }
    return 'Pièce optionnelle';
  };

  const summaryColor = ({ attachment, attachmentRenderData }: AttachmentSummary, theme: Theme) => {
    if (attachment) {
      return theme.palette.success.main;
    } else if (attachmentRenderData.attachmentPresence === 'MANDATORY') {
      return theme.palette.error.main;
    }
    return theme.palette.warning.main;
  };

  return (
    <Table size="small">
      <TableHead>
        <TableCell>Bilan des pièces justificatives</TableCell>
        <TableCell />
      </TableHead>
      <TableBody>
        {attachmentSummaries.map((summary) => (
          <TableRow key={attachmentKey(summary.attachmentRenderData)}>
            <TableCell>
              <AttachmentName
                attachmentData={summary.attachmentRenderData}
                attachment={summary.attachment}
                renderInfoTooltip={false}
                downloadLink
              />
            </TableCell>
            <TableCell align="right" sx={{ color: (theme) => summaryColor(summary, theme) }}>
              {summaryText(summary)}
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};
