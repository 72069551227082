import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';
import { useAppContext } from '../../AppContext';
import { getDemandsList } from '../../navigation/navigationUrlService';
import { toQueryString } from '../../utils/queryStringUtils';

/**
 * Use this to generate a URL appropriate for redirecting a user to the demands list while taking their security
 * context into account.
 */
export const useDemandListDefaultUrlBuilder = () => {
  const { userDetails } = useAuthenticatedCurrentUser();
  const { menu } = useAppContext();

  return `${getDemandsList().getUrl()}?${toQueryString(menu.demandListQueryParameters(userDetails))}`;
};
