import { SocieteMereUser, SocieteMereUserProfile } from '../societeMereUserTypes';
import { SocieteMereUserApiSaveCommand, SocieteMereUserFormValues } from './societeMereUserFormTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { societeMereProfileDisplayLabels } from '../societeMereUserUtils';
import { CurrentUserDetails } from '@eogile/agrements-common/src/security/types';
import { CurrentSocieteMereUserDetails } from '../../security/types';
import { caseInsensitiveCompare } from '@eogile/agrements-common/src/utils/stringUtils';

/**
 * Return the form values matching the given user.
 */
export function toFormValues(user?: SocieteMereUser): SocieteMereUserFormValues {
  if (!user) {
    return formValuesForNewUser();
  }
  return {
    id: user.id,
    login: user.login,
    firstName: user.firstName,
    lastName: user.lastName,
    civility: user.civility,
    email: user.email,
    profiles: [...user.profileCodes],
  };
}

function formValuesForNewUser(): SocieteMereUserFormValues {
  return {
    id: null,
    login: null,
    firstName: null,
    lastName: null,
    civility: null,
    email: null,
    profiles: [],
  };
}

/**
 * Transform the valid form values to API request's payload.
 */
export function toApiSaveCommand(
  formValues: SocieteMereUserFormValues,
  currentUser: CurrentUserDetails,
): SocieteMereUserApiSaveCommand {
  if (![UserCompany.FRANCE_GALOP, UserCompany.LE_TROT].includes(currentUser.company)) {
    throw new Error('Unexpected company');
  }
  return {
    login: formValues.login!,
    company: (currentUser as CurrentSocieteMereUserDetails).company,
    lastName: formValues.lastName!,
    firstName: formValues.firstName!,
    civility: formValues.civility!,
    email: formValues.email!,
    profiles: [...formValues.profiles],
  };
}

/**
 * Return the "Societes meres profiles that cannot be selected.
 *
 * Currently selected profiles are always present in the response.
 */
export function computeUnselectableProfiles() {
  return []; // No incompatible profiles
}

/**
 * The profile codes sorted by profile names.
 */
export const sortedProfileCodes = Object.values(SocieteMereUserProfile).sort((p1, p2) => {
  return caseInsensitiveCompare(societeMereProfileDisplayLabels[p1], societeMereProfileDisplayLabels[p2]);
});
