// note: if there's an async action (like 'refreshDemand') that should happen around the same time as the download,
// you should call 'download' before the action: indeed, Firefox seems to abort "fetch" calls that happen just before a window.location.href.
import { BinaryResponse } from '../api/apiTypes';

export const download = (url: string) => {
  window.location.href = url;
};

export const downloadBinaryResponse = ({ blob, fileName }: BinaryResponse) => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  const url = window.URL.createObjectURL(blob);
  a.href = url;
  a.download = fileName ?? 'file';
  a.click();
  setTimeout(() => {
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  }, 0);
};
