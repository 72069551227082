import React from 'react';
import SelectAdapter, { Option, SelectAdapterProps } from './SelectAdapter';

type EnumSelectAdapterProps<T> = Omit<SelectAdapterProps<T>, 'options'> & {
  options: T[];
  optionName: (enumValue: T) => string;
};

export const EnumSelectAdapter = <T extends string>({ options, optionName, ...rest }: EnumSelectAdapterProps<T>) => {
  const selectOptions: Option<T>[] = options.map((option) => ({ id: option, name: optionName(option) }));
  return <SelectAdapter options={selectOptions} {...rest} />;
};
