import { Demand, VerdictType } from '../../../demandTypes';
import { DialogTitle } from '@mui/material';

type ValidateVerdictDialogTitleProps = {
  demand: Demand;
};

export const ValidateVerdictDialogTitle = ({ demand }: ValidateVerdictDialogTitleProps) => {
  const labels = {
    [VerdictType.GRANTED]: "Valider l'avis favorable de la demande",
    [VerdictType.GRANTED_OUTSIDE_TIME_LIMIT]: "Valider l'avis favorable HD de la demande",
    [VerdictType.COMPLETE_CASE]: "Valider l'avis Dossier complet de la demande",
    [VerdictType.REJECTED]: "Valider l'avis défavorable de la demande",
    [VerdictType.CANCELLED]: "Valider l'avis d'annulation de la demande",
  };
  return <DialogTitle data-testid="verdict-dialog-title">{labels[demand.verdict?.status!]}</DialogTitle>;
};
