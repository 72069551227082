import { DynamicNavigationRoute, StaticNavigationRoute } from './navigationTypes';

export const getUsersAdmin = (): StaticNavigationRoute => ({
  getUrl: () => `/users`,
});

export const getUserCreate = (): StaticNavigationRoute => ({
  getUrl: () => `/users/create`,
});

export const getUserEdition = (): DynamicNavigationRoute<string> => ({
  getUrl: (id) => `/users/${id}`,
  routerPath: '/users/:userId',
});

export const getDemandDetail = (): DynamicNavigationRoute<string> => ({
  getUrl: (id) => `/demands/${id}`,
  routerPath: '/demands/:demandId',
});

export const getDemandEdition = (): DynamicNavigationRoute<string> => ({
  getUrl: (id) => `/demands/${id}/edit`,
  routerPath: '/demands/:demandId/edit',
});

export const getDemandCreate = (): StaticNavigationRoute => ({
  getUrl: () => '/demands/create',
});

export const getDemandsList = (): StaticNavigationRoute => ({
  getUrl: () => `/demands`,
});

export const getTemplatesAdmin = (): StaticNavigationRoute => ({
  getUrl: () => '/templates',
});

export const getDirectionAdmin = (): StaticNavigationRoute => ({
  getUrl: () => '/agencies',
});

export const getReassignDemands = (): StaticNavigationRoute => ({
  getUrl: () => '/demands/reassign',
});

export const getGedSupervision = (): StaticNavigationRoute => ({
  getUrl: () => '/ged/supervision',
});
