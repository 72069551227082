import { parseQueryString } from '@eogile/agrements-common/src/utils/queryStringUtils';
import {
  SocieteMereDemandSearchCommand,
  SocieteMereDemandsFiltersType,
  SocieteMereDemandsLoadRequestParams,
  SocieteMereDemandsSortableFields,
} from '../societeMereDemandListTypes';
import { CurrentSocieteMereApplicationUserDetails } from '../../security/types';
import dayjs from 'dayjs';
import { validateDateRangeFilter, validateDepartmentNumber } from '@eogile/agrements-common/src/demand/demandListUtils';
import {
  parseCommonDemandsListQueryString,
  toCommonDemandsListApiParams,
} from '@eogile/agrements-common/src/demand/demandsFiltersUtils';
import { formatLocalDate } from '@eogile/agrements-common/src/utils/dateUtils';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';
import { isSocieteMereUser } from '../../user/societeMereUserUtils';
import { allSocieteMereDemandStatuses } from '../societeMereDemandUtils';
import { formatLocalDateUpperBound } from '@eogile/agrements-common/src/form/helper/formUtils';
import { DEFAULT_DEMANDS_SORT_FIELD } from '@eogile/agrements-common/src/demand/demandListTypes';

export function parseSocieteMereDemandsListQueryString(search: string): SocieteMereDemandsLoadRequestParams {
  const params = parseQueryString(search);

  return {
    requesterPrenom: (params.requesterPrenom as string) ?? null,
    requesterNom: (params.requesterNom as string) ?? null,
    requesterDateNaissanceLowerBound: params.requesterDateNaissanceLowerBound
      ? dayjs(params.requesterDateNaissanceLowerBound)
      : null,
    requesterDateNaissanceUpperBound: params.requesterDateNaissanceUpperBound
      ? dayjs(params.requesterDateNaissanceUpperBound)
      : null,
    requesterAddressPays: (params.requesterAddressPays as string) ?? null,
    requesterAddressDepartment: (params.requesterAddressDepartment as string) ?? null,
    typeAgrementCode: (params.typeAgrementCode as string) ?? null,
    typeNatureCode: (params.typeNatureCode as string) ?? null,
    sortField: (params.sortField as SocieteMereDemandsSortableFields) ?? DEFAULT_DEMANDS_SORT_FIELD,
    ...parseCommonDemandsListQueryString(search),
  };
}

export function toSocieteMereDemandsListApiParams(
  params: SocieteMereDemandsLoadRequestParams,
  userDetails: CurrentSocieteMereApplicationUserDetails,
): SocieteMereDemandSearchCommand {
  const buildStatuses = () => {
    if (isSocieteMereUser(userDetails)) {
      return params.status ? [params.status] : [];
    } else {
      // ministry users cannot see CREATED demands
      return params.status ? [params.status] : allSocieteMereDemandStatuses.filter((s) => s !== DemandStatus.CREATED);
    }
  };

  return {
    company: isSocieteMereUser(userDetails) ? userDetails.company : null,
    statuses: buildStatuses(),
    requesterPrenom: params.requesterPrenom,
    requesterNom: params.requesterNom,
    requesterDateNaissanceLowerBound: formatLocalDate(params.requesterDateNaissanceLowerBound),
    requesterDateNaissanceUpperBound: formatLocalDateUpperBound(params.requesterDateNaissanceUpperBound),
    requesterAddressPays: params.requesterAddressPays,
    requesterAddressDepartment: params.requesterAddressDepartment,
    typeAgrementCode: params.typeAgrementCode,
    typeNatureCode: params.typeNatureCode,
    ...toCommonDemandsListApiParams(params),
  };
}

export const smDemandsFiltersValidator = (formValues: SocieteMereDemandsFiltersType) => {
  return {
    departmentNumber: validateDepartmentNumber(formValues.departmentNumber),
    receptionDateUpperBound: validateDateRangeFilter(
      formValues.receptionDateLowerBound,
      formValues.receptionDateUpperBound,
    ),
    validationDateUpperBound: validateDateRangeFilter(
      formValues.validationDateLowerBound,
      formValues.validationDateUpperBound,
    ),
    verdictValidationDateUpperBound: validateDateRangeFilter(
      formValues.verdictValidationDateLowerBound,
      formValues.verdictValidationDateUpperBound,
    ),
    requesterDateNaissanceUpperBound: validateDateRangeFilter(
      formValues.requesterDateNaissanceLowerBound,
      formValues.requesterDateNaissanceUpperBound,
    ),
  };
};
