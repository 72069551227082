import * as Sentry from '@sentry/react';
import { getAskCancellationErrorSnackbar, getAskCancellationSuccessSnackbar } from '../../../../snackbar/snackbarUtils';
import { Demand } from '../../../demandTypes';
import useSnackbar from '../../../../snackbar/useSnackbar';
import ApiHelper from '../../../../api/apiHelper';
import MainActionButton from '../../../../components/buttons/MainActionButton';
import { Dialog, DialogActions, DialogTitle } from '@mui/material';
import SecondaryActionButton from '../../../../components/buttons/SecondaryActionButton';
import { useState } from 'react';
import { Form } from 'react-final-form';
import { AskCancellationFormValues } from './askCancellationButtonTypes';
import { AskCancellationDialogContent } from './AskCancellationDialogContent';
import { trim } from '../../../../utils/stringUtils';
import { FormMode, FormModeProvider } from '../../../../form/helper/FormModeProvider';

type AskCancellationDialogProps<T extends Demand> = {
  demand: T;
  refreshDemand: () => void;
};

export const AskCancellationButton = <T extends Demand>({ demand, refreshDemand }: AskCancellationDialogProps<T>) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const closeDialog = () => setDialogOpened(false);

  const askCancellation = async (values: AskCancellationFormValues) => {
    const requestBody = {
      reason: values.reason,
      comment: trim(values.comment),
    };

    try {
      await ApiHelper.postJson(`/api/demands/${demand.id}/ask-cancellation`, requestBody);
      enqueueSnackbar(getAskCancellationSuccessSnackbar());
      closeDialog();
      refreshDemand();
    } catch (e) {
      enqueueSnackbar(getAskCancellationErrorSnackbar());
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <MainActionButton
        dataTestId="ask-cancellation-button"
        action={() => setDialogOpened(true)}
        label="Demander une annulation"
      />
      <Dialog open={dialogOpened}>
        <FormModeProvider mode={FormMode.EDIT}>
          <Form<AskCancellationFormValues> onSubmit={askCancellation}>
            {(props) => (
              <form onSubmit={props.handleSubmit}>
                <DialogTitle>Demander l'annulation de la demande</DialogTitle>
                <AskCancellationDialogContent />
                <DialogActions>
                  <SecondaryActionButton label="Annuler" action={closeDialog} />
                  <MainActionButton label="Confirmer" type="submit" />
                </DialogActions>
              </form>
            )}
          </Form>
        </FormModeProvider>
      </Dialog>
    </>
  );
};
