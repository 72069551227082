import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { withFieldNamePrefix } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { email } from '@eogile/agrements-common/src/form/helper/validators';

export const SocieteMereDemandAddressSection = () => {
  const requesterFieldName = withFieldNamePrefix('requester');
  const addressFieldName = withFieldNamePrefix('requester.address');
  const phoneNumbersFieldName = withFieldNamePrefix('requester.phoneNumbers');

  // note: ZipCodeFieldAdapter and PhoneFieldAdapter are not used on purpose, this form
  // does not perform any checks on these values.
  return (
    <SectionCard title="Adresse">
      <FormGridContainer>
        <FormGridItem>
          <TextFieldAdapter name={addressFieldName('address1')} label="Adresse 1" required cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={addressFieldName('address2')} label="Adresse 2" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={addressFieldName('address3')} label="Adresse 3" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={addressFieldName('zipCode')} label="Code postal" />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={addressFieldName('city')} label="Ville" required cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={addressFieldName('country')} label="Pays" />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={phoneNumbersFieldName('phone1')} label="Téléphone 1" required />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={phoneNumbersFieldName('phone2')} label="Téléphone 2" />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={phoneNumbersFieldName('phone3')} label="Téléphone 3" />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={requesterFieldName('fax')} label="Fax" />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={requesterFieldName('email')} label="Email" validators={[email]} />
        </FormGridItem>
      </FormGridContainer>
    </SectionCard>
  );
};
