export type ImportResult = {
  nbCreated: number;
  nbUpdated: number;
  nbDeleted: number;
  errors: ImportAgencyError[];
};

export enum DirectionLabelUsage {
  MENU_TITLE = 'MENU_TITLE',
  NOMBER_CREATED = 'NOMBER_CREATED',
  NOMBER_UPDATED = 'NOMBER_UPDATED',
  NOMBER_DELETED = 'NOMBER_DELETED',
  ERROR_TITLE = 'ERROR_TITLE',
  ERROR_MESSAGE = 'ERROR_MESSAGE',
}

export type ImportAgencyError = {
  type: ImportAgencyErrorType;
  entityName: string;
};

export enum ImportAgencyErrorType {
  INVALID_COMMAND = 'INVALID_COMMAND',
  AGENCY_REFERENCED_BY_USER = 'AGENCY_REFERENCED_BY_USER',
  AGENCY_REFERENCED_BY_DEMAND = 'AGENCY_REFERENCED_BY_DEMAND',
  REGIONAL_DIRECTION_REFERENCED_BY_USER = 'REGIONAL_DIRECTION_REFERENCED_BY_USER',
  ABORTED_IMPORT = 'ABORTED_IMPORT',
  UNEXPECTED_ERROR = 'UNEXPECTED_ERROR',
}

export type ImportAgencyErrorItemProps = { errorDetail: ImportAgencyError };
