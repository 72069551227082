import { DemandAction, DemandActionType } from './demandActionTypes';
import { FormMode } from '../../../form/helper/FormModeProvider';
import MainActionButton from '../../../components/buttons/MainActionButton';
import { Permission } from '../../../security/types';
import { isMinistryUser } from '../../../user/userUtils';
import { DemandStatus } from '../../demandTypes';
import { EditDemandButton } from './EditDemandButton';
import React from 'react';
import { DemandValidationButton } from './validatedemand/DemandValidationButton';
import { AskCancellationButton } from './askcancellation/AskCancellationButton';
import { DeleteDemandButton } from './DeleteDemandButton';
import { UpdateAttachmentsActionButton } from './UpdateAttachmentsActionButton';
import { DemandReceptionButton } from './reception/DemandReceptionButton';
import { VerdictInputButton } from './verdictinput/VerdictInputButton';
import { hasPermission } from '../../../security/securityUtils';
import { ValidateVerdictButton } from './validateverdict/ValidateVerdictButton';
import {
  demandIsInFinalState,
  demandIsValidatedAndNotInFinalState,
  needsVerdictRejectedReason,
} from '../../demandUtils';
import { UploadSignedVerdictButton } from './uploadsignedverdict/UploadSignedVerdictButton';
import { UserCompany } from '../../../user/commonTypes';
import { printReferralUrl, printVerdictUrl } from './demandActionUtils';
import dayjs from 'dayjs';
import { DemandCloneButton } from './clone/DemandCloneButton';

const saveAction: DemandAction = {
  type: DemandActionType.SAVE,
  canDoAction: ({ mode }) => mode === FormMode.CREATE || mode === FormMode.EDIT,
  renderComponent: () => <MainActionButton type="submit" label="Enregistrer" />,
};

const editAction: DemandAction = {
  type: DemandActionType.EDIT,
  canDoAction: ({ mode, demand, userDetails }) => {
    if (mode !== FormMode.READ || !userDetails.permissions.includes(Permission.UPDATE_DEMAND)) {
      return false;
    }
    if (isMinistryUser(userDetails)) {
      return [
        DemandStatus.VALIDATED,
        DemandStatus.RECEIVED_BY_MINISTRY,
        DemandStatus.WAITING_MINISTRY_VERDICT,
      ].includes(demand.status);
    }
    return demand.status === DemandStatus.CREATED;
  },
  renderComponent: (demand) => <EditDemandButton demand={demand} />,
};

const validateAction: DemandAction = {
  type: DemandActionType.VALIDATE,
  canDoAction: ({ mode, demand, userDetails }) => {
    if (mode !== FormMode.READ || !userDetails.permissions.includes(Permission.VALIDATE_DEMAND)) {
      return false;
    }
    return demand.status === DemandStatus.CREATED;
  },
  renderComponent: (demand, refreshDemand) => <DemandValidationButton demand={demand} refreshDemand={refreshDemand} />,
};

const askCancellationAction: DemandAction = {
  type: DemandActionType.ASK_CANCELLATION,
  canDoAction: ({ mode, demand, userDetails }) => {
    if (mode !== FormMode.READ || !userDetails.permissions.includes(Permission.ASK_CANCELLATION)) {
      return false;
    }

    if (demand?.verdict?.cancellationAskedDateTime) {
      // if there has been a cancellation verdict, don't display this button
      return false;
    }

    return [DemandStatus.RECEIVED_BY_MINISTRY, DemandStatus.WAITING_MINISTRY_VERDICT].includes(demand.status);
  },
  renderComponent: (demand, refreshDemand) => <AskCancellationButton demand={demand} refreshDemand={refreshDemand} />,
};

const deleteAction: DemandAction = {
  type: DemandActionType.DELETE,
  canDoAction: ({ mode, demand, userDetails }) => {
    if (mode !== FormMode.READ || !userDetails.permissions.includes(Permission.DELETE_DEMAND)) {
      return false;
    }
    return [DemandStatus.CREATED, DemandStatus.VALIDATED].includes(demand?.status);
  },
  renderComponent: (demand) => <DeleteDemandButton demand={demand} />,
};

const updateAttachmentsAction: DemandAction = {
  type: DemandActionType.UPDATE_ATTACHMENTS,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      userDetails.permissions.includes(Permission.UPDATE_DEMAND_ATTACHMENT) &&
      (demand.status === DemandStatus.CREATED ||
        // PMU user can still add attachments after validation, before final state
        (userDetails.company === UserCompany.PMU && demandIsValidatedAndNotInFinalState(demand)))
    );
  },
  renderComponent: (demand, refreshDemand) => (
    <UpdateAttachmentsActionButton demand={demand} refreshDemand={refreshDemand} />
  ),
};

const receiveAction: DemandAction = {
  type: DemandActionType.RECEIVE,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      userDetails.permissions.includes(Permission.RECEIVE_DEMAND) &&
      demand.status === DemandStatus.VALIDATED
    );
  },
  renderComponent: (demand, refreshDemand) => <DemandReceptionButton demand={demand} refreshDemand={refreshDemand} />,
};
const printReceiptAction: DemandAction = {
  type: DemandActionType.PRINT_RECEIPT,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      userDetails.permissions.includes(Permission.PRINT_RECEIPT) &&
      ([DemandStatus.RECEIVED_BY_MINISTRY, DemandStatus.CANCELLATION_ASKED].includes(demand?.status) ||
        demandIsInFinalState(demand!))
    );
  },
  renderComponent: (demand) => (
    <MainActionButton
      dataTestId="print-receipt-button"
      href={`/api/demands/files/${demand.id}/receipt`}
      label="Imprimer le récépissé"
    />
  ),
};

const setVerdictAction: DemandAction = {
  type: DemandActionType.SET_VERDICT,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      hasPermission(userDetails, Permission.SET_VERDICT) &&
      [
        DemandStatus.RECEIVED_BY_MINISTRY,
        DemandStatus.CANCELLATION_ASKED,
        DemandStatus.WAITING_MINISTRY_VERDICT,
      ].includes(demand!.status)
    );
  },
  renderComponent: (demand, refreshDemand) => <VerdictInputButton demand={demand} refreshDemand={refreshDemand} />,
};

const validateVerdictAction: DemandAction = {
  type: DemandActionType.VALIDATE_VERDICT,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      hasPermission(userDetails, Permission.VALIDATE_VERDICT) &&
      demand!.status === DemandStatus.WAITING_MINISTRY_VERDICT
    );
  },
  renderComponent: (demand, refreshDemand) => <ValidateVerdictButton demand={demand} refreshDemand={refreshDemand} />,
};

const printDemandAction: DemandAction = {
  type: DemandActionType.PRINT_DEMAND,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      userDetails.permissions.includes(Permission.PRINT_DEMAND) &&
      [
        DemandStatus.CREATED,
        DemandStatus.VALIDATED,
        DemandStatus.RECEIVED_BY_MINISTRY,
        DemandStatus.WAITING_MINISTRY_VERDICT,
      ].includes(demand.status)
    );
  },
  renderComponent: (demand) => (
    <MainActionButton dataTestId="print-button" href={`/api/demands/files/${demand.id}/demand-file`} label="Imprimer" />
  ),
};

const uploadSignedVerdictAction: DemandAction = {
  type: DemandActionType.UPLOAD_SIGNED_VERDICT,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      hasPermission(userDetails, Permission.UPLOAD_SIGNED_VERDICT) &&
      demandIsInFinalState(demand) &&
      !Boolean(demand?.verdict?.signedVerdictFilePath) &&
      dayjs(demand?.verdict?.validationDateTime).isAfter(dayjs().subtract(12, 'month'))
    );
  },
  renderComponent: (demand, refreshDemand) => (
    <UploadSignedVerdictButton demand={demand} refreshDemand={refreshDemand} />
  ),
};

const printReferralAction: DemandAction = {
  type: DemandActionType.PRINT_REFERRAL,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      userDetails.permissions.includes(Permission.PRINT_REFERRAL) &&
      [
        DemandStatus.RECEIVED_BY_MINISTRY,
        DemandStatus.CANCELLATION_ASKED,
        DemandStatus.WAITING_MINISTRY_VERDICT,
      ].includes(demand?.status)
    );
  },
  renderComponent: (demand) => (
    <MainActionButton
      dataTestId="print-referral-button"
      href={printReferralUrl(demand.id)}
      label="Courrier de Saisine"
    />
  ),
};

const printOutletVerdictAction: DemandAction = {
  type: DemandActionType.PRINT_OUTLET_VERDICT,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      userDetails.permissions.includes(Permission.PRINT_OUTLET_VERDICT) &&
      demandIsInFinalState(demand!)
    );
  },
  renderComponent: (demand) => (
    <MainActionButton
      dataTestId="print-outlet-verdict-button"
      href={`/api/demands/files/${demand.id}/outlet-verdict-file`}
      label="Avis à remettre"
    />
  ),
};

const printVerdictRejectedReasonAction: DemandAction = {
  type: DemandActionType.PRINT_VERDICT_REJECTED_REASON,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      userDetails.permissions.includes(Permission.PRINT_VERDICT_REJECTED_REASON) &&
      needsVerdictRejectedReason(demand)
    );
  },
  renderComponent: (demand) => (
    <MainActionButton
      dataTestId="print-verdict-rejected-reason-button"
      href={`/api/demands/files/${demand.id}/verdict-rejected-reason`}
      label="Motivations de l'avis défavorable"
    />
  ),
};

const printVerdictAction: DemandAction = {
  type: DemandActionType.PRINT_VERDICT,
  canDoAction: ({ mode, demand, userDetails }) => {
    if (mode !== FormMode.READ) {
      return false;
    }
    if (!userDetails.permissions.includes(Permission.PRINT_VERDICT)) {
      return false;
    }
    if (userDetails.company === UserCompany.MINISTRY) {
      return Boolean(demand?.verdict?.status);
    }
    return demandIsInFinalState(demand) && Boolean(demand?.verdict?.signedVerdictFilePath);
  },
  renderComponent: (demand) => (
    <MainActionButton
      dataTestId="print-verdict"
      target={'_blank'}
      href={printVerdictUrl(demand.id)}
      label={Boolean(demand?.verdict?.signedVerdictFilePath) ? 'Avis à conserver' : "Imprimer l'avis"}
    />
  ),
};

const cloneAction: DemandAction = {
  type: DemandActionType.CLONE,
  canDoAction: ({ mode, demand, userDetails }) => {
    return (
      mode === FormMode.READ &&
      userDetails.permissions.includes(Permission.CLONE_DEMAND) &&
      demandIsInFinalState(demand)
    );
  },
  renderComponent: (demand) => <DemandCloneButton demand={demand} />,
};

/**
 * This object contains the (ordered) list of actions that should be displayed in the DemandActions component
 * depending on:
 * - the demand itself
 * - the current user information
 * - the demand form mode
 *
 * If additional checks should be performed specifically for a project, you should add a "canDoDemandActionPostFilter"
 * on the AppContext (already done on PMU & FDJ to handle agency user restrictions).
 */
export const demandActionDefinitions: DemandAction[] = [
  saveAction,
  editAction,
  updateAttachmentsAction,
  receiveAction,
  validateAction,
  askCancellationAction,
  setVerdictAction,
  validateVerdictAction,
  uploadSignedVerdictAction,
  deleteAction,
  cloneAction,
  printDemandAction,
  printReceiptAction,
  printReferralAction,
  printOutletVerdictAction,
  printVerdictRejectedReasonAction,
  printVerdictAction,
];
