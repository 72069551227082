import { SessionWarningDialog } from './SessionWatcherDialog';
import ApiHelper from '../api/apiHelper';
import React, { useEffect, useState } from 'react';
import { SessionWarningStatus } from './sessionWarningTypes';
import dayjs from 'dayjs';
import { readLastSuccessDate, refreshLastSuccessDate } from './sessionHelper';

export const SessionWarningContainer = ({
  warningLimitInSecond,
  endOfSessionLimitInSecond,
}: {
  warningLimitInSecond: number;
  endOfSessionLimitInSecond: number;
}) => {
  const [sessionWarningStatus, setSessionWarningStatus] = useState<SessionWarningStatus>(
    SessionWarningStatus.NOT_DISPLAYED,
  );

  useEffect(() => {
    // manually initialize the value to avoid case like login event without making any api call but via SAML
    refreshLastSuccessDate();
  }, []);

  useEffect(() => {
    const id = setInterval(() => {
      const lastSuccessDate = readLastSuccessDate();
      const diffInSecond = dayjs().diff(lastSuccessDate, 'second');

      if (diffInSecond > endOfSessionLimitInSecond) {
        // current user's last success activity was more than "endOfSessionLimitInSecond" seconds ago
        setSessionWarningStatus(SessionWarningStatus.END_OF_SESSION);
      } else if (diffInSecond >= warningLimitInSecond) {
        // Last success activity was more than "warningLimitInSecond" seconds ago
        setSessionWarningStatus(SessionWarningStatus.WARNING);
      } else {
        setSessionWarningStatus(SessionWarningStatus.NOT_DISPLAYED);
      }
    }, 1000);

    return () => {
      clearInterval(id);
    };
  }, [endOfSessionLimitInSecond, sessionWarningStatus, warningLimitInSecond]);

  const touchSessionAction = () => {
    setSessionWarningStatus(SessionWarningStatus.NOT_DISPLAYED);
    ApiHelper.post('/api/user/touch-session', undefined);
  };

  return (
    <SessionWarningDialog
      status={sessionWarningStatus}
      touchSessionAction={touchSessionAction}
      endOfSessionLimitInSecond={endOfSessionLimitInSecond}
    />
  );
};
