import { useEffect, useMemo } from 'react';
import { Demand, VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import { hasAskedCancellation } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputFormUtils';
import { useForm } from 'react-final-form';
import { useVerdictTypeFormValue } from './useVerdictTypeFormValue';

export const useStandardVerdictTypeFormHelper = (demand: Demand) => {
  const availableVerdictTypes = useMemo<VerdictType[]>(() => {
    if (hasAskedCancellation(demand)) {
      return [VerdictType.CANCELLED];
    } else {
      return [VerdictType.GRANTED, VerdictType.REJECTED, VerdictType.CANCELLED];
    }
  }, [demand]);
  const { change } = useForm();
  const currentVerdictType = useVerdictTypeFormValue().value;

  useEffect(() => {
    if (currentVerdictType && !availableVerdictTypes.includes(currentVerdictType)) {
      change('verdictType', undefined);
    }
  }, [availableVerdictTypes, currentVerdictType, change]);

  return {
    currentVerdictType: currentVerdictType,
    availableVerdictTypes,
  };
};
