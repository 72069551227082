import { usePageTitle } from '../../layout/usePageTitle';
import { pageTitle } from '../../demand/demandUtils';
import { FormMode } from '../../form/helper/FormModeProvider';
import { useAppContext } from '../../AppContext';
import { useNavigationHelper } from '../../navigation/useNavigationHelper';
import { getDemandDetail } from '../../navigation/navigationUrlService';
import { Demand } from '../../demand/demandTypes';

export const DemandCreatePage = () => {
  const formMode = FormMode.CREATE;
  usePageTitle(pageTitle(formMode));
  const {
    demand: { DemandForm },
  } = useAppContext();
  const { navigateToDynamicRoute } = useNavigationHelper();

  return (
    <DemandForm
      mode={formMode}
      onSubmitSuccess={(demand: Demand) => navigateToDynamicRoute(getDemandDetail(), demand.id)}
      onDemandRefresh={() => {}}
    />
  );
};
