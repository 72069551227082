import React, { useState } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { SvgIconComponent } from '@mui/icons-material';
import SecondaryActionButton from '../../../components/buttons/SecondaryActionButton';
import MainActionButton from '../../../components/buttons/MainActionButton';

type Props = {
  dialogTitle: string;
  dialogContentText: string;
  confirmAction: () => Promise<void>;
  Icon: SvgIconComponent;
  iconTitle: string;
};

const UserActionWithDialog = ({ dialogTitle, dialogContentText, Icon, iconTitle, confirmAction }: Props) => {
  const [opened, setOpened] = useState(false);

  const openDialog = () => {
    setOpened(true);
  };

  const closeDialog = () => {
    setOpened(false);
  };

  const confirm = async () => {
    await confirmAction();
    closeDialog();
  };

  return (
    <span onClick={(e) => e.stopPropagation()}>
      <IconButton onClick={openDialog} title={iconTitle}>
        <Icon />
      </IconButton>
      <Dialog open={opened} onClose={closeDialog}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>{dialogContentText}</DialogContent>
        <DialogActions>
          <SecondaryActionButton label="Annuler" action={closeDialog} />
          <MainActionButton label="Confirmer" action={confirm} />
        </DialogActions>
      </Dialog>
    </span>
  );
};

export default UserActionWithDialog;
