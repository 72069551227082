import { VerdictInputDialogProps } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/verdictInputComponentTypes';
import { SocieteMereDemand } from '../../../societeMereDemandTypes';
import { useMemo } from 'react';
import {
  buildInitialSocieteMereVerdictInputFormValues,
  buildSocieteMereVerdictInputCommand,
} from './societeMereVerdictInputFormUtils';
import { SocieteMereVerdictInputFormContent } from './SocieteMereVerdictInputFormContent';
import { VerdictInputDialogWrapper } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/VerdictInputDialogWrapper';

export const SocieteMereVerdictInputDialog = ({
  demand,
  open,
  onClose,
  onSubmitSuccess,
}: VerdictInputDialogProps<SocieteMereDemand>) => {
  const initialFormValues = useMemo(() => buildInitialSocieteMereVerdictInputFormValues(demand), [demand]);

  return (
    <VerdictInputDialogWrapper
      demand={demand}
      open={open}
      onClose={onClose}
      initialFormValues={initialFormValues}
      formValuesToCommand={buildSocieteMereVerdictInputCommand}
      onSubmitSuccess={onSubmitSuccess}
    >
      <SocieteMereVerdictInputFormContent demand={demand} />
    </VerdictInputDialogWrapper>
  );
};
