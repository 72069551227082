import React, { ReactNode } from 'react';
import { TableRow } from '@mui/material';
import { useNavigationHelper } from '../../../navigation/useNavigationHelper';
import { getUserEdition } from '../../../navigation/navigationUrlService';

type Props = {
  userId: string;
  children: ReactNode;
};

const UserTableRow = ({ children, userId }: Props) => {
  const { navigateToDynamicRoute } = useNavigationHelper();

  const redirectToUserPage = () => {
    navigateToDynamicRoute(getUserEdition(), userId);
  };

  return (
    <TableRow onClick={redirectToUserPage} sx={{ cursor: 'pointer' }}>
      {children}
    </TableRow>
  );
};

export default UserTableRow;
