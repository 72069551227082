import React from 'react';
import { Card, CardContent, Table, TableBody, TableContainer } from '@mui/material';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import SocieteMereUsersTableHeader from './SocieteMereUsersTableHeader';
import { PaginatedListResponse } from '@eogile/agrements-common/src/api/apiTypes';
import UsersTableHeader from '@eogile/agrements-common/src/user/list/components/UsersTableHeader';
import NoUsersFound from '@eogile/agrements-common/src/user/list/components/NoUsersFound';
import SocieteMereUsersTableRow from './SocieteMereUsersTableRow';
import TablePagination from '@eogile/agrements-common/src/components/table/TablePagination';
import { SocieteMereUsersSortableFields } from '../societeMereUsersTypes';
import { SocieteMereUser } from '../../societeMereUserTypes';

type Props = {
  onCommandChange: (newCommand: PaginationAndSortCommand<SocieteMereUsersSortableFields>) => void;
  command: PaginationAndSortCommand<SocieteMereUsersSortableFields>;
  paginatedUsers: PaginatedListResponse<SocieteMereUser> | null;
  reloadUsers: () => Promise<void>;
};

const SocieteMereUsersTable = ({ command, onCommandChange, paginatedUsers, reloadUsers }: Props) => {
  if (!paginatedUsers) {
    return null;
  }

  const { numberOfResults, results } = paginatedUsers;
  return (
    <Card>
      <UsersTableHeader numberOfUsers={numberOfResults} />
      <CardContent>
        {numberOfResults === 0 && <NoUsersFound />}
        {numberOfResults > 0 && (
          <>
            <TableContainer>
              <Table>
                <SocieteMereUsersTableHeader onCommandChange={onCommandChange} command={command} />
                <TableBody>
                  {results.map((user) => (
                    <SocieteMereUsersTableRow key={user.id} user={user} reloadUsers={reloadUsers} />
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination<SocieteMereUsersSortableFields>
              numberOfResults={numberOfResults}
              onCommandChange={onCommandChange}
              command={command}
            />
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default SocieteMereUsersTable;
