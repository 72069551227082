import React, { ReactNode } from 'react';
import { Autocomplete, AutocompleteRenderInputParams, useMediaQuery } from '@mui/material';
import { useField } from 'react-final-form';
import ReadOnlyField from './ReadOnlyField';
import { isDisabledOrReadFormMode } from '../helper/formUtils';
import { useFormMode } from '../helper/FormModeProvider';
import { composeValidators, FieldValidator, mandatory } from '../helper/validators';
import { EnhancedTextField } from './EnhancedTextField';
import { FormFieldLabel } from './FormFieldLabel';

export type Option<T> = {
  id: T;
  name: string;
};

export type SelectAdapterProps<T> = {
  disabled?: boolean;
  label: string;
  name: string;
  onSelect?: (id: T | null) => void;
  options: Option<T>[];
  required?: boolean;
  placeholder?: string;
  disableClearable?: boolean;
  warningText?: string;
  validators?: FieldValidator<T>[];
  infoTooltipContent?: ReactNode;
};

const SelectAdapter = <T extends string | number = string>({
  disabled = false,
  label,
  name,
  onSelect,
  options,
  required = false,
  placeholder,
  disableClearable = false,
  warningText,
  validators = [],
  infoTooltipContent,
}: SelectAdapterProps<T>) => {
  const isTouchDevice = useMediaQuery('(hover:none)');
  const { input, meta } = useField(name, { validate: composeValidators([required && mandatory, ...validators]) });
  const formMode = useFormMode();
  const value = options.find((option) => option.id === input.value);
  const dataTestId = `${name}-field`;

  if (isDisabledOrReadFormMode(disabled, formMode)) {
    return (
      <ReadOnlyField
        label={label}
        value={value?.name}
        required={required}
        dataTestId={dataTestId + '-readonly'}
        infoTooltipContent={infoTooltipContent}
      />
    );
  }

  const handleChange = (event: any, newValue: Option<T> | null) => {
    touchField();
    input.onChange(newValue?.id);
    if (onSelect != null) {
      onSelect(newValue?.id ?? null);
    }
  };

  const touchField = () => {
    input.onBlur();
  };

  const displayError = Boolean((meta.error || meta.submitError) && meta.touched);

  const Input = (params: AutocompleteRenderInputParams) => (
    <EnhancedTextField
      {...params}
      label={<FormFieldLabel label={label} required={required} infoTooltipContent={infoTooltipContent} />}
      variant="standard"
      InputLabelProps={{
        shrink: true,
      }}
      inputProps={{
        ...params.inputProps,
        inputMode: isTouchDevice ? 'none' : undefined,
        readOnly: isTouchDevice,
      }}
      placeholder={placeholder}
      error={displayError}
      helperText={(displayError && (meta.error || meta.submitError)) || warningText}
      warning={Boolean(warningText)}
      FormHelperTextProps={{}}
    />
  );
  return (
    <Autocomplete
      options={options}
      disableClearable={disableClearable}
      // option can be undefined when using Final-Form because it transforms '' into 'undefined'
      // the ?? fallback is here to prevent an error from showing up in the console even though this isn't a real error
      getOptionLabel={(option) => option.name ?? ''}
      noOptionsText="Aucun résultat trouvé"
      style={{ width: '100%' }}
      onChange={handleChange}
      onBlur={touchField}
      renderInput={Input}
      value={value ?? null}
      data-testid={dataTestId}
    />
  );
};

export default SelectAdapter;
