import { MinistryTerritorialArea, MinistryUserProfile } from '../user/ministryUserTypes';
import { UserCompany } from '../user/commonTypes';

export type CurrentUser = PendingAuthenticationCurrentUser | UnauthenticatedCurrentUser | AuthenticatedCurrentUser;

export enum AuthenticationStatus {
  PENDING_AUTHENTICATION = 'PENDING_AUTHENTICATION',
  AUTHENTICATED = 'AUTHENTICATED',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export type PendingAuthenticationCurrentUser = Readonly<{
  authenticationStatus: AuthenticationStatus.PENDING_AUTHENTICATION;
}>;

export type UnauthenticatedCurrentUser = Readonly<{
  authenticationStatus: AuthenticationStatus.UNAUTHENTICATED;
}>;

export type AuthenticatedCurrentUser<T extends CurrentUserDetails = CurrentUserDetails> = Readonly<{
  authenticationStatus: AuthenticationStatus.AUTHENTICATED;
  userDetails: T;
}>;

export type CurrentUserDetails = Readonly<{
  userId: string;
  login: string;
  company: UserCompany;
  firstName: string;
  lastName: string;
  profiles: readonly string[];
  permissions: readonly Permission[];
}>;

export type CurrentMinistryUserDetails = Omit<CurrentUserDetails, 'profiles'> &
  Readonly<{
    company: UserCompany.MINISTRY;
    profiles: readonly MinistryUserProfile[];
    territorialArea: MinistryTerritorialArea | null;
  }>;

export enum AuthenticationFailureEvent {
  CREDENTIALS_EXPIRED = 'CREDENTIALS_EXPIRED',
  ACCOUNT_BLOCKED = 'ACCOUNT_BLOCKED',
}

export enum Permission {
  ADMINISTRATE_USERS = 'ADMINISTRATE_USERS',
  READ_DEMAND = 'READ_DEMAND',
  UPDATE_DEMAND = 'UPDATE_DEMAND',
  CREATE_DEMAND = 'CREATE_DEMAND',
  VALIDATE_DEMAND = 'VALIDATE_DEMAND',
  ASK_CANCELLATION = 'ASK_CANCELLATION',
  DELETE_DEMAND = 'DELETE_DEMAND',
  UPDATE_DEMAND_ATTACHMENT = 'UPDATE_DEMAND_ATTACHMENT',
  RECEIVE_DEMAND = 'RECEIVE_DEMAND',
  SET_VERDICT = 'SET_VERDICT',
  VALIDATE_VERDICT = 'VALIDATE_VERDICT',
  PRINT_DEMAND = 'PRINT_DEMAND',
  PRINT_RECEIPT = 'PRINT_RECEIPT',
  UPLOAD_SIGNED_VERDICT = 'UPLOAD_SIGNED_VERDICT',
  PRINT_REFERRAL = 'PRINT_REFERRAL',
  PRINT_OUTLET_VERDICT = 'PRINT_OUTLET_VERDICT',
  PRINT_VERDICT_REJECTED_REASON = 'PRINT_VERDICT_REJECTED_REASON',
  PRINT_VERDICT = 'PRINT_VERDICT',
  ADMINISTRATE_TEMPLATES = 'ADMINISTRATE_TEMPLATES',
  ADMINISTRATE_DIRECTION = 'ADMINISTRATE_DIRECTION',
  CLONE_DEMAND = 'CLONE_DEMAND',
  SIGNED_VERDICT_SUPERVISION = 'SIGNED_VERDICT_SUPERVISION',
}
