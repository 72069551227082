import React, { ReactNode } from 'react';
import { TextField } from '@mui/material';
import { useField } from 'react-final-form';
import ReadOnlyField from './ReadOnlyField';
import { isDisabledOrReadFormMode } from '../helper/formUtils';
import { useFormMode } from '../helper/FormModeProvider';
import { FormFieldLabel } from './FormFieldLabel';

type NumberFieldAdapterProps = {
  disabled?: boolean;
  label: string;
  max?: number;
  min?: number;
  numberPattern?: number;
  name: string;
  required?: boolean;
  infoTooltipContent?: ReactNode;
};

const validate = (required: boolean, max?: number, min?: number) => (value?: number) => {
  if (required && value == null) {
    return 'Champ obligatoire';
  }
  if (value != null && max != null && value > max) {
    return `Le nombre renseigné ne doit pas être plus grand que ${max}`;
  }
  if (value != null && min != null && value < min) {
    return `Le nombre renseigné ne doit pas être plus petit que ${min}`;
  }
  return null;
};

export const NumberFieldAdapter = ({
                                     disabled = false,
                                     label,
                                     max,
                                     min,
                                     name,
                                     required = false,
                                     numberPattern,
                                     infoTooltipContent,
                                   }: NumberFieldAdapterProps) => {
  const formMode = useFormMode();
  const { input, meta } = useField(name, {
    validate: validate(required, max, min),
  });
  const dataTestId = `${name}-field`;

  if (isDisabledOrReadFormMode(disabled, formMode)) {
    return (
      <ReadOnlyField
        label={label}
        value={input.value}
        required={required}
        infoTooltipContent={infoTooltipContent}
        dataTestId={dataTestId + '-readonly'}
      />
    );
  }

  const displayError = Boolean((meta.error || meta.submitError) && meta.touched);
  const touchField = () => {
    input.onBlur();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value) {
      const asNumber = Number(e.target.value);
      input.onChange(!isNaN(asNumber) ? asNumber : null);
    } else {
      input.onChange(null);
    }
  };

  return (
    <TextField
      variant='standard'
      inputProps={{ pattern: numberPattern ?? '[0-9]*', inputMode: 'numeric' }}
      fullWidth
      label={<FormFieldLabel label={label} required={required} infoTooltipContent={infoTooltipContent} />}
      InputLabelProps={{
        shrink: true,
      }}
      data-testid={dataTestId}
      value={input.value}
      onChange={handleChange}
      onBlur={touchField}
      error={displayError}
      helperText={displayError && (meta.error || meta.submitError)}
    />
  );
};
