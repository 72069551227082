import { Form } from 'react-final-form';
import { Box, Grid, Link, Stack } from '@mui/material';
import TextFieldAdapter from '../../form/components/TextFieldAdapter';
import ApiHelper from '../../api/apiHelper';
import { useCurrentUserRefresher } from '../CurrentUserContext';
import MainActionButton from '../../components/buttons/MainActionButton';
import { useEffect } from 'react';
import useSnackbar from '../../snackbar/useSnackbar';
import { Severity } from '../../utils/severityUtils';
import ApiError from '../../api/ApiError';
import { AuthenticationFailureEvent } from '../types';
import { useSearchParams } from 'react-router-dom';
import { useAppContext } from '../../AppContext';
import { SAML_LOGIN_INITIATOR_URL } from '../securityUtils';

export type LoginFormProps = {
  onCredentialsExpired: (login: string) => void;
};

type LoginFormValues = {
  login: string;
  password: string;
};

const USER_NOT_FOUND_CONTENT = 'Utilisateur ou mot de passe non trouvé';

export const LoginForm = ({ onCredentialsExpired }: LoginFormProps) => {
  const { login } = useAppContext();
  const [params] = useSearchParams();
  const currentUserRefresher = useCurrentUserRefresher();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (Boolean(params.get('samlError'))) {
      enqueueSnackbar({ content: USER_NOT_FOUND_CONTENT, severity: Severity.ERROR });
    }
  }, [params, enqueueSnackbar]);

  const onSubmit = async (form: LoginFormValues) => {
    try {
      await ApiHelper.postJson('/login', {
        login: form.login,
        password: form.password,
      });
      await currentUserRefresher();
    } catch (e) {
      if (e instanceof ApiError) {
        handleError(form.login, e);
      }
    }
  };

  const handleError = (login: string, error: ApiError) => {
    if (error.message === AuthenticationFailureEvent.ACCOUNT_BLOCKED) {
      enqueueSnackbar({ content: 'Compte bloqué, contactez un administrateur', severity: Severity.ERROR });
    } else if (error.message === AuthenticationFailureEvent.CREDENTIALS_EXPIRED) {
      onCredentialsExpired(login);
    } else {
      enqueueSnackbar({ content: USER_NOT_FOUND_CONTENT, severity: Severity.ERROR });
    }
  };

  return (
    <Stack direction="column" spacing={2}>
      <Form<LoginFormValues> onSubmit={onSubmit} initialValues={{ login: '', password: '' }}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldAdapter name="login" label="Utilisateur" required />
              </Grid>
              <Grid item xs={12}>
                <TextFieldAdapter name="password" label="Mot de passe" required password />
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                <Grid item>
                  <MainActionButton label="Se connecter" type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
      {login.saml && (
        <Box>
          Utilisateur {login.saml.samlUserType} ?{' '}
          <Link href={SAML_LOGIN_INITIATOR_URL}>Cliquez ici pour vous authentifier.</Link>
        </Box>
      )}
    </Stack>
  );
};
