import dayjs from 'dayjs';

/**
 * Store the last success activity date time into the locale storage to
 * take into account activity of other tabs.
 *
 */
export function refreshLastSuccessDate() {
  localStorage.setItem('lastSuccessDateTimeString', dayjs().format());
}

export function readLastSuccessDate() {
  return dayjs(localStorage.getItem('lastSuccessDateTimeString'));
}
