import React, { useState } from 'react';
import { Box, Card, CardContent, CardHeader, Container, Grid } from '@mui/material';
import { useAppContext } from './AppContext';
import { PasswordResetForm } from './security/password/components/PasswordResetForm';
import { LoginForm } from './security/components/LoginForm';

type UnauthenticatedView = { type: 'login-form' } | { type: 'reset-credentials'; login: string };

const UnauthenticatedUserPage = () => {
  const { appName } = useAppContext();
  const [view, setView] = useState<UnauthenticatedView>({ type: 'login-form' });

  return (
    <Container maxWidth={false}>
      <Grid
        maxWidth={'sm'}
        sx={{ m: 'auto', p: 4 }}
        container
        alignItems="center"
        justifyContent="center"
        rowSpacing={2}
      >
        <Grid item xs={12}>
          <Box
            sx={{
              backgroundImage: "url('/images/logo.png')",
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
            }}
            height="100px"
          ></Box>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <CardHeader title={appName} />
            <CardContent>
              {view.type === 'login-form' && (
                <LoginForm onCredentialsExpired={(login) => setView({ type: 'reset-credentials', login })} />
              )}
              {view.type === 'reset-credentials' && <PasswordResetForm login={view.login} />}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
};

export default UnauthenticatedUserPage;
