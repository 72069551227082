import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ApiHelper from '../../../api/apiHelper';
import { SnackbarRequest } from '../../../snackbar/snackbarUtils';
import { Severity } from '../../../utils/severityUtils';
import useSnackbar from '../../../snackbar/useSnackbar';
import { useFormSubmitHelper } from '../../../form/helper/useFormSubmitHelper';
import { SubmissionErrors } from 'final-form';
import { UserApiSaveResponse } from '../../usersTypes';
import { CurrentUserDetails } from '../../../security/types';
import { useAuthenticatedCurrentUser } from '../../../security/CurrentUserContext';
import { useNavigationHelper } from '../../../navigation/useNavigationHelper';
import { getUserEdition } from '../../../navigation/navigationUrlService';

type Parameters<UserT, FormValues> = {
  /**
   * Function computing the initial form values for a given user.
   */
  computeInitialValues: (user?: UserT) => FormValues;
  /**
   * Function transforming the valid form values into the HTTP request body.
   */
  toApiUserSaveCommand: (formValues: FormValues, currentUser: CurrentUserDetails) => any;
};

type HookReturnType<FormValues> = Readonly<{
  /**
   * The values to use to initialize the form.
   */
  initialValues: FormValues | undefined;
  /**
   * The plain text password that was generated server-side when creating the user.
   *
   * If defined, then the "password" dialog must be displayed.
   */
  plainTextPassword: string | null;
  /**
   * Send the HTTP request to save or update the user matching the given form values.
   *
   * @param formValues Valid and defined.
   */
  submitUserForm: (formValues: FormValues) => Promise<SubmissionErrors>;
  /**
   * Function to call to close the dialog that displays the plain text password.
   */
  closePasswordDialog: () => void;
}>;

/**
 * Hook in charge of managing the user's form:
 *   - It computes the initial values by loading if required the user from the server;
 *   - It sends the HTTP request to create or update the user;
 *   - It manages the state related to the potentially generated password;
 *   - It redirects to user's page after creation.
 *
 * It is meant to be generic to allow reuse among the different companies.
 */
function useUserFormHelper<UserT, FormValues extends { id: string | null }>({
  computeInitialValues,
  toApiUserSaveCommand,
}: Parameters<UserT, FormValues>): HookReturnType<FormValues> {
  /**
   * Compute the initial values.
   *
   * If there is a user identifier in the current page's path, then the matching user
   * is loading from the server to compute the form values.
   * Otherwise, the computed form values match a new user.
   */
  function useInitialValues(): FormValues | undefined {
    const [formValues, setFormValues] = useState<FormValues>();
    const { userId } = useParams();

    useEffect(() => {
      if (userId) {
        formValuesForUser(userId).then(setFormValues);
      } else {
        setFormValues(computeInitialValues());
      }
    }, [userId]);

    return formValues;
  }

  /**
   * Return the form values matching the given user.
   */
  async function formValuesForUser(userId: string): Promise<FormValues> {
    const user: UserT = await ApiHelper.get(`/api/users/${userId}`);
    return computeInitialValues(user);
  }

  /**
   * Manage form submission (HTTP request, password dialog display and redirection)
   */
  function useSubmitHelper() {
    const currentUser = useAuthenticatedCurrentUser();
    const { navigateToDynamicRoute } = useNavigationHelper();
    const delegateSubmit = useFormSubmitHelper();
    const { enqueueSnackbar } = useSnackbar();
    const [responseUserId, setResponseUserId] = useState<string>();
    const [plainTextPassword, setPlainTextPassword] = useState<string | null>(null);

    async function submitUserForm(formValues: FormValues) {
      return await delegateSubmit(async () => {
        const result: UserApiSaveResponse = await sendHttpRequest(formValues);
        enqueueSnackbar(successfulSaveSnackbar);
        setResponseUserId(result.userId);
        setPlainTextPassword(result.plainTextPassword);
        if (!result.plainTextPassword && isCreation(formValues)) {
          redirectToUserPage(result.userId);
        }
      });
    }

    /**
     * Send the HTTP request.
     */
    async function sendHttpRequest(formValues: FormValues): Promise<UserApiSaveResponse> {
      const payload = toApiUserSaveCommand(formValues, currentUser.userDetails);
      if (isCreation(formValues)) {
        return await ApiHelper.postJson('/api/users', payload);
      }
      await ApiHelper.putJson(`/api/users/${formValues.id}`, payload);
      return { userId: formValues.id!, plainTextPassword: null };
    }

    /**
     * Return a boolean indicating if this is a creation case.
     * {@code false} is returned for an update.
     */
    function isCreation(formValues: FormValues) {
      return !formValues.id;
    }

    /**
     * Close the password dialog and redirect to the user's page.
     */
    function closePasswordDialog() {
      setPlainTextPassword(null);
      redirectToUserPage(responseUserId!);
    }

    /**
     * Redirect to the user's page.
     */
    function redirectToUserPage(userId: string) {
      // We cannot directly use responseUserId stored in state.
      // if there is no password for the user, the function is called before
      // the state is actually updated.
      navigateToDynamicRoute(getUserEdition(), userId);
    }

    return {
      submitUserForm,
      plainTextPassword,
      closePasswordDialog,
    };
  }

  /**
   * The snackbar command to display when the user has been successfully saved.
   */
  const successfulSaveSnackbar: SnackbarRequest = {
    content: "L'utilisateur a été enregistré avec succès",
    severity: Severity.SUCCESS,
  };

  const { closePasswordDialog, submitUserForm, plainTextPassword } = useSubmitHelper();
  return {
    initialValues: useInitialValues(),
    closePasswordDialog,
    submitUserForm,
    plainTextPassword,
  };
}

export default useUserFormHelper;
