import React from 'react';
import { User } from '../../usersTypes';
import TableCell from '../../../components/table/TableCell';

type Props = {
  user: User;
};
const UserFirstNameTableCell = ({ user }: Props) => {
  return <TableCell>{user.firstName}</TableCell>;
};
export default UserFirstNameTableCell;
