import { Box, Button, CircularProgress } from '@mui/material';
import React from 'react';
import { ButtonProps } from './buttonTypes';

const SecondaryActionButton = ({
  action,
  className,
  colorVariant = 'NORMAL',
  dataTestId,
  disabled = false,
  href,
  label,
  type,
  startIcon,
  sx,
  pendingSpinner,
  fileInput,
}: ButtonProps) => {
  if (!pendingSpinner) {
    return (
      <Button
        className={className}
        color={colorVariant === 'NORMAL' ? 'primary' : 'error'}
        data-testid={dataTestId}
        disabled={disabled}
        href={href}
        variant="outlined"
        onClick={action}
        startIcon={startIcon}
        type={type !== 'upload' ? type : undefined}
        component={type === 'upload' ? 'label' : (undefined as any)}
        sx={sx}
      >
        {fileInput}
        {label}
      </Button>
    );
  } else {
    return (
      <Button sx={sx} variant="outlined" color={colorVariant === 'NORMAL' ? 'primary' : 'error'}>
        <Box sx={{ visibility: 'hidden' }}>{label}</Box>
        <CircularProgress sx={{ position: 'absolute' }} size="1.7em" color="inherit" />
      </Button>
    );
  }
};

export default SecondaryActionButton;
