import React from 'react';
import { Box, Grid } from '@mui/material';
import { usePasswordFormHelper } from '../hooks/usePasswordFormHelper';
import { PasswordUpdateFormValues } from '../passwordTypes';
import { Form } from 'react-final-form';
import TextFieldAdapter from '../../../form/components/TextFieldAdapter';
import MainActionButton from '../../../components/buttons/MainActionButton';
import UserPasswordRules from '../../../user/password/components/UserPasswordRules';
import { validatePassword, validatePasswordConfirmation } from '../passwordUtils';

type Props = {
  login: string;
  onSuccess?: () => void;
};

const PasswordValidator = (password?: string | null) => {
  const errors = validatePassword(password);
  if (errors.length === 0) {
    return null;
  }
  return errors.map((error) => (
    <React.Fragment key={error}>
      {error}
      <br />
    </React.Fragment>
  ));
};

export const PasswordResetForm = ({ login, onSuccess }: Props) => {
  const { initialValues, submitForm } = usePasswordFormHelper(login);

  const onSubmit = async (values: PasswordUpdateFormValues) => {
    const submissionErrors = await submitForm(values);
    if (!submissionErrors) {
      onSuccess?.();
    }
    return submissionErrors;
  };

  return (
    <Box>
      <Form<PasswordUpdateFormValues> initialValues={initialValues} onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextFieldAdapter name="plainTextCurrentPassword" label="Mot de passe actuel" required password />
              </Grid>
              <Grid item xs={12}>
                <TextFieldAdapter
                  name="plainTextNewPassword"
                  label="Nouveau mot de passe"
                  password
                  required
                  validators={[PasswordValidator]}
                />
              </Grid>
              <Grid item xs={12}>
                <TextFieldAdapter
                  name="plainTextNewPasswordConfirmation"
                  label="Confirmation du nouveau mot de passe"
                  password
                  required
                  validators={[validatePasswordConfirmation]}
                />
              </Grid>
              <Grid item xs={12}>
                <UserPasswordRules />
              </Grid>
              <Grid container item xs={12} justifyContent="flex-end">
                <Grid item>
                  <MainActionButton label="Mettre à jour le mot de passe" type="submit" />
                </Grid>
              </Grid>
            </Grid>
          </form>
        )}
      </Form>
    </Box>
  );
};
