import { Table, TableBody, TableHead, TableRow, Typography } from '@mui/material';
import TableCell from '../../../components/table/TableCell';
import { demandStatusLabels, verdictTypeLabels } from '../../demandUtils';
import { formatLocalDateForDisplay } from '../../../utils/dateUtils';
import dayjs from 'dayjs';
import { Demand } from '../../demandTypes';
import { getDemandDetail } from '../../../navigation/navigationUrlService';
import { useNavigationHelper } from '../../../navigation/useNavigationHelper';

type RequesterLinkedDemandsTableProps = {
  linkedDemands: readonly Demand[] | null;
  inError: boolean;
};

export const RequesterLinkedDemandsTable = ({ linkedDemands, inError }: RequesterLinkedDemandsTableProps) => {
  const { navigateToDynamicRoute } = useNavigationHelper();
  const openDemandPage = (demandId: string) => {
    navigateToDynamicRoute(getDemandDetail(), demandId);
  };

  return (
    <>
      {inError && (
        <Typography color="error.main">
          Une erreur est survenue pendant le chargement des demandes pour ce requérant.
        </Typography>
      )}
      {linkedDemands &&
        (linkedDemands.length > 0 ? (
          <Table size="small">
            <TableHead>
              <TableRow>
                <TableCell>N° Chrono</TableCell>
                <TableCell>Statut</TableCell>
                <TableCell>Date de réception</TableCell>
                <TableCell>Avis</TableCell>
                <TableCell>Date de validation de l'avis</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {linkedDemands.map((linkedDemand) => (
                <TableRow
                  key={linkedDemand.id}
                  onClick={() => openDemandPage(linkedDemand.id)}
                  sx={{ cursor: 'pointer' }}
                >
                  <TableCell>{linkedDemand.chronoNumber}</TableCell>
                  <TableCell>{demandStatusLabels[linkedDemand.status]}</TableCell>
                  <TableCell>
                    {linkedDemand.receptionDateTime
                      ? formatLocalDateForDisplay(dayjs(linkedDemand.receptionDateTime))
                      : ''}
                  </TableCell>
                  <TableCell>
                    {linkedDemand.verdict?.status ? verdictTypeLabels[linkedDemand.verdict.status] : ''}
                  </TableCell>
                  <TableCell>
                    {linkedDemand.verdict?.validationDateTime
                      ? formatLocalDateForDisplay(dayjs(linkedDemand.verdict.validationDateTime))
                      : ''}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        ) : (
          <Typography>Aucune demande n'a été trouvée pour ce requérant.</Typography>
        ))}
    </>
  );
};
