export type ValidationError = {
  code: string;
  fieldName?: string;
  message: string;
};

type Content = string | { message: string; errors?: Record<string, string> } | ValidationError[];

const getMessage = (content: Content): string => {
  if (typeof content === 'string') {
    return content;
  }
  if (Array.isArray(content)) {
    return 'Validation errors';
  }
  return content.message;
};

export default class ApiError extends Error {
  readonly httpStatus: number;
  readonly errors?: Record<string, string>;
  readonly validationErrors?: ValidationError[];

  constructor(httpStatus: number, content: Content) {
    super(getMessage(content));
    this.httpStatus = httpStatus;
    if (Array.isArray(content)) {
      this.validationErrors = content;
    } else if (typeof content === 'string') {
      this.message = content;
    } else {
      this.errors = content.errors;
    }
    Object.setPrototypeOf(this, ApiError.prototype);
  }
}
