import { useLocation, useNavigate } from 'react-router-dom';
import { useCallback, useMemo } from 'react';
import { queryStringToFilterValues, toApiSearchCommand } from '../societeMereUsersUtils';
import { SocieteMereUsersFilterValues, SocieteMereUsersSortableFields } from '../societeMereUsersTypes';
import { useDataLoader } from '@eogile/agrements-common/src/hooks/useDataLoader';
import { PaginatedListResponse } from '@eogile/agrements-common/src/api/apiTypes';
import { SocieteMereUser } from '../../societeMereUserTypes';
import { useSocieteMereAuthenticatedCurrentUser } from '../../../security/hooks';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { mergePaginationAndSortCommandWithParams } from '@eogile/agrements-common/src/utils/tableUtils';
import { toQueryString } from '@eogile/agrements-common/src/utils/queryStringUtils';
import ApiHelper from '@eogile/agrements-common/src/api/apiHelper';

/**
 * Hook that offers utilities for the users list:
 *   - Transform the query string to filter values;
 *   - Load users.
 */
export default function useUsersListHelper() {
  const location = useLocation();
  const filterValues = useMemo(() => queryStringToFilterValues(location.search), [location.search]);
  const { paginatedUsers, reloadUsers } = useUsersLoader(filterValues);
  const onPaginationOrSortChange = useSortAndPagination(filterValues);

  return {
    filterValues,
    onPaginationOrSortChange,
    paginatedUsers,
    reloadUsers,
  };
}

function useUsersLoader(filterValues: SocieteMereUsersFilterValues) {
  const currentUser = useSocieteMereAuthenticatedCurrentUser().userDetails;
  const apiParams = useMemo(() => toApiSearchCommand(currentUser, filterValues), [currentUser, filterValues]);
  const { data, setData } = useDataLoader<PaginatedListResponse<SocieteMereUser>>('/api/users', apiParams);
  const reloadUsers = useCallback(async () => {
    const response = await ApiHelper.get('/api/users', apiParams);
    setData(response);
  }, [setData, apiParams]);
  return {
    paginatedUsers: data,
    reloadUsers,
  };
}

/**
 * Function to call to update pagination and sort values in the query string.
 */
function useSortAndPagination(filterValues: SocieteMereUsersFilterValues) {
  const navigate = useNavigate();
  return (newCommand: PaginationAndSortCommand<SocieteMereUsersSortableFields>) => {
    const values = mergePaginationAndSortCommandWithParams(filterValues, newCommand);
    const newSearch = toQueryString(values);
    navigate({ search: newSearch }, { replace: false });
  };
}
