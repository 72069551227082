import MainActionButton from '../../../../components/buttons/MainActionButton';
import { useState } from 'react';
import { Demand } from '../../../demandTypes';
import { useAppContext } from '../../../../AppContext';
import { download } from '../../../../utils/downloadUtils';
import { printVerdictUrl } from '../demandActionUtils';

type VerdictInputButtonProps = {
  demand: Demand;
  refreshDemand: () => void;
};

export const VerdictInputButton = ({ demand, refreshDemand }: VerdictInputButtonProps) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const {
    demand: { VerdictInputDialog },
  } = useAppContext();

  const onSubmitSuccess = () => {
    download(printVerdictUrl(demand.id));
    refreshDemand();
  };

  return (
    <>
      <MainActionButton dataTestId="verdict-input-button" action={() => setDialogOpened(true)} label="Saisir l'avis" />
      <VerdictInputDialog
        demand={demand}
        open={dialogOpened}
        onClose={() => setDialogOpened(false)}
        onSubmitSuccess={onSubmitSuccess}
      />
    </>
  );
};
