import React from 'react';
import { styled, Typography } from '@mui/material';

const List = styled('ul')({
  marginTop: 0,
  paddingLeft: '20px',
});

const UserPasswordRules = () => (
  <div>
    <Typography mt={2} sx={{ textDecoration: 'underline' }}>
      Rappel des règles sur les mots de passe :
    </Typography>
    <List>
      <li>Une longueur minimale de 8 caractères ;</li>
      <li>Contenir des lettres en majuscule et en minuscule (a-z, A-Z) ;</li>
      <li>
        Contenir des chiffres et des caractères de ponctuation parmi « 0-9,!@#$%^&*()_+|~- =\`{}[]:\\";'&lt;&gt;?,./) »
        ;
      </li>
      <li>Changement des mots de passe obligatoire tous les 3 mois ;</li>
      <li>Les mots de passe doivent être réellement modifiés à chaque échéance.</li>
    </List>
  </div>
);

export default UserPasswordRules;
