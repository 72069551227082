import { SectionCard } from '../../components/cards/SectionCard';
import { FormGridItem } from '../../components/grid/FormGridItem';
import ReadOnlyField from '../../form/components/ReadOnlyField';
import { DefinedVerdict, Verdict, VerdictType } from '../demandTypes';
import {
  cancellationReasonLabels,
  isMinistryCancellationReason,
  rejectionReasonLabels,
  verdictTypeLabels,
} from '../demandUtils';
import dayjs from 'dayjs';
import { FormGridContainer } from '../../components/grid/FormGridContainer';
import { ReactNode } from 'react';

export type CancellationReasonVariant = 'SUPPORTS_CANCELLATION_REASON' | 'NO_CANCELLATION_REASON';
type Props = {
  verdict: DefinedVerdict;
  variant: CancellationReasonVariant;
  additionalContent?: ReactNode;
};

export const DemandMinistryVerdictSection = ({ variant, verdict, additionalContent }: Props) => {
  const formattedCancellationReason = formatCancellationReason(variant, verdict);

  return (
    <SectionCard title="Avis du ministère">
      <FormGridContainer>
        <FormGridItem>
          <ReadOnlyField label="Avis" value={verdictTypeLabels[verdict.status]} />
        </FormGridItem>
        {verdict.rejectionReason && (
          <FormGridItem>
            <ReadOnlyField label="Motif" value={rejectionReasonLabels[verdict.rejectionReason]} />
          </FormGridItem>
        )}
        {verdict.status === VerdictType.CANCELLED && formattedCancellationReason && (
          <FormGridItem data-testid="cancellationReason">
            <ReadOnlyField label="Motif d'annulation" value={formattedCancellationReason} />
          </FormGridItem>
        )}
        {verdict.dateTime && (
          <FormGridItem>
            <ReadOnlyField label="Avis donné le" value={dayjs(verdict.dateTime)} />
          </FormGridItem>
        )}
        {verdict.validationDateTime && (
          <FormGridItem>
            <ReadOnlyField label="Avis validé le" value={dayjs(verdict.validationDateTime)} />
          </FormGridItem>
        )}
      </FormGridContainer>
      {additionalContent}
    </SectionCard>
  );
};

const formatCancellationReason = (variant: CancellationReasonVariant, verdict: Verdict) => {
  let parts: string[] = [];
  if (variant === 'SUPPORTS_CANCELLATION_REASON') {
    if (verdict.cancellationReason) {
      // can only be present in FDJ case
      parts.push(
        `${isMinistryCancellationReason(verdict.cancellationReason) ? 'Ministère' : 'FDJ'} - ${
          cancellationReasonLabels[verdict.cancellationReason]
        }`,
      );
    } else {
      parts.push('Autre');
    }
  }
  if (verdict.cancellationComment) {
    parts.push(verdict.cancellationComment);
  }
  return parts.join(' - ');
};
