import { TableRow as MuiTableRow } from '@mui/material';
import { SimpleDemandView } from '../../demand/demandListTypes';
import { DemandStatus } from '../../demand/demandTypes';
import dayjs from 'dayjs';
import { useNavigationHelper } from '../../navigation/useNavigationHelper';
import { getDemandDetail } from '../../navigation/navigationUrlService';
import { orange, red } from '@mui/material/colors';
import { ReactNode } from 'react';

type TableRowProps = {
  demand: SimpleDemandView;
  children: ReactNode;
};

const receivedDemandStatuses: readonly DemandStatus[] = [
  DemandStatus.RECEIVED_BY_MINISTRY,
  DemandStatus.WAITING_MINISTRY_VERDICT,
  DemandStatus.CANCELLATION_ASKED,
];

export const DemandTableRow = ({ demand, children }: TableRowProps) => {
  const { navigateToDynamicRoute } = useNavigationHelper();

  const openDemandPage = (demandId: string) => {
    navigateToDynamicRoute(getDemandDetail(), demandId);
  };

  function computeColor() {
    if (receivedDemandStatuses.includes(demand.status)) {
      const receivedSince = dayjs().diff(dayjs(demand.receptionDate), 'day');
      if (receivedSince >= 45) {
        return red[100];
      } else if (receivedSince >= 35) {
        return orange[100];
      }
    }
    return undefined;
  }

  return (
    <MuiTableRow onClick={() => openDemandPage(demand.id)} sx={{ cursor: 'pointer', backgroundColor: computeColor() }}>
      {children}
    </MuiTableRow>
  );
};
