import { ButtonGroup, CardHeader, Fab, Stack, Tooltip } from '@mui/material';
import { useNavigationHelper } from '../../navigation/useNavigationHelper';
import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';
import { Permission } from '../../security/types';
import { getDemandCreate } from '../../navigation/navigationUrlService';
import SecondaryActionButton from '../../components/buttons/SecondaryActionButton';
import AddIcon from '@mui/icons-material/Add';
import React from 'react';
import { BaseDemandSearchCommand } from '../demandListTypes';
import { pathWithQueryString } from '../../api/apiHelper';
import { UserCompany } from '../../user/commonTypes';
import { noResultsTitle, numberOfDemandResults } from '../demandListUtils';

type DemandListTableHeaderProps<T extends BaseDemandSearchCommand> = {
  numberOfResults: number;
  apiParams: T;
};

export function DemandListTableHeader<T extends BaseDemandSearchCommand>({
  numberOfResults,
  apiParams,
}: DemandListTableHeaderProps<T>) {
  const { navigateToStaticRoute } = useNavigationHelper();
  const { userDetails } = useAuthenticatedCurrentUser();
  const displayCreationButton = userDetails.permissions.includes(Permission.CREATE_DEMAND);
  const apiParamsForExporting = { ...apiParams, pageSize: 0 };
  const canExportStats =
    userDetails.company !== UserCompany.LE_TROT && userDetails.company !== UserCompany.FRANCE_GALOP;

  const openCreationPage = () => {
    navigateToStaticRoute(getDemandCreate());
  };

  const actionComponent = (
    <Stack direction="row" spacing={2} alignItems="center">
      {numberOfResults > 0 && (
        <ButtonGroup>
          <SecondaryActionButton
            href={pathWithQueryString('/api/demands/export', apiParamsForExporting)}
            label="Exporter"
          />
          {canExportStats && (
            <SecondaryActionButton
              href={pathWithQueryString('/api/demands/stats', apiParamsForExporting)}
              label="Statistiques"
            />
          )}
        </ButtonGroup>
      )}
      {displayCreationButton && (
        <Tooltip title="Créer une nouvelle demande">
          <Fab color="primary" onClick={openCreationPage}>
            <AddIcon />
          </Fab>
        </Tooltip>
      )}
    </Stack>
  );

  return (
    <CardHeader
      action={actionComponent}
      title={numberOfResults > 0 ? numberOfDemandResults(numberOfResults) : undefined}
      subheader={numberOfResults === 0 ? noResultsTitle : undefined}
    />
  );
}
