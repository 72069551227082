import { Drawer, getDrawerUtilityClass } from '@mui/material';

import { sidebarWidth } from '../layoutUtils';
import Menu from './menu/Menu';
import Version from './version/Version';
import { useLayoutState } from '../LayoutContext';

const Sidebar = () => {
  const {
    state: { isSidebarOpenedOnSmallDevice },
    closeSidebar,
  } = useLayoutState();

  return (
    <Drawer
      anchor="left"
      variant="temporary"
      onClose={closeSidebar}
      open={isSidebarOpenedOnSmallDevice}
      sx={{
        width: sidebarWidth,
        flexShrink: 0,
        display: 'flex',
        flexDirection: 'column',
        [`& .${getDrawerUtilityClass('paper')}`]: {
          width: sidebarWidth,
        },
      }}
      ModalProps={{
        keepMounted: true, // Better open performance on mobile.
      }}
    >
      <Menu />
      <Version />
    </Drawer>
  );
};

export default Sidebar;
