import React from 'react';
import { EnumSelectAdapter } from '../../form/components/EnumSelectAdapter';
import { MinistryTerritorialArea } from '../../user/ministryUserTypes';
import { ministryTerritorialAreaLabels } from '../../user/ministryUserUtils';

type Props = {
  fieldName: string;
  required?: boolean;
};

const options = Object.values(MinistryTerritorialArea).sort();

const MinistryTerritorialAreaSelect = ({ fieldName, required = false }: Props) => {
  return (
    <EnumSelectAdapter
      label="Zone territoriale du ministère"
      name={fieldName}
      options={options}
      optionName={(area) => ministryTerritorialAreaLabels[area]}
      required={required}
    />
  );
};

export default MinistryTerritorialAreaSelect;
