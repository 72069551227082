import { Severity } from '../utils/severityUtils';
import { SnackbarMessage } from 'notistack';

export type SnackbarRequest = {
  readonly content: SnackbarMessage;
  readonly severity: Severity;
};

export const getDataLoadingErrorSnackbar = (): SnackbarRequest => ({
  content: "Une erreur s'est produite pendant le chargement des données",
  severity: Severity.ERROR,
});

export const getGenericFormValidationErrorSnackbar = (): SnackbarRequest => ({
  content: "Le formulaire n'est pas valide, veuillez vérifier",
  severity: Severity.ERROR,
});
export const getDemandValidationSuccessSnackbar = (): SnackbarRequest => ({
  content: 'La demande a été validée avec succès',
  severity: Severity.SUCCESS,
});

export const getDemandValidationErrorSnackbar = (): SnackbarRequest => ({
  content: "Une erreur s'est produite pendant la validation",
  severity: Severity.ERROR,
});

export const getAskCancellationSuccessSnackbar = (): SnackbarRequest => ({
  content: "L'annulation de demande a été enregistrée",
  severity: Severity.SUCCESS,
});

export const getAskCancellationErrorSnackbar = (): SnackbarRequest => ({
  content: "Une erreur s'est produite pendant la demande d'annulation",
  severity: Severity.ERROR,
});

export const getDemandDeletionSuccessSnackbar = (): SnackbarRequest => ({
  content: 'La demande a été supprimée avec succès',
  severity: Severity.SUCCESS,
});

export const getDemandDeletionErrorSnackbar = (): SnackbarRequest => ({
  content: "Une erreur s'est produite pendant la suppression",
  severity: Severity.ERROR,
});
