import { Card, CardHeader } from '@mui/material';
import { useAppContext } from '../../AppContext';
import { Demand } from '../demandTypes';

type Props = {
  demand: Demand | undefined;
};

export const DemandFormTitle = ({ demand }: Props) => {
  const {
    demand: { title },
  } = useAppContext();
  return (
    <Card>
      <CardHeader title={demand ? title(demand) : 'Nouvelle demande'} />
    </Card>
  );
};
