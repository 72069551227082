import { Attachment, Demand } from './demandTypes';
import {
  AttachmentCandidateInfo,
  AttachmentHelper,
  AttachmentItemRenderInfo,
  AttachmentPresence,
  AttachmentPresenceRule,
  AttachmentPresenceRules,
  AttachmentRenderData,
} from './attachmentTypes';
import { isDemandWithCandidates } from './demandUtils';
import { caseInsensitiveCompare } from '../utils/stringUtils';

/**
 * Returns a key-value records where keys are the attachment types the demand is allowed to have, and values are
 * objects containing additional info for this attachment type (linked to candidate, mandatory or not, multiple allowed, ...).
 *
 * This function does not consider the existing attachments but purely the rules from the Excel "combination" sheet,
 * the merge of previous attachments that no longer match the presence rules with the actual expected attachment types is
 * done in "buildAttachmentUpdateDataList".
 *
 * @param demand the demand
 * @param rules the rules helper object, provided by the app
 */
export function getAttachmentTypeInfosCompatibleWithDemand<D extends Demand, A extends string>(
  demand: D,
  rules: AttachmentPresenceRules<D, A>,
): Partial<Record<A, AttachmentPresence>> {
  const compatibleAttachmentTypes: Partial<Record<A, AttachmentPresence>> = {};
  Object.entries(rules)
    .map(([attachmentType, rule]) => {
      return { attachmentType: attachmentType as A, presence: (rule as AttachmentPresenceRule<D>)(demand) };
    })
    .filter(({ presence }) => presence !== 'INCOMPATIBLE')
    .forEach(({ attachmentType, presence }) => {
      compatibleAttachmentTypes[attachmentType] = presence;
    });
  return compatibleAttachmentTypes;
}

export function attachmentTypeDisplayOrderWeightsFromOrderedList<A extends string>(
  attachmentTypes: A[],
): Record<A, number> {
  const weights: Record<string, number> = {};
  attachmentTypes.forEach((type, index) => (weights[type] = index));
  return weights as Record<A, number>;
}

/**
 * Returns a list of data objects intended to be iterated in React components to display demand attachments:
 * - in the expected order
 * - with compatible attachment types and types that no longer match the rules, but were previously added to the demand
 * @param demand the demand
 * @param attachmentHelper the helper object, provided by the app
 */
export function buildAttachmentRenderDataList<D extends Demand, A extends string>(
  demand: D,
  attachmentHelper: AttachmentHelper<D, A>,
): AttachmentRenderData<A>[] {
  const attachmentTypesCompatibleWithDemand = getAttachmentTypeInfosCompatibleWithDemand(
    demand,
    attachmentHelper.attachmentPresenceRules,
  );
  const renderDataList: AttachmentRenderData<A>[] = [];

  /**
   * union of:
   * - the compatible attachment types
   * - the types of existing attachments
   */
  function getAllowedAttachmentTypes(): A[] {
    const result: Set<A> = new Set<A>();
    demand.attachments.map((a) => a.type as A).forEach((a) => result.add(a));
    (Object.keys(attachmentTypesCompatibleWithDemand) as A[]).forEach((a) => result.add(a));
    return [...result];
  }

  function findExistingAttachmentsForCandidateAndType(attachmentType: A, candidateIndex: number) {
    return demand.attachments.filter((a) => a.candidateIndex === candidateIndex && a.type === attachmentType);
  }

  function findExistingAttachmentsForType(attachmentType: A) {
    return demand.attachments.filter((a) => !Boolean(a.candidateIndex) && a.type === attachmentType);
  }

  function buildRenderData(
    attachmentType: A,
    initialAttachments: Attachment[],
    candidateInfo: AttachmentCandidateInfo | undefined,
  ): AttachmentRenderData<A> {
    return {
      demandId: demand.id,
      attachmentType,
      attachmentTypeLabel: attachmentHelper.attachmentTypeLabels[attachmentType],
      attachmentPresence: attachmentTypesCompatibleWithDemand[attachmentType]!,
      infoTooltip: attachmentHelper.attachmentTypeInfoTooltips[attachmentType],
      allowMultiple: attachmentHelper.attachmentTypesAllowMultiple.includes(attachmentType),
      attachments: initialAttachments.map((a) => ({ info: a, uploadedAt: undefined })),
      candidateInfo,
    };
  }

  getAllowedAttachmentTypes().forEach((attachmentType) => {
    const isCompatibleWithDemand = Boolean(attachmentTypesCompatibleWithDemand[attachmentType]);
    if (attachmentHelper.attachmentTypesForCandidate.includes(attachmentType)) {
      const demandCandidates = isDemandWithCandidates(demand) ? demand.candidates : [];
      demandCandidates.forEach((candidate, i) => {
        const candidateIndex = i + 1;
        const existingAttachments = findExistingAttachmentsForCandidateAndType(attachmentType, candidateIndex);
        if (existingAttachments.length > 0 || isCompatibleWithDemand) {
          renderDataList.push(
            buildRenderData(attachmentType, existingAttachments, { candidate, index: candidateIndex }),
          );
        }
      });
    } else {
      const existingAttachments = findExistingAttachmentsForType(attachmentType);
      if (existingAttachments.length > 0 || isCompatibleWithDemand) {
        renderDataList.push(buildRenderData(attachmentType, existingAttachments, undefined));
      }
    }
  });

  return sortedAttachmentRenderDataList(renderDataList, attachmentHelper.attachmentTypeDisplayOrderWeights);
}

function sortedAttachmentRenderDataList<A extends string>(
  renderDataList: AttachmentRenderData<A>[],
  attachmentTypeDisplayOrderWeights: Record<A, number>,
): AttachmentRenderData<A>[] {
  const compareCandidateIndex = (d1: AttachmentRenderData<A>, d2: AttachmentRenderData<A>) => {
    const d1Index = d1.candidateInfo?.index ?? Number.MAX_SAFE_INTEGER;
    const d2Index = d2.candidateInfo?.index ?? Number.MAX_SAFE_INTEGER;
    return d1Index - d2Index;
  };
  const compareAttachmentOrder = (d1: AttachmentRenderData<A>, d2: AttachmentRenderData<A>) => {
    return attachmentTypeDisplayOrderWeights[d1.attachmentType] - attachmentTypeDisplayOrderWeights[d2.attachmentType];
  };
  const compareUploadedAt = (d1: AttachmentItemRenderInfo, d2: AttachmentItemRenderInfo) => {
    const d1UploadedAt = d1.uploadedAt ?? 0;
    const d2UploadedAt = d2.uploadedAt ?? 0;
    return d1UploadedAt - d2UploadedAt;
  };
  const compareFilename = (d1: AttachmentItemRenderInfo, d2: AttachmentItemRenderInfo) => {
    return caseInsensitiveCompare(d1.info.filename, d2.info.filename);
  };

  const orderedRenderData = [...renderDataList];

  // first, sort by criteria that do not depend on the actual attachments metadata
  orderedRenderData.sort((d1, d2) => {
    return [compareCandidateIndex, compareAttachmentOrder].reduce(
      (compared, comparator) => (compared === 0 ? comparator(d1, d2) : compared),
      0,
    );
  });

  // then, for each "render data", reorder their attachments
  return orderedRenderData.map((renderData) => {
    const orderedAttachments = [...renderData.attachments];
    orderedAttachments.sort((d1, d2) => {
      return [compareUploadedAt, compareFilename].reduce(
        (compared, comparator) => (compared === 0 ? comparator(d1, d2) : compared),
        0,
      );
    });
    return { ...renderData, attachments: orderedAttachments };
  });
}

export const attachmentKey = (attachmentRenderData: AttachmentRenderData<any>) => {
  return (
    attachmentRenderData.attachmentType +
    (attachmentRenderData.candidateInfo ? `-${attachmentRenderData.candidateInfo!.index}` : '')
  );
};

export const attachmentDownloadLink = (demandId: string, attachmentId: string) =>
  `/api/demands/${demandId}/attachments/${attachmentId}`;

export const MAX_UPLOAD_FILE_SIZE = 50 * 1024 * 1024; // 50MB
