import { convertWithDefaultNumber, parseQueryString } from '../utils/queryStringUtils';
import dayjs from 'dayjs';
import {
  BaseDemandSearchCommand,
  DEFAULT_DEMANDS_SORT_FIELD,
  DEFAULT_DEMANDS_SORT_ORDER,
  DemandsFiltersType,
} from './demandListTypes';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, PaginationAndSortCommand, SortOrder } from '../components/table/tableTypes';
import { Except } from 'type-fest';
import { DemandStatus } from './demandTypes';
import { formatZonedDateTime } from '../utils/dateUtils';
import { CurrentUserDetails } from '../security/types';
import { isMinistryUser } from '../user/userUtils';
import { MinistryTerritorialArea, MinistryUserProfile } from '../user/ministryUserTypes';
import { formatLocalDateUpperBoundAsZonedDateTime } from '../form/helper/formUtils';

export function parseCommonDemandsListQueryString(
  search: string,
): DemandsFiltersType & Except<PaginationAndSortCommand<any>, 'sortField'> {
  const params = parseQueryString(search);
  return {
    status: (params.status as DemandStatus) ?? null,
    chronoNumber: (params.chronoNumber as string) ?? null,
    receptionDateLowerBound: params.receptionDateLowerBound ? dayjs(params.receptionDateLowerBound) : null,
    receptionDateUpperBound: params.receptionDateUpperBound ? dayjs(params.receptionDateUpperBound) : null,
    validationDateLowerBound: params.validationDateLowerBound ? dayjs(params.validationDateLowerBound) : null,
    validationDateUpperBound: params.validationDateUpperBound ? dayjs(params.validationDateUpperBound) : null,
    ministryReference: (params.ministryReference as string) ?? null,
    fullText: (params.fullText as string) ?? null,
    departmentNumber: (params.departmentNumber as string) ?? null,
    territorialArea: (params.territorialArea as MinistryTerritorialArea) ?? null,
    verdictValidationDateLowerBound: params.verdictValidationDateLowerBound
      ? dayjs(params.verdictValidationDateLowerBound)
      : null,
    verdictValidationDateUpperBound: params.verdictValidationDateUpperBound
      ? dayjs(params.verdictValidationDateUpperBound)
      : null,
    sortOrder: (params.sortOrder as SortOrder) ?? DEFAULT_DEMANDS_SORT_ORDER,
    page: convertWithDefaultNumber(params.page, DEFAULT_PAGE),
    pageSize: convertWithDefaultNumber(params.pageSize, DEFAULT_PAGE_SIZE),
  };
}

export function toCommonDemandsListApiParams(
  params: DemandsFiltersType & PaginationAndSortCommand<any>,
): BaseDemandSearchCommand {
  return {
    chronoNumber: params.chronoNumber,
    receptionDateLowerBound: formatZonedDateTime(params.receptionDateLowerBound),
    receptionDateUpperBound: formatLocalDateUpperBoundAsZonedDateTime(params.receptionDateUpperBound),
    validationDateLowerBound: formatZonedDateTime(params.validationDateLowerBound),
    validationDateUpperBound: formatLocalDateUpperBoundAsZonedDateTime(params.validationDateUpperBound),
    ministryReference: params.ministryReference,
    fullText: params.fullText,
    departmentNumber: params.departmentNumber,
    territorialArea: params.territorialArea,
    verdictValidationDateLowerBound: formatZonedDateTime(params.verdictValidationDateLowerBound),
    verdictValidationDateUpperBound: formatLocalDateUpperBoundAsZonedDateTime(params.verdictValidationDateUpperBound),
    sortField: params.sortField ?? DEFAULT_DEMANDS_SORT_FIELD,
    sortOrder: params.sortOrder ?? DEFAULT_DEMANDS_SORT_ORDER,
    page: params.page > 0 ? params.page : DEFAULT_PAGE,
    pageSize: params.pageSize > 0 ? params.pageSize : DEFAULT_PAGE_SIZE,
  };
}

export const initialStatusFilterValueIfLocalMinistryUser = (
  userDetails: CurrentUserDetails,
): DemandStatus | undefined => {
  if (isMinistryUser(userDetails)) {
    return userDetails.profiles.includes(MinistryUserProfile.MINISTRY_LOCAL)
      ? DemandStatus.RECEIVED_BY_MINISTRY
      : undefined;
  }
  return undefined;
};
