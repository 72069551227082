import { useField, useForm } from 'react-final-form';
import { useVerdictTypeFormValue } from './useVerdictTypeFormValue';
import { useEffect } from 'react';
import { RejectionReason, VerdictType } from '../../../demandTypes';

type RejectedVerdictFormHelperHook = {
  activeRejectionReasons: readonly RejectionReason[];
};

const rejectionReasonFieldName = 'rejectionReason';

export const useStandardRejectedVerdictFormHelper = (
  activeRejectionReasons: readonly RejectionReason[],
): RejectedVerdictFormHelperHook => {
  const { change } = useForm();
  const currentVerdictType = useVerdictTypeFormValue().value;
  const rejectionReasonField = useField(rejectionReasonFieldName, { subscription: { value: true } });
  const currentRejectionReason = rejectionReasonField.input.value;

  useEffect(() => {
    if (currentVerdictType !== VerdictType.REJECTED) {
      change(rejectionReasonFieldName, undefined);
    }
  }, [change, currentVerdictType]);

  useEffect(() => {
    if (currentRejectionReason && !activeRejectionReasons.includes(currentRejectionReason)) {
      change(rejectionReasonFieldName, undefined);
    }
  }, [change, currentRejectionReason, activeRejectionReasons]);

  return {
    activeRejectionReasons,
  };
};
