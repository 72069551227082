import React, { useEffect } from 'react';
import MinistryTerritorialAreaSelect from '../../../../components/ministry-territorial-area/ministryTerritorialAreaSelect';
import { useField } from 'react-final-form';
import { MinistryUserProfile } from '../../../ministryUserTypes';

/**
 * Field representing the territorial area of a ministry user.
 *
 * The field is only displayed if "ministry local" profile is selected.
 */
const UserMinistryTerritorialAreaField = () => {
  const {
    input: { value: profileCodes = [] },
  } = useField<MinistryUserProfile[]>('profiles');
  const { input } = useField('territorialArea', { subscription: { value: true } });
  const shouldDisplayField = profileCodes.includes(MinistryUserProfile.MINISTRY_LOCAL);

  useEffect(() => {
    if (!shouldDisplayField) {
      input.onChange(null);
    }
  }, [shouldDisplayField, input]);

  if (shouldDisplayField) {
    return <MinistryTerritorialAreaSelect fieldName="territorialArea" required />;
  }
  return null;
};

export default UserMinistryTerritorialAreaField;
