import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { SessionWarningStatus } from './sessionWarningTypes';
import SecondaryActionButton from '../components/buttons/SecondaryActionButton';
import MainActionButton from '../components/buttons/MainActionButton';
import { readLastSuccessDate } from './sessionHelper';

export const SessionWarningDialog = ({
  status,
  touchSessionAction,
  endOfSessionLimitInSecond,
}: {
  status: SessionWarningStatus;
  touchSessionAction: () => void;
  endOfSessionLimitInSecond: number;
}) => {
  const expirationDate = readLastSuccessDate().add(endOfSessionLimitInSecond, 'second').format('HH:mm');

  const getWarningText = () => {
    switch (status) {
      case SessionWarningStatus.WARNING:
        return `Votre session va expirer à ${expirationDate} pour cause d'inactivité.`;
      case SessionWarningStatus.END_OF_SESSION:
        return `Votre session est expirée depuis ${expirationDate}.`;
      case SessionWarningStatus.NOT_DISPLAYED:
        return '';
    }
  };

  return (
    <Dialog data-testid="session-warning-dialog" open={status !== SessionWarningStatus.NOT_DISPLAYED}>
      <DialogTitle>Expiration de votre session</DialogTitle>
      <DialogContent>{getWarningText()}</DialogContent>
      <DialogActions>
        {status === SessionWarningStatus.WARNING && (
          <SecondaryActionButton dataTestId="touch-session-button" label="Prolonger" action={touchSessionAction} />
        )}
        {status === SessionWarningStatus.END_OF_SESSION && (
          <MainActionButton dataTestId="close-button" label="Fermer" action={() => window.location.reload()} />
        )}
      </DialogActions>
    </Dialog>
  );
};
