import { CurrentUserDetails, Permission } from './types';
import { UserCompany } from '../user/commonTypes';

/**
 * Return a boolean indicating whether or not the given user has at least one of
 * the given permissions.
 *
 * If the permission array is empty, false is returned.
 *
 * @param user The current user.
 * @param permissions The required permissions.
 */
export const hasAnyPermission = (user: CurrentUserDetails, permissions: Permission[]): boolean => {
  return permissions.some((permission) => hasPermission(user, permission));
};

/**
 * Return a boolean indicating whether or not the given user has the given permission.
 *
 * @param user The current user.
 * @param permission The required permission.
 */
export const hasPermission = (user: CurrentUserDetails, permission: Permission): boolean =>
  user.permissions.includes(permission);

/**
 * Return a boolean indicating whether or not the company of the given user is present
 * in the given array of user companies.
 *
 * If the array of user companies is empty, false is returned.
 *
 * @param user The current user.
 * @param userTypes The required user companies.
 */
export const hasAnyUserCompany = (user: CurrentUserDetails, userTypes: UserCompany[]): boolean => {
  return userTypes.includes(user.company);
};

export const SAML_LOGIN_INITIATOR_URL = `/saml2/authenticate/agrements`;
