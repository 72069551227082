import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { withFieldNamePrefix } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { DatePickerAdapter } from '@eogile/agrements-common/src/form/components/DatePickerAdapter';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { postJanuaryFirst1900AndNotAfterTodayDatePickerProps } from '@eogile/agrements-common/src/form/helper/formUtils';
import { FormMode, useFormMode } from '@eogile/agrements-common/src/form/helper/FormModeProvider';
import { RequesterLinkedDemands } from '@eogile/agrements-common/src/demand/components/candidate/RequesterLinkedDemands';
import { SocieteMereDemand } from '../societeMereDemandTypes';

type SocieteMereDemandRequesterPartnerSectionProps = {
  demand: SocieteMereDemand | undefined;
};

export const SocieteMereDemandRequesterPartnerSection = ({ demand }: SocieteMereDemandRequesterPartnerSectionProps) => {
  const mode = useFormMode();
  const fieldName = withFieldNamePrefix('requesterPartner');

  return (
    <SectionCard title="Conjoint(e)">
      <FormGridContainer>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('lastName')} label="Nom / Dénomination" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('firstName')} label="Prénom" cssCapitalize />
        </FormGridItem>
        <FormGridItem>
          <DatePickerAdapter
            name={fieldName('birthDate')}
            label="Date de naissance"
            {...postJanuaryFirst1900AndNotAfterTodayDatePickerProps}
          />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('birthPlace')} label="Lieu de naissance" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('nationality')} label="Nationalité" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('jobName')} label="Profession" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('companyNameAndAddress')} label="Nom et adresse de l’entreprise" />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('companyActivity')} label="Nature de l’activité de l’entreprise" />
        </FormGridItem>
      </FormGridContainer>
      {mode === FormMode.READ && (
        <RequesterLinkedDemands
          demandId={demand!.id}
          requesterIndex={1}
          requesterSearchCriteria={{
            firstName: demand!.requesterPartner.firstName,
            lastName: demand!.requesterPartner.lastName,
            birthDate: demand!.requesterPartner.birthDate,
          }}
        />
      )}
    </SectionCard>
  );
};
