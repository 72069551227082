import { useState } from 'react';
import MainActionButton from '../../../components/buttons/MainActionButton';
import { UpdateAttachmentsDialog } from '../attachment/UpdateAttachmentsDialog';
import { Demand } from '../../demandTypes';
import { useAppContext } from '../../../AppContext';
import { demandIsValidatedAndNotInFinalState } from '../../demandUtils';

type Props = {
  demand: Demand;
  refreshDemand: () => Promise<void>;
};

export const UpdateAttachmentsActionButton = ({ demand, refreshDemand }: Props) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const {
    demand: { attachmentCanBeAddedAfterValidation },
  } = useAppContext();

  const handleClose = async () => {
    setDialogOpened(false);
    await refreshDemand();
  };

  const afterValidationDisplay = attachmentCanBeAddedAfterValidation && demandIsValidatedAndNotInFinalState(demand);

  return (
    <>
      <MainActionButton
        dataTestId={afterValidationDisplay ? 'update-attachments-after-validation-button' : 'update-attachments-button'}
        label="Modifier les pièces justificatives"
        action={() => setDialogOpened(true)}
      />
      <UpdateAttachmentsDialog
        demand={demand}
        open={dialogOpened}
        onClose={handleClose}
        afterValidationDisplay={afterValidationDisplay}
      />
    </>
  );
};
