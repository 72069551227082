import { ComponentType, createContext, ReactNode, useContext } from 'react';
import { UserCompany } from './user/commonTypes';
import { CancellationReason, Demand } from './demand/demandTypes';
import { DemandFormProps } from './demand/components/demandComponentTypes';
import { CurrentUserDetails } from './security/types';
import { CanDoDemandActionPostFilter } from './demand/components/actions/demandActionTypes';
import { AttachmentHelper } from './demand/attachmentTypes';
import { VerdictInputDialogProps } from './demand/components/actions/verdictinput/verdictInputComponentTypes';
import { DirectionLabelUsage } from './components/direction/ImportAgencyTypes';

export type AppContextType = {
  appName: string;
  login: {
    saml: false | { samlUserType: UserCompany.FDJ | UserCompany.PMU };
  };
  profileDisplayLabel: (profile: string) => string;
  demand: {
    title: (demand: Demand) => string;
    DemandForm: ComponentType<DemandFormProps<any>>;
    DemandsList: ComponentType;
    canDoActionPostFilter?: CanDoDemandActionPostFilter;
    availableCancellationReasons: CancellationReason[];
    attachmentHelper: AttachmentHelper<any, any>;
    attachmentCanBeAddedAfterValidation: boolean;
    VerdictInputDialog: ComponentType<VerdictInputDialogProps<any>>;
  };
  menu: {
    demandListQueryParameters: (userDetails: CurrentUserDetails) => Record<string, string>;
  };
  directionAdmin:
    | false
    | {
        buildDirectionLabel: (usage: DirectionLabelUsage) => string;
        AppDirectionAdminPage: ComponentType;
      };
  reassignDemands?: { cardTitle: string };
  users: {
    UsersList: ComponentType;
    UserForm: ComponentType;
  };
  sessionWatcher:
    | false
    | {
        warningLimitInSecond: number;
        endOfSessionLimitInSecond: number;
      };
  appSpecificRoutes?: ReactNode;
};

const Ctx = createContext<AppContextType | null>(null);

export const AppContextProvider = ({ ctx, children }: { ctx: AppContextType; children: ReactNode }) => {
  return <Ctx.Provider value={ctx}>{children}</Ctx.Provider>;
};

export const useAppContext = () => {
  return useContext(Ctx)!;
};
