import React, { ReactNode } from 'react';
import { Checkbox, FormControlLabel, FormGroup, getFormGroupUtilityClass } from '@mui/material';
import { useField } from 'react-final-form';
import { useFormMode } from '../helper/FormModeProvider';
import { isDisabledOrReadFormMode } from '../helper/formUtils';
import { FormFieldLabel } from './FormFieldLabel';

type CheckboxAdapterProps = {
  label: string;
  name: string;
  disabled?: boolean;
  infoTooltipContent?: ReactNode;
};
const CheckboxAdapter = ({ label, name, disabled = false, infoTooltipContent }: CheckboxAdapterProps) => {
  const field = useField(name);
  const formMode = useFormMode();
  const currentValue = field.input.value;

  const onCheckBoxChange = () => {
    field.input.onChange(!Boolean(currentValue));
  };
  return (
    <FormGroup
      sx={{
        [`&.${getFormGroupUtilityClass('row')}`]: { height: 48 },
      }}
      row
    >
      <FormControlLabel
        labelPlacement="start"
        sx={{ ml: 0 }}
        control={
          <Checkbox
            checked={Boolean(currentValue)}
            disabled={isDisabledOrReadFormMode(disabled, formMode)}
            data-testid={`${name}-field`}
            onChange={onCheckBoxChange}
          />
        }
        label={<FormFieldLabel label={label} required={false} infoTooltipContent={infoTooltipContent} />}
      />
    </FormGroup>
  );
};

export default CheckboxAdapter;
