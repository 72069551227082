import React, { ReactElement, ReactNode } from 'react';

import { Box, FormControl, InputLabel, Typography } from '@mui/material';
import { Dayjs, isDayjs } from 'dayjs';
import { formatLocalDateForDisplay } from '../../utils/dateUtils';
import { FormFieldLabel } from './FormFieldLabel';

type ReadOnlyFieldProps = {
  label?: string;
  value?: ReadOnlyFieldValue;
  valueElement?: ReactElement;
  required?: boolean;
  dataTestId?: string;
  infoTooltipContent?: ReactNode;
};

export type ReadOnlyFieldValue = string | string[] | number | Dayjs | null | undefined;

const formatValue = (value: ReadOnlyFieldValue): ReactElement | null => {
  if (isDayjs(value)) {
    return <>{formatLocalDateForDisplay(value)}</>;
  }
  if (Array.isArray(value)) {
    return (
      <>
        {value.map((line) => (
          <span key={line}>
            {line}
            <br />
          </span>
        ))}
      </>
    );
  }
  if (typeof value === 'string' || typeof value === 'number') {
    return <>{value}</>;
  }
  return null;
};

const ReadOnlyField = ({
  label,
  value,
  valueElement,
  required = false,
  dataTestId,
  infoTooltipContent,
}: ReadOnlyFieldProps) => {
  const hasLabel = Boolean(label);
  return (
    <FormControl
      variant="standard"
      fullWidth
      data-testid={dataTestId || `readOnlyField`}
      sx={hasLabel ? { marginBottom: 1 } : undefined}
    >
      {label && (
        <InputLabel data-testid="label" shrink>
          <FormFieldLabel label={label} required={required} infoTooltipContent={infoTooltipContent} />
        </InputLabel>
      )}
      {valueElement && (
        <Box data-testid="valueElement" sx={hasLabel ? { marginTop: 2 } : undefined}>
          {valueElement}
        </Box>
      )}
      {!valueElement && (
        <Typography
          sx={{
            whiteSpace: 'pre-line',
            overflowWrap: 'break-word',
            ...(hasLabel && { marginTop: 2 }),
          }}
        >
          <span data-testid="value">{formatValue(value)}</span>
        </Typography>
      )}
    </FormControl>
  );
};

export default ReadOnlyField;
