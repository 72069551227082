import { useState } from 'react';
import { Demand } from '../../../demandTypes';
import MainActionButton from '../../../../components/buttons/MainActionButton';
import { Dialog, DialogActions, DialogContent, Grid } from '@mui/material';
import SecondaryActionButton from '../../../../components/buttons/SecondaryActionButton';
import { Form } from 'react-final-form';
import { FormMode, FormModeProvider } from '../../../../form/helper/FormModeProvider';
import { ValidateVerdictFormValues } from './validateVerdictFormTypes';
import { buildValidateVerdictFormData } from './validateVerdictFormUtils';
import ApiHelper from '../../../../api/apiHelper';
import { captureException } from '@sentry/react';
import useSnackbar from '../../../../snackbar/useSnackbar';
import { Severity } from '../../../../utils/severityUtils';
import { SignedVerdictFileUploadFieldAdapter } from '../SignedVerdictFileUploadFieldAdapter';
import { ValidateVerdictDialogTitle } from './ValidateVerdictDialogTitle';

type ValidateVerdictProps = {
  demand: Demand;
  refreshDemand: () => void;
};

export const ValidateVerdictButton = ({ demand, refreshDemand }: ValidateVerdictProps) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const [pending, setPending] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const closeDialog = () => {
    if (!pending) {
      setDialogOpened(false);
    }
  };

  const onSubmit = async (formValues: ValidateVerdictFormValues) => {
    const formData = buildValidateVerdictFormData(formValues);
    try {
      setPending(true);
      await ApiHelper.post(`/api/demands/${demand.id}/verdict/validate`, formData);
      setDialogOpened(false);
      enqueueSnackbar({
        content: "L'avis a été validé avec succès",
        severity: Severity.SUCCESS,
      });
      refreshDemand();
    } catch (e) {
      captureException(e);
      enqueueSnackbar({
        content: "Une erreur est survenue pendant la validation de l'avis",
        severity: Severity.ERROR,
      });
    } finally {
      setPending(false);
    }
  };

  return (
    <>
      <MainActionButton
        dataTestId="validate-verdict-button"
        label="Valider l'avis"
        action={() => setDialogOpened(true)}
      />
      <Dialog data-testid="validate-verdict-dialog" open={dialogOpened} onClose={closeDialog} fullWidth>
        <FormModeProvider mode={FormMode.EDIT}>
          <Form onSubmit={onSubmit}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <ValidateVerdictDialogTitle demand={demand} />
                <DialogContent>
                  <Grid container>
                    <Grid item xs={12}>
                      <SignedVerdictFileUploadFieldAdapter disabled={pending} />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <SecondaryActionButton label="Annuler" action={closeDialog} disabled={pending} />
                  <MainActionButton
                    dataTestId="confirm-validation"
                    label="Confirmer"
                    type="submit"
                    disabled={pending}
                    pendingSpinner={pending}
                  />
                </DialogActions>
              </form>
            )}
          </Form>
        </FormModeProvider>
      </Dialog>
    </>
  );
};
