export enum Severity {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  WARNING = 'WARNING',
  ERROR = 'ERROR',
}

export const getMuiSeverity = (severity: Severity) => {
  switch (severity) {
    case Severity.ERROR:
      return 'error';
    case Severity.WARNING:
      return 'warning';
    case Severity.INFO:
      return 'info';
    case Severity.SUCCESS:
      return 'success';
    default:
      throw new Error(`Unknown severity: ${severity}`);
  }
};
