import React from 'react';
import { MinistryUser } from '../../../../usersTypes';
import UserLoginTableCell from '../../../components/UserLoginTableCell';
import UserLastNameTableCell from '../../../components/UserLastNameTableCell';
import UserFirstNameTableCell from '../../../components/UserFirstNameTableCell';
import UserEmailTableCell from '../../../components/UserEmailTableCell';
import UserProfilesTableCell from '../../../components/UserProfilesTableCell';
import UserUpdatedAtTableCell from '../../../components/UserUpdatedAtTableCell';
import { ministryProfileDisplayLabels } from '../../../../userUtils';
import { ministryTerritorialAreaLabels } from '../../../../ministryUserUtils';
import TableCell from '../../../../../components/table/TableCell';
import UserTableRow from '../../../components/UserTableRow';
import DeleteUserAction from '../../../components/DeleteUserAction';
import UserStatusCell from '../../../components/UserStatusCell';
import UnblockUserAction from '../../../components/UnblockUserAction';
import GeneratePasswordUserAction from '../../../components/GeneratePasswordUserAction';

type Props = {
  user: MinistryUser;
  reloadUsers: () => Promise<void>;
};

function territorialAreaLabel(user: MinistryUser) {
  if (user.territorialArea) {
    return ministryTerritorialAreaLabels[user.territorialArea];
  }
  return null;
}

const MinistryUsersTableRow = ({ user, reloadUsers }: Props) => {
  return (
    <UserTableRow userId={user.id}>
      <UserLoginTableCell user={user} />
      <UserLastNameTableCell user={user} />
      <UserFirstNameTableCell user={user} />
      <UserEmailTableCell user={user} />
      <TableCell>{territorialAreaLabel(user)}</TableCell>
      <UserProfilesTableCell
        profileCodes={user.profileCodes}
        getProfileName={(profileCode) => ministryProfileDisplayLabels[profileCode]}
      />
      <UserStatusCell user={user} />
      <UserUpdatedAtTableCell user={user} />
      <TableCell>
        <DeleteUserAction user={user} reloadUsers={reloadUsers} />
        <GeneratePasswordUserAction user={user} />
        <UnblockUserAction user={user} reloadUsers={reloadUsers} />
      </TableCell>
    </UserTableRow>
  );
};

export default MinistryUsersTableRow;
