import { Civility } from '@eogile/agrements-common/src/common-types/civilityTypes';
import { SocieteMereAcquirerType, SocieteMereDemandRequester } from '../demand/societeMereDemandTypes';

export const societeMereCivilityLabels: Record<Civility, string> = {
  [Civility.MR]: 'Monsieur',
  [Civility.MS]: 'Madame',
};

export const getRequesterLabel = (requester: SocieteMereDemandRequester) => {
  if (requester.acquirerType === SocieteMereAcquirerType.COMPANY && requester.denomination) {
    return requester.denomination;
  }
  return [requester.firstName, requester.lastName].filter(Boolean).join(' ');
};
