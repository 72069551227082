import { RejectionReason } from '../../../demandTypes';
import { EnumSelectAdapter } from '../../../../form/components/EnumSelectAdapter';
import { rejectionReasonLabels } from '../../../demandUtils';

export const RejectionReasonSelectAdapter = ({
  availableRejectionReasons,
}: {
  availableRejectionReasons: readonly RejectionReason[];
}) => (
  <EnumSelectAdapter<RejectionReason>
    label="Motif"
    name="rejectionReason"
    required
    options={availableRejectionReasons as RejectionReason[]}
    optionName={(v) => rejectionReasonLabels[v]}
  />
);
