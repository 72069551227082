import { SectionCard } from '../../components/cards/SectionCard';
import { Attachment, Demand } from '../demandTypes';
import { useMemo } from 'react';
import { buildAttachmentRenderDataList } from '../attachmentUtils';
import { useAppContext } from '../../AppContext';
import { Table, TableBody, TableHead, TableRow } from '@mui/material';
import { AttachmentName } from './attachment/AttachmentName';
import TableCell from '../../components/table/TableCell';
import { formatDateTimeForFrenchDisplay } from '../../utils/dateUtils';

type Props = {
  demand: Demand;
};

export const DemandAttachmentsSection = ({ demand }: Props) => {
  const {
    demand: { attachmentHelper, attachmentCanBeAddedAfterValidation },
  } = useAppContext();

  const existingAttachments = useMemo(() => {
    return buildAttachmentRenderDataList(demand, attachmentHelper)
      .filter((rd) => rd.attachments.length > 0)
      .flatMap((rd) => rd.attachments.map((a) => ({ attachment: a.info, attachmentRenderData: rd })));
  }, [demand, attachmentHelper]);

  const buildUploadTimestamp = (attachment: Attachment) =>
    `Ajouté${formatDateTimeForFrenchDisplay(attachment.uploadedAt)}${
      attachment.uploaderLogin ? ' par ' + attachment.uploaderLogin : ''
    }`;

  const buildCommentTimestamp = (attachment: Attachment) =>
    `Renseigné${formatDateTimeForFrenchDisplay(attachment.commentedAt)}${
      attachment.commenterLogin ? ' par ' + attachment.commenterLogin : ''
    }`;

  return (
    <SectionCard title="Pièces justificatives">
      <Table size="small">
        {existingAttachments.length > 0 && attachmentCanBeAddedAfterValidation && (
          <TableHead>
            <TableRow>
              <TableCell>Type</TableCell>
              <TableCell>Commentaire</TableCell>
            </TableRow>
          </TableHead>
        )}
        <TableBody>
          {existingAttachments.map(({ attachment, attachmentRenderData }) => (
            <TableRow key={attachment.id}>
              <TableCell>
                <AttachmentName
                  attachmentData={attachmentRenderData}
                  attachment={attachment}
                  renderInfoTooltip={false}
                  downloadLink
                />
                {attachmentCanBeAddedAfterValidation && (
                  <i>{attachment.uploadedAt || attachment.uploaderLogin ? buildUploadTimestamp(attachment) : ''}</i>
                )}
              </TableCell>
              {attachmentCanBeAddedAfterValidation && (
                <>
                  <TableCell sx={{ whiteSpace: 'pre-line', overflowWrap: 'break-word' }}>
                    {attachment.comment && (
                      <>
                        {attachment.comment}
                        <br />
                      </>
                    )}
                    <i>
                      {attachment.commentedAt || attachment.commenterLogin ? buildCommentTimestamp(attachment) : ''}
                    </i>
                  </TableCell>
                </>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </SectionCard>
  );
};
