import React from 'react';
import { Box, Divider, List } from '@mui/material';
import MenuEntry from './MenuEntry';
import { useAuthenticatedCurrentUser } from '../../../security/CurrentUserContext';
import { useMenuData } from './useMenuData';

const Menu = () => {
  const currentUser = useAuthenticatedCurrentUser();
  const menuData = useMenuData();

  return (
    <>
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          padding: (theme) => theme.spacing(0, 1),
          // necessary for content to be below app bar
          ...theme.mixins.toolbar,
          justifyContent: 'flex-end',
          backgroundImage: 'url("/images/logo.png")',
          backgroundPosition: 'center',
          backgroundSize: 'auto 80%',
          backgroundRepeat: 'no-repeat',
        })}
      />
      <Divider />
      <List sx={{ flexGrow: 1 }}>
        {menuData.map((menuEntry) => (
          <MenuEntry currentUser={currentUser.userDetails} depth={0} menuEntry={menuEntry} key={menuEntry.label} />
        ))}
      </List>
    </>
  );
};

export default Menu;
