import React from 'react';
import { TableCell as MuiTableCell, TableCellProps as MuiTableCellProps } from '@mui/material';

export type TableCellProps = {
  children?: React.ReactNode;
  onClick?: () => void;
  ['data-testid']?: string;
  padding?: 'checkbox' | 'none' | 'normal';
  colSpan?: number;
  align?: MuiTableCellProps['align'];
  sx?: MuiTableCellProps['sx'];
  // when 'true', adds line breaks to cell with long text without spaces that would overflow otherwise.
  // useful for user-controlled text such as last name, cities...
  breakWord?: boolean;
};

export const TableCell = ({
  children,
  padding = 'normal',
  onClick,
  'data-testid': dataTestId,
  colSpan,
  align,
  sx,
  breakWord,
}: TableCellProps) => {
  function handleClick(e?: React.MouseEvent<HTMLDivElement>) {
    e?.stopPropagation();
    onClick!();
  }

  return (
    <MuiTableCell
      align={align}
      onClick={onClick ? handleClick : undefined}
      padding={padding}
      data-testid={dataTestId}
      colSpan={colSpan}
      sx={[breakWord ? { wordBreak: 'break-word' } : {}, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      {children}
    </MuiTableCell>
  );
};

export default TableCell;
