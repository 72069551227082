import { Template, TemplateFilename } from './templateTypes';
import { UserCompany } from '../user/commonTypes';

const fg = (name: string) => `${name} (France Galop)`;
const lt = (name: string) => `${name} (Le Trot)`;

const cancelledVerdictLabel = "Avis d'annulation";
const rejectedVerdictLabel = 'Avis défavorable';
const grantedVerdictLabel = 'Avis favorable';
const rejectedVerdictReasonLabel = "Motivations d'avis défavorable";

export const templateLabels: (userCompany: UserCompany) => Readonly<Record<TemplateFilename, string>> = (
  userCompany,
) => ({
  'avis_annulation.docx': cancelledVerdictLabel,
  'avis_annulation_fg.docx': fg(cancelledVerdictLabel),
  'avis_annulation_lt.docx': lt(cancelledVerdictLabel),
  'avis_defavorable.docx': rejectedVerdictLabel,
  'avis_defavorable_fg.docx': fg(rejectedVerdictLabel),
  'avis_defavorable_lt.docx': lt(rejectedVerdictLabel),
  'avis_dossier_complet.docx': 'Avis de dossier complet',
  'avis_favorable.docx': grantedVerdictLabel,
  'avis_favorable_hd.docx': 'Avis favorable hors délai',
  'avis_favorable_fg.docx': fg(grantedVerdictLabel),
  'avis_favorable_lt.docx': lt(grantedVerdictLabel),
  'fiche.docx': "Fiche d'agrément",
  'motivations_avis_defavorable.docx': rejectedVerdictReasonLabel,
  'motivations_avis_defavorable_fg.docx': fg(rejectedVerdictReasonLabel),
  'motivations_avis_defavorable_lt.docx': lt(rejectedVerdictReasonLabel),
  'pdv_avis_annulation.docx': "Avis d'annulation PDV",
  'pdv_avis_defavorable.docx': 'Avis défavorable PDV',
  'pdv_avis_dossier_complet.docx': 'Avis de dossier complet PDV',
  'pdv_avis_favorable.docx': 'Avis favorable PDV',
  'pdv_avis_favorable_hd.docx': 'Avis favorable hors délai PDV',
  'recepisse.docx': 'Récépissé',
  'saisine.docx': 'Saisine',
  'stats.xls': 'Statistiques',
  'stats-a.xls': userCompany === UserCompany.PMU ? 'Statistiques - Zone de vente' : 'Statistiques - Agence/Secteur',
  'stats-m.xls': 'Statistiques - Ministère',
});

export const templateUrl = (template: Template) => `/api/templates/${template.filename}`;
