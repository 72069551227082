import { useForm } from 'react-final-form';
import { useVerdictTypeFormValue } from './useVerdictTypeFormValue';
import { useEffect } from 'react';
import { Demand, VerdictType } from '../../../demandTypes';
import { hasAskedCancellation } from './verdictInputFormUtils';

export const useStandardCancelledVerdictFormHelper = (demand: Demand) => {
  const { change } = useForm();
  const currentVerdictType = useVerdictTypeFormValue().value;
  const supportsMinistryCancellationFields = !hasAskedCancellation(demand);

  useEffect(() => {
    if (currentVerdictType !== VerdictType.CANCELLED || !supportsMinistryCancellationFields) {
      change('cancellationComment', undefined);
    }
  }, [change, currentVerdictType, supportsMinistryCancellationFields]);

  return { supportsMinistryCancellationFields };
};
