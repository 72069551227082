import { FormFieldLabel } from '../../../form/components/FormFieldLabel';
import { EnhancedTextField } from '../../../form/components/EnhancedTextField';
import React, { useState } from 'react';
import ApiHelper from '../../../api/apiHelper';
import { Severity } from '../../../utils/severityUtils';
import useSnackbar from '../../../snackbar/useSnackbar';
import { Attachment } from '../../demandTypes';
import { captureException } from '@sentry/react';

export const AttachmentCommentTextArea = ({ demandId, attachment }: { demandId: string; attachment: Attachment }) => {
  const { enqueueSnackbar } = useSnackbar();
  const [comment, setComment] = useState<string>(attachment.comment ?? '');

  const handleOnBlur = async () => {
    const commentChanged = (attachment.comment ?? '') !== comment.trim();

    if (commentChanged) {
      const cmd = {
        attachmentId: attachment.id,
        comment,
      };
      try {
        await ApiHelper.putJson(`/api/demands/${demandId}/attachment/comment`, cmd);
        enqueueSnackbar({
          content: 'Le commentaire a été sauvegardé avec succès',
          severity: Severity.SUCCESS,
        });
      } catch (e) {
        enqueueSnackbar({
          severity: Severity.ERROR,
          content: "Le commentaire n'a pas pu être sauvegardé.",
        });
        captureException(e);
      }
    }
  };

  return (
    <EnhancedTextField
      variant="standard"
      fullWidth
      label={<FormFieldLabel label="Commentaire" required={false} />}
      multiline
      rows={1}
      value={comment}
      onChange={(e) => setComment(e.target.value)}
      onBlur={handleOnBlur}
    />
  );
};
