import {
  SocieteMereUsersApiSearchCommand,
  SocieteMereUsersFilterValues,
  SocieteMereUsersSortableFields,
} from './societeMereUsersTypes';
import { convertWithDefaultNumber, parseQueryString } from '@eogile/agrements-common/src/utils/queryStringUtils';
import { SocieteMereUserProfile } from '../societeMereUserTypes';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, SortOrder } from '@eogile/agrements-common/src/components/table/tableTypes';
import { CurrentSocieteMereApplicationUserDetails } from '../../security/types';

/**
 * Transform the given query string into valid filter form values.
 */
export function queryStringToFilterValues(search: string): SocieteMereUsersFilterValues {
  const params = parseQueryString(search);
  return {
    login: (params.login as string) ?? null,
    lastName: (params.lastName as string) ?? null,
    email: (params.email as string) ?? null,
    profile: (params.profile as SocieteMereUserProfile) ?? null,
    page: convertWithDefaultNumber(params.page, DEFAULT_PAGE),
    pageSize: convertWithDefaultNumber(params.pageSize, DEFAULT_PAGE_SIZE),
    sortField: (params.sortField as SocieteMereUsersSortableFields) ?? 'LOGIN',
    sortOrder: (params.sortOrder as SortOrder) ?? 'ASC',
  };
}

/**
 * Transform th given filter values into a search command that can
 * be understood by the API.
 */
export function toApiSearchCommand(
  currentUser: CurrentSocieteMereApplicationUserDetails,
  filterValues: SocieteMereUsersFilterValues,
): SocieteMereUsersApiSearchCommand {
  return {
    ...filterValues,
    company: currentUser.company,
  };
}
