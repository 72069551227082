import { SocieteMereUserProfile } from './societeMereUserTypes';
import { CurrentSocieteMereApplicationUserDetails, CurrentSocieteMereUserDetails } from '../security/types';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';

export const societeMereProfileDisplayLabels: Record<SocieteMereUserProfile, string> = {
  [SocieteMereUserProfile.SOCIETE_MERE_DEMAND_READER]: 'Société mère Lecture',
  [SocieteMereUserProfile.SOCIETE_MERE_WRITER]: 'Société mère Demandeur',
  [SocieteMereUserProfile.SOCIETE_MERE_ADMIN]: 'Société mère Administrateur',
};

export const isSocieteMereUser = (
  user: CurrentSocieteMereApplicationUserDetails,
): user is CurrentSocieteMereUserDetails => {
  return user.company === UserCompany.LE_TROT || user.company === UserCompany.FRANCE_GALOP;
};
