import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { Card, CardContent, CardHeader, Collapse, Grid } from '@mui/material';
import FormFiltersListener from '@eogile/agrements-common/src/form/components/FormFiltersListener';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import CollapsableCardHeaderAction from '@eogile/agrements-common/src/components/card/CollapsableCardHeaderAction';
import DateRangePickerAdapter from '@eogile/agrements-common/src/form/components/DateRangePickerAdapter';
import SelectAdapter from '@eogile/agrements-common/src/form/components/SelectAdapter';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';
import { SocieteMereDemandsFiltersType, SocieteMereDemandsLoadRequestParams } from '../societeMereDemandListTypes';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { EnumSelectAdapter } from '@eogile/agrements-common/src/form/components/EnumSelectAdapter';
import { SocieteMereAgreementType, SocieteMereNatureType } from '../societeMereDemandTypes';
import {
  allSocieteMereDemandStatuses,
  displaySocieteMereAgreementType,
  franceGalopAgreementTypes,
  leTrotAgreementTypes,
  societeMereNatureTypeLabels,
} from '../societeMereDemandUtils';
import { useSocieteMereAuthenticatedCurrentUser } from '../../security/hooks';
import { smDemandsFiltersValidator } from './societeMereDemandsFiltersUtils';
import { demandStatusLabels } from '@eogile/agrements-common/src/demand/demandUtils';
import MinistryTerritorialAreaSelect from '@eogile/agrements-common/src/components/ministry-territorial-area/ministryTerritorialAreaSelect';

type SocieteMereDemandsFiltersProps = {
  initialValues: SocieteMereDemandsFiltersType;
  formValuesToQueryString: (values: SocieteMereDemandsLoadRequestParams) => Record<string, string>;
};

const SocieteMereDemandsFiltersFields = ({
  initialValues,
  formValuesToQueryString,
}: SocieteMereDemandsFiltersProps) => {
  const { userDetails } = useSocieteMereAuthenticatedCurrentUser();
  const defaultOpened = Object.values(initialValues).some((value) => Boolean(value));
  const [opened, setOpened] = useState(defaultOpened);
  const isMinistryUser = userDetails.company === UserCompany.MINISTRY;

  const toggleOpened = () => {
    setOpened(!opened);
  };

  const getAgreementTypeOptions = () => {
    switch (userDetails.company) {
      case UserCompany.MINISTRY:
        return Object.values(SocieteMereAgreementType);
      case UserCompany.LE_TROT:
        return leTrotAgreementTypes;
      case UserCompany.FRANCE_GALOP:
        return franceGalopAgreementTypes;
    }
  };

  const statusOptions = allSocieteMereDemandStatuses
    // If the user belongs to the ministry, requests with the "CREATED" status cannot be displayed
    .filter((p) => (userDetails.company === UserCompany.MINISTRY ? p !== DemandStatus.CREATED : true))
    .map((s) => ({ id: s, name: demandStatusLabels[s] }));

  return (
    <Card>
      <CardHeader
        sx={{ cursor: 'pointer', padding: '5px 10px' }}
        onClick={toggleOpened}
        title="Critères"
        action={<CollapsableCardHeaderAction opened={opened} toggleOpened={toggleOpened} />}
      />
      <CardContent>
        <TextFieldAdapter
          placeholder="Rechercher un nom, une enseigne, un code pdv, une ville ou un numéro chrono..."
          name="fullText"
          label="Recherche libre"
        />
      </CardContent>
      <Collapse in={opened} data-testid="collapsable-card-content">
        <CardContent>
          <FormFiltersListener transformValues={formValuesToQueryString} />
          <Grid container rowSpacing={2}>
            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter name="chronoNumber" label="No Chrono" />
              </Grid>
              <Grid item xs={6}>
                <EnumSelectAdapter
                  name="typeAgrementCode"
                  label="Type d'agrément"
                  options={getAgreementTypeOptions()}
                  optionName={(opt) => displaySocieteMereAgreementType(opt, isMinistryUser)}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter name="requesterNom" label="Nom / dénomination" />
              </Grid>
              <Grid item xs={6}>
                <EnumSelectAdapter
                  name="typeNatureCode"
                  label="Nature"
                  options={Object.values(SocieteMereNatureType)}
                  optionName={(opt) => societeMereNatureTypeLabels[opt]}
                />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter name="requesterPrenom" label="Prénom" />
              </Grid>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="validationDateLowerBound"
                  upperBoundFieldName="validationDateUpperBound"
                  label="Date de validation"
                />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="requesterDateNaissanceLowerBound"
                  upperBoundFieldName="requesterDateNaissanceUpperBound"
                  label="Date de naissance"
                />
              </Grid>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="receptionDateLowerBound"
                  upperBoundFieldName="receptionDateUpperBound"
                  label="Date de réception"
                />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter name="requesterAddressPays" label="Pays" />
              </Grid>
              <Grid item xs={6}>
                <SelectAdapter name="status" label="Statut" options={statusOptions} />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <TextFieldAdapter name="requesterAddressDepartment" label="Numéro du département" />
              </Grid>
              <Grid item xs={6}>
                <TextFieldAdapter name="ministryReference" label="Référence du ministère" />
              </Grid>
            </Grid>

            <Grid container item spacing={4}>
              <Grid item xs={6}>
                <MinistryTerritorialAreaSelect fieldName="territorialArea" />
              </Grid>
              <Grid item xs={6}>
                <DateRangePickerAdapter
                  lowerBoundFieldName="verdictValidationDateLowerBound"
                  upperBoundFieldName="verdictValidationDateUpperBound"
                  label="Date de validation de l'avis"
                />
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </Collapse>
    </Card>
  );
};

const SocieteMereDemandsFilters = ({ initialValues, formValuesToQueryString }: SocieteMereDemandsFiltersProps) => {
  return (
    <Form initialValues={initialValues} validate={smDemandsFiltersValidator} subscription={{}} onSubmit={() => {}}>
      {() => (
        <SocieteMereDemandsFiltersFields
          initialValues={initialValues}
          formValuesToQueryString={formValuesToQueryString}
        />
      )}
    </Form>
  );
};

export default SocieteMereDemandsFilters;
