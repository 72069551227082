export enum MinistryTerritorialArea {
  DTPJ_AJACCIO = 'DTPJ_AJACCIO',
  DTPJ_ANGERS = 'DTPJ_ANGERS',
  DTPJ_CLERMONT_FERRAND = 'DTPJ_CLERMONT_FERRAND',
  DTPJ_DIJON = 'DTPJ_DIJON',
  DTPJ_LIMOGES = 'DTPJ_LIMOGES',
  DTPJ_MONTPELLIER = 'DTPJ_MONTPELLIER',
  DTPJ_NANCY = 'DTPJ_NANCY',
  DTPJ_NANTES = 'DTPJ_NANTES',
  DTPJ_ORLEANS = 'DTPJ_ORLEANS',
  DTPJ_REIMS = 'DTPJ_REIMS',
  DTPJ_ROUEN = 'DTPJ_ROUEN',
  DTPJ_TOULOUSE = 'DTPJ_TOULOUSE',
  DTPN_GUYANE = 'DTPN_GUYANE',
  DTPN_LA_REUNION = 'DTPN_LA_REUNION',
  DTPN_MAYOTTE = 'DTPN_MAYOTTE',
  DTPN_NOUVELLE_CALEDONIE = 'DTPN_NOUVELLE_CALEDONIE',
  DZPJ_ANTILLES = 'DZPJ_ANTILLES',
  DZPJ_EST_STRASBOURG = 'DZPJ_EST_STRASBOURG',
  DZPJ_NORD_LILLE = 'DZPJ_NORD_LILLE',
  DZPJ_OUEST_RENNES = 'DZPJ_OUEST_RENNES',
  DZPJ_SUD_EST_LYON = 'DZPJ_SUD_EST_LYON',
  DZPJ_SUD_MARSEILLE = 'DZPJ_SUD_MARSEILLE',
  DZPJ_SUD_OUEST_BORDEAUX = 'DZPJ_SUD_OUEST_BORDEAUX',
  POLYNESIE_FRANCAISE = 'POLYNESIE_FRANCAISE',
  SCCJ_PARIS = 'SCCJ_PARIS',
  SPJ_ANNECY = 'SPJ_ANNECY',
  SPJ_BAYONNNE = 'SPJ_BAYONNNE',
  SPJ_CAEN = 'SPJ_CAEN',
  SPJ_CHAMBERY = 'SPJ_CHAMBERY',
  SPJ_COQUELLES = 'SPJ_COQUELLES',
  SPJ_CREIL = 'SPJ_CREIL',
  SPJ_LA_ROCHELLE = 'SPJ_LA_ROCHELLE',
  SPJ_METZ = 'SPJ_METZ',
  SPJ_NICE = 'SPJ_NICE',
  SPJ_PERPIGNAN = 'SPJ_PERPIGNAN',
  SPJ_QUIMPER = 'SPJ_QUIMPER',
  SPJ_SAINT_ETIENNE = 'SPJ_SAINT_ETIENNE',
  SPJ_TOULON = 'SPJ_TOULON',
}

export enum MinistryUserProfile {
  MINISTRY_PREPARATOR = 'MINISTRY_PREPARATOR',
  MINISTRY_VALIDATOR = 'MINISTRY_VALIDATOR',
  MINISTRY_LOCAL = 'MINISTRY_LOCAL',
  MINISTRY_ADMIN = 'MINISTRY_ADMIN',
}
