import { useCallback } from 'react';

import ApiHelper from '../api/apiHelper';
import { RequestParams } from '../api/apiTypes';
import { usePromiseLoader } from './usePromiseLoader';

/**
 * Hook that centralizes the logic of loading a resource from the server.
 *
 * @param path The URL where the resource is stored.
 * @param params The query string params
 * @param active If active, data is not loaded
 */
export function useDataLoader<T>(path: string, params?: RequestParams, active: boolean = true) {
  const promiseGetter = useCallback(() => ApiHelper.get(path, params), [path, params]);
  return usePromiseLoader<T>(promiseGetter, active);
}
