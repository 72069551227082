import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import { withFieldNamePrefix } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { EnumSelectAdapter } from '@eogile/agrements-common/src/form/components/EnumSelectAdapter';
import { SocieteMereDemand, SocieteMereDemandRequesterSituation } from '../societeMereDemandTypes';
import { societeMereDemandRequesterSituationLabels } from '../societeMereDemandUtils';
import { DatePickerAdapter } from '@eogile/agrements-common/src/form/components/DatePickerAdapter';
import { NumberFieldAdapter } from '@eogile/agrements-common/src/form/components/NumberFieldAdapter';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import ReadOnlyField from '@eogile/agrements-common/src/form/components/ReadOnlyField';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { postJanuaryFirst1900AndNotAfterTodayDatePickerProps } from '@eogile/agrements-common/src/form/helper/formUtils';

type Props = {
  demand: SocieteMereDemand | undefined;
};

export const SocieteMereDemandRequesterSection = ({ demand }: Props) => {
  const fieldName = withFieldNamePrefix('requester');

  return (
    <SectionCard title="Demandeur">
      <FormGridContainer>
        <FormGridItem>
          <EnumSelectAdapter
            name={fieldName('situation')}
            label="Situation de famille"
            options={Object.values(SocieteMereDemandRequesterSituation)}
            optionName={(opt) => societeMereDemandRequesterSituationLabels[opt]}
          />
        </FormGridItem>
        <FormGridItem>
          <DatePickerAdapter
            name={fieldName('maritalStatusDate')}
            label="Date (si Pacsé, Marié, ou Divorcé)"
            {...postJanuaryFirst1900AndNotAfterTodayDatePickerProps}
          />
        </FormGridItem>
        <FormGridItem>
          <NumberFieldAdapter name={fieldName('numberOfChildren')} label="Nombre d'enfants" />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('jobName')} label="Profession" cssUppercase />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('companyNameAndAddress')} label="Nom et adresse de l’entreprise" />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name={fieldName('companyActivity')} label="Nature de l’activité de l’entreprise" />
        </FormGridItem>
        {demand?.requester.referenceTaxIncome != null && (
          <FormGridItem>
            <ReadOnlyField value={demand.requester.referenceTaxIncome} label="Revenu fiscal de référence (€)" />
          </FormGridItem>
        )}
      </FormGridContainer>
    </SectionCard>
  );
};
