import React, { useState } from 'react';
import { Avatar, Box, Divider, Popover, Typography } from '@mui/material';
import { useAuthenticatedCurrentUser } from '../../security/CurrentUserContext';
import { LogoutButton } from '../../security/components/LogoutButton';
import { useAppContext } from '../../AppContext';
import { UserCompany } from '../../user/commonTypes';
import PasswordUpdateDialog from '../../security/password/components/PasswordUpdateDialog';
import SecondaryActionButton from '../../components/buttons/SecondaryActionButton';
import { Person } from '@mui/icons-material';

const shouldDisplayUpdatePasswordLink = (currentUser: { company: UserCompany }) =>
  [UserCompany.MINISTRY, UserCompany.LE_TROT, UserCompany.FRANCE_GALOP].includes(currentUser.company);

const HeaderAvatar = () => {
  const { profileDisplayLabel } = useAppContext();
  const [opened, setOpened] = useState(false);
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const { userDetails: currentUser } = useAuthenticatedCurrentUser();
  const [passwordDialogOpened, setPasswordDialogOpened] = useState(false);
  const displayUpdatePasswordLink = shouldDisplayUpdatePasswordLink(currentUser);

  const onAvatarClick = (e: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(e.currentTarget);
    setOpened(true);
  };

  return (
    <>
      <Avatar
        data-testid="avatar"
        sx={{
          backgroundColor: (theme) => theme.palette.common.white,
          color: (theme) => theme.palette.primary.main,
          cursor: 'pointer',
          border: '2px solid',
        }}
        onClick={onAvatarClick}
      >
        <Person />
      </Avatar>
      <Popover
        anchorEl={anchorEl}
        sx={{
          marginTop: '5px',
          marginRight: '5px',
        }}
        onClose={() => setOpened(false)}
        open={opened}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Box sx={{ padding: 1.5 }}>
          <Typography variant="subtitle2" data-testid="current-user-name">
            {`${currentUser.firstName} ${currentUser.lastName}`}
          </Typography>
          <Typography variant="body2" data-testid="current-user-profiles">
            {currentUser.profiles.map(profileDisplayLabel).join(', ')}
          </Typography>
        </Box>
        <Divider />
        {displayUpdatePasswordLink && (
          <>
            <Box sx={{ padding: 1.5, display: 'flex', justifyContent: 'center' }}>
              <PasswordUpdateDialog opened={passwordDialogOpened} onClose={() => setPasswordDialogOpened(false)} />
              <SecondaryActionButton label="Modifier mon mot de passe" action={() => setPasswordDialogOpened(true)} />
            </Box>
            <Divider />
          </>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            padding: 1,
          }}
        >
          <LogoutButton />
        </Box>
      </Popover>
    </>
  );
};

export default HeaderAvatar;
