import {
  AcquirerCompanyType,
  AcquirerType,
  CancellationReason,
  Demand,
  DemandStatus,
  DemandWithCandidates,
  OperatingMode,
  RejectionReason,
  VerdictType,
} from './demandTypes';
import { FormMode } from '../form/helper/FormModeProvider';

export const demandStatusLabels: Record<DemandStatus, string> = {
  [DemandStatus.CREATED]: 'Créée',
  [DemandStatus.CANCELLATION_ASKED]: "En demande d'annulation",
  [DemandStatus.CANCELLED_VERDICT]: 'Annulée',
  [DemandStatus.VALIDATED]: 'Validée',
  [DemandStatus.RECEIVED_BY_MINISTRY]: 'Réceptionnée',
  [DemandStatus.WAITING_MINISTRY_VERDICT]: 'Avis à valider',
  [DemandStatus.GRANTED_VERDICT]: 'Avis favorable',
  [DemandStatus.GRANTED_VERDICT_OUTSIDE_TIME_LIMIT]: 'Avis favorable HD',
  [DemandStatus.REJECTED_VERDICT]: 'Avis défavorable',
  [DemandStatus.CANCELLED_VERDICT]: "Avis d'annulation",
  [DemandStatus.COMPLETE_CASE_VERDICT]: 'Dossier complet',
};

export const operatingModeLabels: Record<OperatingMode, string> = {
  [OperatingMode.DIRECT_EXPLOITATION]: 'Exploitation directe',
  [OperatingMode.LEASE_MANAGEMENT]: 'Location gérance',
  [OperatingMode.OCCUPATION]: 'Occupation',
};

export const acquirerTypeLabels: Record<AcquirerType, string> = {
  [AcquirerType.COMPANY]: 'En société',
  [AcquirerType.IN_OWN_NAME]: 'En nom propre',
};

export const acquirerCompanyTypeLabels: Record<AcquirerCompanyType, string> = {
  [AcquirerCompanyType.SNC]: 'SNC',
  [AcquirerCompanyType.SARL]: 'SARL',
  [AcquirerCompanyType.SCS]: 'SCS',
  [AcquirerCompanyType.SA]: 'SA',
  [AcquirerCompanyType.SAS]: 'SAS',
  [AcquirerCompanyType.SASU]: 'SASU',
  [AcquirerCompanyType.SCA]: 'SCA',
  [AcquirerCompanyType.EURL]: 'EURL',
  [AcquirerCompanyType.SCP]: 'SCP',
  [AcquirerCompanyType.OTHER]: 'AUTRES',
  [AcquirerCompanyType.EIRL]: 'EIRL',
};

export const activeAcquirerCompanyTypes: readonly AcquirerCompanyType[] = Object.values(AcquirerCompanyType).filter(
  (companyType) => companyType !== AcquirerCompanyType.EIRL,
);

export const verdictTypeLabels: Record<VerdictType, string> = {
  [VerdictType.GRANTED]: 'Avis favorable',
  [VerdictType.GRANTED_OUTSIDE_TIME_LIMIT]: 'Avis favorable HD',
  [VerdictType.CANCELLED]: "Avis d'annulation",
  [VerdictType.REJECTED]: 'Avis défavorable',
  [VerdictType.COMPLETE_CASE]: 'Dossier complet',
};

export const rejectionReasonLabels: Record<RejectionReason, string> = {
  [RejectionReason.NO_BUDGET_JUSTIFICATION]: 'Non justification des fonds',
  [RejectionReason.PUBLIC_ORDER_CONSIDERATIONS]: "Considérations d'ordre public",
  [RejectionReason.OTHER]: 'Autres',
  [RejectionReason.SOCIETES_MERES_MEETINGS_MISSED]: "N'a pas déféré aux convocations",
  [RejectionReason.SOCIETES_MERES_MISSING_DOCUMENTS]: "N'a pas fourni les documents utiles à l'enquête",
  [RejectionReason.PMU_MEETINGS_MISSED]: "N'a (ont) pas déféré aux convocations",
  [RejectionReason.PMU_MISSING_DOCUMENTS]: "N'a (ont) pas fourni les documents utiles à l'enquête",
};

export const cancellationReasonLabels: Record<CancellationReason, string> = {
  [CancellationReason.NO_PRESENTATION]: 'Non présentation',
  [CancellationReason.FILE_ERROR]: 'Erreur fichier',
  [CancellationReason.FDJ_OTHER]: 'Autre',
  [CancellationReason.INCOMPLETE_CASE]: 'Dossier incomplet',
  [CancellationReason.DID_NOT_DEFER]: "N'a (ont) pas déféré aux convocations",
  [CancellationReason.MINISTRY_OTHER]: 'Autre',
};

export const pageTitle = (mode: FormMode) => {
  switch (mode) {
    case FormMode.CREATE:
      return 'Création de la demande';
    case FormMode.EDIT:
      return 'Modification de la demande';
    case FormMode.READ:
      return 'Visualisation de la demande';
  }
};

export const allDemandClosedStatuses: readonly DemandStatus[] = [
  DemandStatus.GRANTED_VERDICT,
  DemandStatus.GRANTED_VERDICT_OUTSIDE_TIME_LIMIT,
  DemandStatus.REJECTED_VERDICT,
  DemandStatus.CANCELLED_VERDICT,
  DemandStatus.COMPLETE_CASE_VERDICT,
];

export const demandIsInFinalState = (demand: Demand) => {
  return allDemandClosedStatuses.includes(demand.status);
};

export const demandHasBeenReceivedByMinistry = (demand: Demand) => {
  return (
    [DemandStatus.RECEIVED_BY_MINISTRY, DemandStatus.WAITING_MINISTRY_VERDICT].includes(demand.status) ||
    demandIsInFinalState(demand)
  );
};

export const isMinistryCancellationReason = (reason: CancellationReason) => {
  return [
    CancellationReason.INCOMPLETE_CASE,
    CancellationReason.DID_NOT_DEFER,
    CancellationReason.MINISTRY_OTHER,
  ].includes(reason);
};

export const allNonClosedStatuses: readonly DemandStatus[] = [
  DemandStatus.CREATED,
  DemandStatus.VALIDATED,
  DemandStatus.CANCELLATION_ASKED,
  DemandStatus.RECEIVED_BY_MINISTRY,
  DemandStatus.WAITING_MINISTRY_VERDICT,
];

export const isDemandWithCandidates = (demand: Demand): demand is DemandWithCandidates => {
  return 'candidates' in demand;
};

export const needsVerdictRejectedReason = (demand: Demand) => {
  return (
    demand.status === DemandStatus.REJECTED_VERDICT ||
    (demand.status === DemandStatus.WAITING_MINISTRY_VERDICT && demand.verdict?.status === VerdictType.REJECTED)
  );
};

export const demandIsValidatedAndNotInFinalState = (demand: Demand) => {
  return [
    DemandStatus.VALIDATED,
    DemandStatus.CANCELLATION_ASKED,
    DemandStatus.RECEIVED_BY_MINISTRY,
    DemandStatus.WAITING_MINISTRY_VERDICT,
  ].includes(demand.status);
};
