import { Card, CardContent, CardHeader } from '@mui/material';
import { ReactNode } from 'react';

type Props = {
  title: string;
  children: ReactNode;
  headerAction?: ReactNode;
  dataTestId?: string;
};

export const SectionCard = ({ title, headerAction, children, dataTestId }: Props) => {
  return (
    <Card data-testid={dataTestId}>
      <CardHeader title={title} action={headerAction} />
      <CardContent>{children}</CardContent>
    </Card>
  );
};
