import { SectionCard } from '../../components/cards/SectionCard';
import { FormGridItem } from '../../components/grid/FormGridItem';
import TextFieldAdapter from '../../form/components/TextFieldAdapter';
import ReadOnlyField from '../../form/components/ReadOnlyField';
import { Demand } from '../demandTypes';
import { FormGridContainer } from '../../components/grid/FormGridContainer';
import dayjs from 'dayjs';

type Props = { demand: Demand };

export const DemandMinistryDataSection = ({ demand }: Props) => {
  return (
    <SectionCard title="Données ministère">
      <FormGridContainer>
        <FormGridItem>
          <ReadOnlyField label="No Chrono" value={demand.chronoNumber} />
        </FormGridItem>
        <FormGridItem>
          <TextFieldAdapter name="ministryReference" label="Référence" required />
        </FormGridItem>
        {demand.receptionDateTime && (
          <FormGridItem>
            <ReadOnlyField label="Réceptionnée le" value={dayjs(demand.receptionDateTime)} />
          </FormGridItem>
        )}
      </FormGridContainer>
    </SectionCard>
  );
};
