import {
  getFormHelperTextUtilityClasses,
  getFormLabelUtilityClasses,
  getInputUtilityClass,
  styled,
  TextField,
  TextFieldProps,
} from '@mui/material';

export type EnhancedTextFieldProps = TextFieldProps & {
  warning?: boolean;
  cssUppercase?: boolean;
  cssCapitalize?: boolean;
};

/**
 * Enhanced MUI TextField with additional display tweaks:
 * - accepts a "warning" prop to have a warning display state that looks just like the "error" state, except with a different color scheme.
 * - accepts an "cssUppercase" prop that automatically uppercase the displayed input text with some CSS. Note that this does not change
 * the underlying value, it is the responsibility of the back-end to ensure that some fields must be upper-cased.
 * - accepts an "cssCapitalize" prop that does the same thing, except that it only capitalizes the first letter of each word.
 */
export const EnhancedTextField = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'warning' && prop !== 'cssUppercase' && prop !== 'cssCapitalize',
})<EnhancedTextFieldProps>(({ warning, error, cssUppercase, cssCapitalize, theme }) => ({
  ...(warning &&
    !error && {
      [`& .${getFormLabelUtilityClasses('root')}`]: {
        color: theme.palette.warning.main,
      },
      [`& .${getInputUtilityClass('underline')}:before`]: {
        borderBottomColor: theme.palette.warning.main,
      },
      [`& .${getInputUtilityClass('underline')}:after`]: {
        borderBottomColor: theme.palette.warning.main,
      },
      [`& .${getFormHelperTextUtilityClasses('root')}`]: {
        color: theme.palette.warning.main,
      },
    }),
  ...(cssUppercase && {
    [`& .${getInputUtilityClass('input')}`]: {
      textTransform: 'uppercase',
    },
  }),
  ...(cssCapitalize && {
    [`& .${getInputUtilityClass('input')}`]: {
      textTransform: 'capitalize',
    },
  }),
}));
