import {
  SocieteMereDemand,
  SocieteMereDemandRequester,
  SocieteMereDemandRequesterPartner,
} from './societeMereDemandTypes';
import { SocieteMereDemandFormCommand, SocieteMereDemandFormValues } from './societeMereDemandFormTypes';
import { toNullSafeDayJsFormValue } from '@eogile/agrements-common/src/form/helper/formUtils';
import { capitalize, trim, uppercase } from '@eogile/agrements-common/src/utils/stringUtils';
import { formatLocalDate } from '@eogile/agrements-common/src/utils/dateUtils';

export const buildInitialSocieteMereDemandFormValues = (
  demand: SocieteMereDemand | undefined,
): SocieteMereDemandFormValues => {
  return {
    agreementType: demand?.agreementType,
    referralDemand: demand?.referralDemand,
    nature: demand?.nature,
    societeMereReference: demand?.societeMereReference,
    otherDemandExists: demand?.otherDemandExists ?? false,
    ministryReference: demand?.ministryReference,
    comment: demand?.comment,
    // force Final-Form to initialize with an empty linked demand
    linkedDemandIds: (demand?.linkedDemandIds?.length ?? 0) > 0 ? [...demand!.linkedDemandIds] : [undefined as any],
    requester: toRequesterFormValues(demand?.requester),
    requesterPartner: toRequesterPartnerFormValues(demand?.requesterPartner),
  };
};

const toRequesterFormValues = (
  requester: SocieteMereDemandRequester | undefined,
): SocieteMereDemandFormValues['requester'] => {
  return {
    acquirerType: requester?.acquirerType,
    civility: requester?.civility,
    denomination: requester?.denomination,
    firstName: requester?.firstName,
    lastName: requester?.lastName,
    birthName: requester?.birthName,
    birthDate: toNullSafeDayJsFormValue(requester?.birthDate),
    birthPlace: requester?.birthPlace,
    birthCountry: requester?.birthCountry,
    nationality: requester?.nationality,
    firstLegalRepresentativeName: requester?.firstLegalRepresentativeName,
    secondLegalRepresentativeName: requester?.secondLegalRepresentativeName,
    address: {
      address1: requester?.address.address1,
      address2: requester?.address.address2,
      address3: requester?.address.address3,
      zipCode: requester?.address.zipCode,
      city: requester?.address.city,
      country: requester?.address.country,
    },
    phoneNumbers: {
      phone1: requester?.phoneNumbers.phone1,
      phone2: requester?.phoneNumbers.phone2,
      phone3: requester?.phoneNumbers.phone3,
    },
    fax: requester?.fax,
    email: requester?.email,
    situation: requester?.situation,
    maritalStatusDate: toNullSafeDayJsFormValue(requester?.maritalStatusDate),
    numberOfChildren: requester?.numberOfChildren,
    jobName: requester?.jobName,
    companyNameAndAddress: requester?.companyNameAndAddress,
    companyActivity: requester?.companyActivity,
  };
};

const toRequesterPartnerFormValues = (
  partner: SocieteMereDemandRequesterPartner | undefined,
): SocieteMereDemandFormValues['requesterPartner'] => {
  return {
    firstName: partner?.firstName,
    lastName: partner?.lastName,
    birthDate: toNullSafeDayJsFormValue(partner?.birthDate),
    birthPlace: partner?.birthPlace,
    nationality: partner?.nationality,
    jobName: partner?.jobName,
    companyNameAndAddress: partner?.companyNameAndAddress,
    companyActivity: partner?.companyActivity,
  };
};

export const buildSocieteMereDemandFormCommand = (
  formValues: SocieteMereDemandFormValues,
): SocieteMereDemandFormCommand => {
  const { requester, requesterPartner: partner } = formValues;
  return {
    agreementType: formValues.agreementType!,
    referralDemand: trim(formValues.referralDemand),
    nature: formValues.nature,
    societeMereReference: trim(uppercase(formValues.societeMereReference))!,
    otherDemandExists: formValues.otherDemandExists,
    ministryReference: trim(formValues.ministryReference),
    comment: trim(formValues.comment),
    linkedDemandIds: formValues.linkedDemandIds.filter(Boolean) as string[],
    requester: {
      acquirerType: requester.acquirerType!,
      civility: requester.civility,
      denomination: trim(uppercase(requester.denomination)),
      lastName: trim(uppercase(requester.lastName)),
      firstName: trim(capitalize(requester.firstName)),
      birthName: trim(uppercase(requester.birthName)),
      birthDate: formatLocalDate(requester.birthDate) ?? undefined,
      birthPlace: trim(uppercase(requester.birthPlace)),
      birthCountry: trim(uppercase(requester.birthCountry)),
      nationality: trim(uppercase(requester.nationality)),
      firstLegalRepresentativeName: trim(uppercase(requester.firstLegalRepresentativeName)),
      secondLegalRepresentativeName: trim(uppercase(requester.secondLegalRepresentativeName)),
      address: {
        address1: trim(uppercase(requester.address.address1))!,
        address2: trim(uppercase(requester.address.address2)),
        address3: trim(uppercase(requester.address.address3)),
        zipCode: trim(requester.address.zipCode),
        city: trim(uppercase(requester.address.city))!,
        country: trim(requester.address.country),
      },
      phoneNumbers: {
        phone1: trim(requester.phoneNumbers.phone1)!,
        phone2: trim(requester.phoneNumbers.phone2),
        phone3: trim(requester.phoneNumbers.phone3),
      },
      fax: trim(requester.fax),
      email: requester.email,
      situation: requester.situation,
      maritalStatusDate: formatLocalDate(requester.maritalStatusDate) ?? undefined,
      numberOfChildren: requester.numberOfChildren,
      jobName: trim(uppercase(requester.jobName)),
      companyNameAndAddress: trim(requester.companyNameAndAddress),
      companyActivity: trim(requester.companyActivity),
    },
    requesterPartner: {
      lastName: trim(uppercase(partner.lastName)),
      firstName: trim(capitalize(partner.firstName)),
      birthDate: formatLocalDate(partner.birthDate) ?? undefined,
      birthPlace: trim(uppercase(partner.birthPlace)),
      nationality: trim(uppercase(partner.nationality)),
      jobName: trim(uppercase(partner.jobName)),
      companyNameAndAddress: trim(partner.companyNameAndAddress),
      companyActivity: trim(partner.companyActivity),
    },
  };
};
