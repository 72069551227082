import dayjs, { Dayjs } from 'dayjs';

export const getAppVersion = (): string => {
  // @ts-ignore
  return process.env.REACT_APP_VERSION ?? 'dev';
};

export const getAppCommitId = (): string | null => {
  // @ts-ignore
  const commitId: string | undefined = process.env.REACT_APP_COMMIT_ID;
  return commitId ?? null;
};

export const getAppBuildDateTime = (): Dayjs | null => {
  // @ts-ignore
  const appTimestamp: string | undefined = process.env.REACT_APP_TIMESTAMP;
  if (!appTimestamp) {
    return null;
  }
  const appTimestampInt = parseInt(appTimestamp, 10);
  return dayjs(appTimestampInt);
};
