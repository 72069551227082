import useUsersListHelper from '../hooks/useUsersListHelper';
import MinistryUsersFilters from './MinistryUsersFilters';
import React from 'react';
import MinistryUsersTable from './MinistryUsersTable';

/**
 * Component in charge of displaying the list of ministry users with
 * its filters.
 */
const MinistryUsersList = () => {
  const { filterValues, onPaginationOrSortChange, paginatedUsers, reloadUsers } = useUsersListHelper();
  return (
    <>
      <MinistryUsersFilters filterValues={filterValues} />
      <MinistryUsersTable
        command={filterValues}
        onCommandChange={onPaginationOrSortChange}
        paginatedUsers={paginatedUsers}
        reloadUsers={reloadUsers}
      />
    </>
  );
};

export default MinistryUsersList;
