import MainActionButton from '../../components/buttons/MainActionButton';
import { TemplateView } from '../templateTypes';
import { ChangeEvent, useState } from 'react';
import { MAX_UPLOAD_FILE_SIZE } from '../../demand/attachmentUtils';
import { Severity } from '../../utils/severityUtils';
import ApiHelper from '../../api/apiHelper';
import { captureException } from '@sentry/react';
import useSnackbar from '../../snackbar/useSnackbar';

type UploadTemplateButtonProps = {
  templateView: TemplateView;
};

export const UploadTemplateButton = ({ templateView }: UploadTemplateButtonProps) => {
  const { template, allowedExtension } = templateView;
  const [pendingUpload, setPendingUpload] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      enqueueSnackbar({
        severity: Severity.ERROR,
        content: 'Le modèle de document dépasse la taille maximale de fichier autorisée (50 Mo).',
      });
      return;
    }

    const formData = new FormData();
    formData.append('file', file);
    try {
      setPendingUpload(true);
      await ApiHelper.put(`/api/templates/${template.filename}`, formData);
      enqueueSnackbar({
        severity: Severity.SUCCESS,
        content: 'Le modèle de document a bien été enregistré.',
      });
    } catch (e) {
      enqueueSnackbar({
        severity: Severity.ERROR,
        content: "Le modèle de document n'a pas pu être enregistré.",
      });
      captureException(e);
    } finally {
      setPendingUpload(false);
    }
  };

  return (
    <MainActionButton
      type="upload"
      label="Remplacer"
      pendingSpinner={pendingUpload}
      fileInput={<input hidden accept={'.' + allowedExtension} type="file" onChange={handleUpload} />}
    />
  );
};
