import { useFormSubmitHelper } from '../../../../form/helper/useFormSubmitHelper';
import ApiHelper from '../../../../api/apiHelper';
import useSnackbar from '../../../../snackbar/useSnackbar';
import { Severity } from '../../../../utils/severityUtils';

export function useVerdictInputFormSubmitHelper(demandId: string) {
  const internalSubmitForm = useFormSubmitHelper();
  const { enqueueSnackbar } = useSnackbar();

  async function submitForm<T>(command: T, onSubmitSuccess: () => void) {
    return await internalSubmitForm(async () => {
      await ApiHelper.putJson(`/api/demands/${demandId}/verdict`, command);
      enqueueSnackbar({
        content: "L'avis a été saisi avec succès",
        severity: Severity.SUCCESS,
      });
      onSubmitSuccess();
    });
  }

  return submitForm;
}
