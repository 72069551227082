import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE, PaginationAndSortCommand } from '../components/table/tableTypes';

/**
 * Transform the given parameters to filter values.
 *
 * The returned object does not contain fields related to table parameters
 * (pagination, sort, …).
 *
 * @param requestParams
 */
export function extractFormValuesFromLoadRequestParams<TFields, TFiltersType>(
  requestParams: PaginationAndSortCommand<TFields> & TFiltersType,
) {
  const { sortField, sortOrder, page, pageSize, ...filterValues } = requestParams;
  return filterValues;
}

/**
 * Return a function takes the form values and returns an object representing the
 * new query string.
 *
 * It will be used by the controls filters form to update the current page query string
 * each time a filter value changes. As the result in the table will be changed just
 * after, table's pagination will be set to default.
 *
 * Currying is used to include the current table parameters (pagination and sort)
 * in the query string computation.
 *
 * @param paginationAndSortCommand The current table parameters.
 */
export const formValuesToQueryString = <TFiltersType, T extends PaginationAndSortCommand<any>>(
  paginationAndSortCommand: T,
): ((filtersValues: TFiltersType) => Record<string, any>) => {
  return (formValues: TFiltersType) => {
    return {
      page: DEFAULT_PAGE,
      pageSize: DEFAULT_PAGE_SIZE,
      sortField: paginationAndSortCommand.sortField,
      sortOrder: paginationAndSortCommand.sortOrder,
      ...formValues,
    };
  };
};

export function extractPaginationAndSortCommand<TSortableFields, T extends PaginationAndSortCommand<TSortableFields>>(
  params: T,
): PaginationAndSortCommand<TSortableFields> {
  return {
    page: params.page,
    pageSize: params.pageSize,
    sortField: params.sortField,
    sortOrder: params.sortOrder,
  };
}

export function mergePaginationAndSortCommandWithParams<
  TSortableFields,
  T extends PaginationAndSortCommand<TSortableFields>,
>(params: T, newCommand: PaginationAndSortCommand<TSortableFields>) {
  return {
    ...params,
    ...newCommand,
  };
}
