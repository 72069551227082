import { Dayjs } from 'dayjs';
import { DemandStatus } from './demandTypes';
import { LocalDate } from '../common-types/dateTypes';
import { MinistryTerritorialArea } from '../user/ministryUserTypes';

export type SimpleDemandView = Readonly<{
  id: string;
  chronoNumber: string;
  receptionDate?: LocalDate;
  validationDate?: LocalDate;
  status: DemandStatus;
  verdictDate?: LocalDate;
  score: number;
}>;

export type DemandsFiltersType = Readonly<{
  status: DemandStatus | null;
  chronoNumber: string | null;
  receptionDateLowerBound: Dayjs | null;
  receptionDateUpperBound: Dayjs | null;
  validationDateLowerBound: Dayjs | null;
  validationDateUpperBound: Dayjs | null;
  ministryReference: string | null;
  fullText: string | null;
  departmentNumber: string | null;
  territorialArea: MinistryTerritorialArea | null;
  verdictValidationDateLowerBound: Dayjs | null;
  verdictValidationDateUpperBound: Dayjs | null;
}>;
export const DEFAULT_DEMANDS_SORT_FIELD = 'SCORE';
export const DEFAULT_DEMANDS_SORT_ORDER = 'ASC';

export type BaseDemandSearchCommand = Readonly<{
  statuses?: readonly DemandStatus[] | null;
  chronoNumber?: string | null;
  receptionDateLowerBound?: LocalDate | null;
  receptionDateUpperBound?: LocalDate | null;
  validationDateLowerBound?: LocalDate | null;
  validationDateUpperBound?: LocalDate | null;
  ministryReference?: string | null;
  fullText?: string | null;
  departmentNumber?: string | null;
  territorialArea?: MinistryTerritorialArea | null;
  verdictValidationDateLowerBound?: LocalDate | null;
  verdictValidationDateUpperBound?: LocalDate | null;
  sortField?: string | null;
  sortOrder?: string | null;
  page?: number;
  pageSize?: number;
}>;
