import { CircularProgress, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { ChangeEvent } from 'react';
import ApiHelper from '../../../api/apiHelper';
import useSnackbar from '../../../snackbar/useSnackbar';
import { Severity } from '../../../utils/severityUtils';
import { Attachment } from '../../demandTypes';
import { AttachmentUploadStatus } from '../../attachmentTypes';
import { MAX_UPLOAD_FILE_SIZE } from '../../attachmentUtils';
import { captureException } from '@sentry/react';

type Props = {
  demandId: string;
  attachmentKey: string;
  uploadStatus: AttachmentUploadStatus;
  onAttachmentUploaded: (attachment: Attachment) => void;
  onUploadStatusChange: (attachmentKey: string, status: AttachmentUploadStatus) => void;
};

export const UploadAttachmentButton = ({
  demandId,
  uploadStatus,
  attachmentKey,
  onAttachmentUploaded,
  onUploadStatusChange,
}: Props) => {
  const inProgress = uploadStatus === 'pending';
  const { enqueueSnackbar } = useSnackbar();

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files![0];
    if (file.size > MAX_UPLOAD_FILE_SIZE) {
      enqueueSnackbar({
        severity: Severity.ERROR,
        content: 'La pièce justificative dépasse la taille maximale de fichier autorisée (50 Mo).',
      });
      return;
    }

    const formData = new FormData();
    formData.append(attachmentKey, file);
    try {
      onUploadStatusChange(attachmentKey, 'pending');
      const attachment = await ApiHelper.put(`/api/demands/${demandId}/attachments`, formData);
      enqueueSnackbar({
        severity: Severity.SUCCESS,
        content: 'La pièce justificative a bien été enregistrée.',
      });
      onAttachmentUploaded(attachment);
    } catch (e) {
      enqueueSnackbar({
        severity: Severity.ERROR,
        content: "La pièce justificative n'a pas pu être enregistrée.",
      });
      captureException(e);
    } finally {
      onUploadStatusChange(attachmentKey, 'idle');
    }
  };

  if (inProgress) {
    return (
      <IconButton disableRipple disabled>
        <CircularProgress size="1em" />
      </IconButton>
    );
  }

  return (
    <IconButton color="secondary" component="label" data-testid="upload-attachment-button">
      <input
        hidden
        accept=".png,.jpg,.jpeg,.pdf"
        type="file"
        onChange={handleUpload}
        data-testid="upload-attachment-input"
      />
      <Add />
    </IconButton>
  );
};
