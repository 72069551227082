import { AttachmentRenderData, AttachmentUploadStatus } from '../../attachmentTypes';
import { Attachment } from '../../demandTypes';
import { UpdateAttachmentRow } from './UpdateAttachmentRow';

type Props = {
  attachmentData: AttachmentRenderData<any>;
  uploadStatus: AttachmentUploadStatus;
  onAttachmentUploaded: (attachment: Attachment) => void;
  onAttachmentRemoved: (attachment: Attachment) => void;
  onUploadStatusChange: (attachmentKey: string, status: 'pending' | 'idle') => void;

  // whether the popup should be displayed for the case where user add attachments after the validation of demand
  afterValidationDisplay: boolean;
};

export const UpdateAttachmentRowGroup = ({
  attachmentData,
  uploadStatus,
  onAttachmentUploaded,
  onAttachmentRemoved,
  onUploadStatusChange,

  afterValidationDisplay,
}: Props) => {
  if (attachmentData.allowMultiple) {
    return (
      <>
        {attachmentData.attachments.map((attachment) => (
          <UpdateAttachmentRow
            key={attachment.info.id}
            attachmentData={attachmentData}
            uploadStatus={uploadStatus}
            attachment={attachment.info}
            onAttachmentUploaded={onAttachmentUploaded}
            onAttachmentRemoved={onAttachmentRemoved}
            onUploadStatusChange={onUploadStatusChange}
            afterValidationDisplay={afterValidationDisplay}
          />
        ))}
        <UpdateAttachmentRow
          attachmentData={attachmentData}
          attachment={undefined}
          uploadStatus={uploadStatus}
          onAttachmentUploaded={onAttachmentUploaded}
          onAttachmentRemoved={onAttachmentRemoved}
          onUploadStatusChange={onUploadStatusChange}
          afterValidationDisplay={afterValidationDisplay}
        />
      </>
    );
  } else {
    return (
      <UpdateAttachmentRow
        attachmentData={attachmentData}
        attachment={attachmentData.attachments[0]?.info}
        uploadStatus={uploadStatus}
        onAttachmentUploaded={onAttachmentUploaded}
        onAttachmentRemoved={onAttachmentRemoved}
        onUploadStatusChange={onUploadStatusChange}
        afterValidationDisplay={afterValidationDisplay}
      />
    );
  }
};
