import { createContext, ReactNode, useCallback, useContext, useEffect, useState } from 'react';
import { AuthenticatedCurrentUser, AuthenticationStatus, CurrentUser, CurrentUserDetails } from './types';
import ApiHelper from '../api/apiHelper';

type Refresher = () => void;
const Ctx = createContext<CurrentUser>({ authenticationStatus: AuthenticationStatus.PENDING_AUTHENTICATION });
const RefreshCtx = createContext<Refresher>(() => {});

/**
 * @param children rest of React component tree
 * @param initialState initialize the current user context with a specific value. This is meant to be used by
 * front-end tests only, so that we can easily render a component for a specific user profile without having
 * to mock /api/user/me each time.
 */
export const CurrentUserProvider = ({
  children,
  initialState,
}: {
  children: ReactNode;
  initialState?: CurrentUser;
}) => {
  const [currentUser, setCurrentUser] = useState<CurrentUser>(
    initialState ?? {
      authenticationStatus: AuthenticationStatus.PENDING_AUTHENTICATION,
    },
  );
  const refreshCurrentUser = useCallback(async () => {
    setCurrentUser(await ApiHelper.get('/api/user/me'));
  }, []);

  useEffect(() => {
    if (!initialState) {
      // noinspection JSIgnoredPromiseFromCall
      refreshCurrentUser();
    }
  }, [initialState, refreshCurrentUser]);

  return (
    <Ctx.Provider value={currentUser}>
      <RefreshCtx.Provider value={refreshCurrentUser}>{children}</RefreshCtx.Provider>
    </Ctx.Provider>
  );
};

export const useCurrentUser = () => {
  return useContext(Ctx);
};

/**
 * returns the authenticated user view with no app-specific typings. You can use the typed variant of this hook in each app module
 * if needed (e.g.: useFdjAuthenticatedCurrentUser)
 */
export function useAuthenticatedCurrentUser<T extends CurrentUserDetails = CurrentUserDetails>() {
  const ctx = useContext(Ctx);
  if (ctx.authenticationStatus !== AuthenticationStatus.AUTHENTICATED) {
    throw new Error('Current user is not authenticated');
  }
  return ctx as AuthenticatedCurrentUser<T>;
}

export const useCurrentUserRefresher = () => {
  return useContext(RefreshCtx);
};
