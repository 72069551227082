import { Demand, VerdictType } from '../../../demandTypes';
import {
  StandardCancelledVerdictInputCommand,
  StandardCancelledVerdictInputFormValues,
  StandardCompleteCaseVerdictInputCommand,
  StandardCompleteCaseVerdictInputFormValues,
  StandardGrantedVerdictInputCommand,
  StandardGrantedVerdictInputFormValues,
  StandardRejectedVerdictInputCommand,
  StandardRejectedVerdictInputFormValues,
} from './verdictInputFormTypes';
import { trim } from '../../../../utils/stringUtils';

export const hasAskedCancellation = (demand: Demand) => Boolean(demand.verdict?.cancellationAskedDateTime);

export const toStandardGrantedVerdictInputFormValues = (): StandardGrantedVerdictInputFormValues => {
  return { verdictType: VerdictType.GRANTED };
};

export const toStandardCompleteCaseVerdictInputFormValues = (): StandardCompleteCaseVerdictInputFormValues => {
  return { verdictType: VerdictType.COMPLETE_CASE };
};

export const toStandardCancelledVerdictInputFormValues = (demand: Demand): StandardCancelledVerdictInputFormValues => {
  return { verdictType: VerdictType.CANCELLED, cancellationComment: demand.verdict?.cancellationComment };
};

export const toStandardRejectedVerdictInputFormValues = (demand: Demand): StandardRejectedVerdictInputFormValues => {
  return { verdictType: VerdictType.REJECTED, rejectionReason: demand.verdict?.rejectionReason };
};

export const toStandardGrantedVerdictInputCommand = (): StandardGrantedVerdictInputCommand => {
  return { verdictType: VerdictType.GRANTED };
};

export const toStandardCompleteCaseVerdictInputCommand = (): StandardCompleteCaseVerdictInputCommand => {
  return { verdictType: VerdictType.COMPLETE_CASE };
};

export const toStandardCancelledVerdictInputCommand = (
  formValues: StandardCancelledVerdictInputFormValues,
): StandardCancelledVerdictInputCommand => {
  return { verdictType: VerdictType.CANCELLED, cancellationComment: trim(formValues.cancellationComment) };
};

export const toStandardRejectedVerdictInputCommand = (
  formValues: StandardRejectedVerdictInputFormValues,
): StandardRejectedVerdictInputCommand => {
  return { verdictType: VerdictType.REJECTED, rejectionReason: formValues.rejectionReason! };
};
