import {
  SocieteMereAcquirerType,
  SocieteMereAgreementType,
  SocieteMereDemandRequesterSituation,
  SocieteMereNatureType,
} from './societeMereDemandTypes';
import { SocieteMereCompany } from '../security/types';
import { UserCompany } from '@eogile/agrements-common/src/user/commonTypes';
import { DemandStatus } from '@eogile/agrements-common/src/demand/demandTypes';

export const societeMereAgreementTypeLabels: Record<SocieteMereAgreementType, string> = {
  [SocieteMereAgreementType.PCOU]: 'Propriétaire couleurs',
  [SocieteMereAgreementType.ASSO]: 'Associé',
  [SocieteMereAgreementType.BAIL]: 'Bailleur',
  [SocieteMereAgreementType.ELEV]: 'Éleveur-Bailleur',
  [SocieteMereAgreementType.ENPU]: 'Entraîneur public',
  [SocieteMereAgreementType.ENPA]: 'Entraîneur particulier',
  [SocieteMereAgreementType.ENPE]: "Permis d'entraîner",
  [SocieteMereAgreementType.AUEN]: "Autorisation d'entraînement",
  [SocieteMereAgreementType.JOCK]: 'Jockey',
  [SocieteMereAgreementType.JJOC]: 'Jeune jockey',
  [SocieteMereAgreementType.APPR]: 'Apprenti',
  [SocieteMereAgreementType.STAG]: 'Stagiaire',
  [SocieteMereAgreementType.CAVL]: 'Cavalier',
  [SocieteMereAgreementType.GENR]: 'Gentleman rider',
  [SocieteMereAgreementType.CAVA]: 'Cavaliere',
  [SocieteMereAgreementType.MAND]: 'Mandataire',
  [SocieteMereAgreementType.PORP]: 'Porteur de parts',
  [SocieteMereAgreementType.GERA]: 'Gérant de société',
  [SocieteMereAgreementType.A]: 'Associé / Co-loc',
  [SocieteMereAgreementType.AE]: 'Autorisation d’entraîner / Entraîneur Particulier',
  [SocieteMereAgreementType.AL]: 'Apprenti / Lad',
  [SocieteMereAgreementType.EP]: 'Entraîneur public',
  [SocieteMereAgreementType.JA]: 'Jockey amateur',
  [SocieteMereAgreementType.JP]: 'Jockey professionnel',
  [SocieteMereAgreementType.L]: 'Bailleur',
  [SocieteMereAgreementType.P]: 'Propriétaire',
  [SocieteMereAgreementType.PE]: 'Permis d’entraîner',
  [SocieteMereAgreementType.PP]: 'Porteur de parts',
};

export const societeMereNatureTypeLabels: Record<SocieteMereNatureType, string> = {
  [SocieteMereNatureType.FIRST_DEMAND]: '1ere demande',
  [SocieteMereNatureType.RE_EXAMINATION]: 'Ré-examen',
  [SocieteMereNatureType.QUALITY_CHANGE]: 'Changement de qualité',
  [SocieteMereNatureType.CANCELLATION]: 'Annulation',
};

export const societeMereAcquirerTypeLabels: Record<SocieteMereAcquirerType, string> = {
  [SocieteMereAcquirerType.COMPANY]: 'Personne morale',
  [SocieteMereAcquirerType.PERSON]: 'Personne physique',
};

export const societeMereDemandRequesterSituationLabels: Record<SocieteMereDemandRequesterSituation, string> = {
  [SocieteMereDemandRequesterSituation.SINGLE]: 'Célibataire',
  [SocieteMereDemandRequesterSituation.PACS]: 'Pacsé',
  [SocieteMereDemandRequesterSituation.MARRIED]: 'Marié',
  [SocieteMereDemandRequesterSituation.DIVORCED]: 'Divorcé',
  [SocieteMereDemandRequesterSituation.WIDOWED]: 'Veuf(ve)',
};

export const displaySocieteMereAgreementType = (agreementType: SocieteMereAgreementType, withCode: boolean = false) => {
  let code = '';
  if (withCode && franceGalopAgreementTypes.includes(agreementType)) {
    code = 'France Galop - ';
  }

  if (withCode && leTrotAgreementTypes.includes(agreementType)) {
    code = 'Le Trot - ';
  }

  return code + societeMereAgreementTypeLabels[agreementType];
};

export const leTrotAgreementTypes: SocieteMereAgreementType[] = [
  SocieteMereAgreementType.A,
  SocieteMereAgreementType.AE,
  SocieteMereAgreementType.AL,
  SocieteMereAgreementType.EP,
  SocieteMereAgreementType.JA,
  SocieteMereAgreementType.JP,
  SocieteMereAgreementType.L,
  SocieteMereAgreementType.P,
  SocieteMereAgreementType.PE,
  SocieteMereAgreementType.PP,
];

export const franceGalopAgreementTypes: SocieteMereAgreementType[] = [
  SocieteMereAgreementType.PCOU,
  SocieteMereAgreementType.ASSO,
  SocieteMereAgreementType.BAIL,
  SocieteMereAgreementType.ELEV,
  SocieteMereAgreementType.ENPU,
  SocieteMereAgreementType.ENPA,
  SocieteMereAgreementType.ENPE,
  SocieteMereAgreementType.AUEN,
  SocieteMereAgreementType.JOCK,
  SocieteMereAgreementType.JJOC,
  SocieteMereAgreementType.APPR,
  SocieteMereAgreementType.STAG,
  SocieteMereAgreementType.CAVL,
  SocieteMereAgreementType.GENR,
  SocieteMereAgreementType.CAVA,
  SocieteMereAgreementType.MAND,
  SocieteMereAgreementType.PORP,
  SocieteMereAgreementType.GERA,
];

export const agreementTypesForCompany = (company: SocieteMereCompany) => {
  return company === UserCompany.LE_TROT ? leTrotAgreementTypes : franceGalopAgreementTypes;
};

export const allSocieteMereDemandStatuses = Object.values(DemandStatus).filter(
  (s) => s !== DemandStatus.COMPLETE_CASE_VERDICT,
);
