import { SectionCard } from '@eogile/agrements-common/src/components/cards/SectionCard';
import { generalInformationSectionTitle } from '@eogile/agrements-common/src/demand/demandFormUtils';
import { SocieteMereDemand, SocieteMereNatureType } from '../societeMereDemandTypes';
import { DemandCurrentStatus } from '@eogile/agrements-common/src/demand/components/DemandCurrentStatus';
import { FormGridItem } from '@eogile/agrements-common/src/components/grid/FormGridItem';
import { EnumSelectAdapter } from '@eogile/agrements-common/src/form/components/EnumSelectAdapter';
import TextFieldAdapter from '@eogile/agrements-common/src/form/components/TextFieldAdapter';
import { societeMereAgreementTypeLabels, societeMereNatureTypeLabels } from '../societeMereDemandUtils';
import CheckboxAdapter from '@eogile/agrements-common/src/form/components/CheckboxAdapter';
import { FormGridContainer } from '@eogile/agrements-common/src/components/grid/FormGridContainer';
import { FormGridContainersStack } from '@eogile/agrements-common/src/components/grid/FormGridContainersStack';
import { ReferralDemandFormGridContainer } from '@eogile/agrements-common/src/demand/components/ReferralDemandFormGridContainer';
import { useSocieteMereAgreementTypeOptionsFormHelper } from '../hooks/useSocieteMereAgreementTypeOptionsFormHelper';
import { useAuthenticatedCurrentUser } from '@eogile/agrements-common/src/security/CurrentUserContext';
import { isMinistryUser } from '@eogile/agrements-common/src/user/userUtils';

type Props = {
  demand: SocieteMereDemand | undefined;
};

export const SocieteMereDemandGeneralInformationSection = ({ demand }: Props) => {
  const { agreementTypeOptions } = useSocieteMereAgreementTypeOptionsFormHelper(demand);
  const { userDetails } = useAuthenticatedCurrentUser();

  return (
    <SectionCard title={generalInformationSectionTitle}>
      <FormGridContainersStack>
        <DemandCurrentStatus demand={demand} showDemandCompany={isMinistryUser(userDetails)} />
        <FormGridContainer>
          <FormGridItem>
            <EnumSelectAdapter
              name="agreementType"
              label="Type d'agrément"
              required
              options={agreementTypeOptions}
              optionName={(opt) => societeMereAgreementTypeLabels[opt]}
            />
          </FormGridItem>
        </FormGridContainer>
        <ReferralDemandFormGridContainer />
        <FormGridContainer>
          <FormGridItem>
            <EnumSelectAdapter
              name="nature"
              label="Nature"
              options={Object.values(SocieteMereNatureType)}
              optionName={(opt) => societeMereNatureTypeLabels[opt]}
            />
          </FormGridItem>
          <FormGridItem>
            <TextFieldAdapter name="societeMereReference" label="Référence société mère" required cssUppercase />
          </FormGridItem>
          <FormGridItem>
            <CheckboxAdapter label="Autres autorisations (obtenue ou en-cours)" name="otherDemandExists" />
          </FormGridItem>
        </FormGridContainer>
      </FormGridContainersStack>
    </SectionCard>
  );
};
