import React from 'react';
import { Box, Typography } from '@mui/material';
import { getAppBuildDateTime, getAppCommitId, getAppVersion } from '../../../config/metadata/metadata';

const titleMessage = (() => {
  const commitId = getAppCommitId();
  const buildMoment = getAppBuildDateTime();
  if (commitId === null || buildMoment === null) {
    return '';
  }
  return commitId + ' - ' + buildMoment.format('DD/MM/YYYY HH:mm:ss');
})();

const Version = () => {
  return (
    <Box
      sx={{
        padding: 1,
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Typography variant="body2" title={titleMessage}>
        {getAppVersion()}
      </Typography>
    </Box>
  );
};

export default Version;
