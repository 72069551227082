import { MinistryTerritorialArea } from './ministryUserTypes';

export const ministryTerritorialAreaLabels: Readonly<Record<MinistryTerritorialArea, string>> = {
  [MinistryTerritorialArea.DTPJ_AJACCIO]: 'DTPJ-AJACCIO',
  [MinistryTerritorialArea.DTPJ_ANGERS]: 'DTPJ-ANGERS',
  [MinistryTerritorialArea.DTPJ_CLERMONT_FERRAND]: 'DTPJ-CLERMONT FERRAND',
  [MinistryTerritorialArea.DTPJ_DIJON]: 'DTPJ-DIJON',
  [MinistryTerritorialArea.DTPJ_LIMOGES]: 'DTPJ-LIMOGES',
  [MinistryTerritorialArea.DTPJ_MONTPELLIER]: 'DTPJ-MONTPELLIER',
  [MinistryTerritorialArea.DTPJ_NANCY]: 'DTPJ-NANCY',
  [MinistryTerritorialArea.DTPJ_NANTES]: 'DTPJ-NANTES',
  [MinistryTerritorialArea.DTPJ_ORLEANS]: 'DTPJ-ORLEANS',
  [MinistryTerritorialArea.DTPJ_REIMS]: 'DTPJ-REIMS',
  [MinistryTerritorialArea.DTPJ_ROUEN]: 'DTPJ-ROUEN',
  [MinistryTerritorialArea.DTPJ_TOULOUSE]: 'DTPJ-TOULOUSE',
  [MinistryTerritorialArea.DTPN_GUYANE]: 'DTPN-GUYANE',
  [MinistryTerritorialArea.DTPN_LA_REUNION]: 'DTPN-LA REUNION',
  [MinistryTerritorialArea.DTPN_MAYOTTE]: 'DTPN-MAYOTTE',
  [MinistryTerritorialArea.DTPN_NOUVELLE_CALEDONIE]: 'DTPN-NOUVELLE-CALEDONIE',
  [MinistryTerritorialArea.DZPJ_ANTILLES]: 'DZPJ-ANTILLES',
  [MinistryTerritorialArea.DZPJ_EST_STRASBOURG]: 'DZPJ-EST-STRASBOURG',
  [MinistryTerritorialArea.DZPJ_NORD_LILLE]: 'DZPJ-NORD-LILLE',
  [MinistryTerritorialArea.DZPJ_OUEST_RENNES]: 'DZPJ-OUEST-RENNES',
  [MinistryTerritorialArea.DZPJ_SUD_EST_LYON]: 'DZPJ-SUD-EST-LYON',
  [MinistryTerritorialArea.DZPJ_SUD_MARSEILLE]: 'DZPJ-SUD-MARSEILLE',
  [MinistryTerritorialArea.DZPJ_SUD_OUEST_BORDEAUX]: 'DZPJ-SUD-OUEST-BORDEAUX',
  [MinistryTerritorialArea.POLYNESIE_FRANCAISE]: 'POLYNESIE-FRANCAISE',
  [MinistryTerritorialArea.SCCJ_PARIS]: 'SCCJ-PARIS',
  [MinistryTerritorialArea.SPJ_ANNECY]: 'SPJ-ANNECY',
  [MinistryTerritorialArea.SPJ_BAYONNNE]: 'SPJ-BAYONNNE',
  [MinistryTerritorialArea.SPJ_CAEN]: 'SPJ-CAEN',
  [MinistryTerritorialArea.SPJ_CHAMBERY]: 'SPJ-CHAMBERY',
  [MinistryTerritorialArea.SPJ_COQUELLES]: 'SPJ-COQUELLES',
  [MinistryTerritorialArea.SPJ_CREIL]: 'SPJ-CREIL',
  [MinistryTerritorialArea.SPJ_LA_ROCHELLE]: 'SPJ-LA ROCHELLE',
  [MinistryTerritorialArea.SPJ_METZ]: 'SPJ-METZ',
  [MinistryTerritorialArea.SPJ_NICE]: 'SPJ-NICE',
  [MinistryTerritorialArea.SPJ_PERPIGNAN]: 'SPJ-PERPIGNAN',
  [MinistryTerritorialArea.SPJ_QUIMPER]: 'SPJ-QUIMPER',
  [MinistryTerritorialArea.SPJ_SAINT_ETIENNE]: 'SPJ-SAINT-ETIENNE',
  [MinistryTerritorialArea.SPJ_TOULON]: 'SPJ-TOULON',
};
