import React from 'react';
import { User } from '../../usersTypes';
import TableCell from '../../../components/table/TableCell';
import dayjs from 'dayjs';

type Props = {
  user: User;
};

const UserUpdatedAtTableCell = ({ user }: Props) => {
  return <TableCell>{dayjs(user.updatedAt).format('DD/MM/YYYY HH:mm')}</TableCell>;
};

export default UserUpdatedAtTableCell;
