import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useState } from 'react';
import { Demand } from '../../../demandTypes';
import SecondaryActionButton from '../../../../components/buttons/SecondaryActionButton';
import MainActionButton from '../../../../components/buttons/MainActionButton';
import ApiHelper from '../../../../api/apiHelper';
import useSnackbar from '../../../../snackbar/useSnackbar';
import {
  getDemandValidationErrorSnackbar,
  getDemandValidationSuccessSnackbar,
} from '../../../../snackbar/snackbarUtils';
import { DemandAttachmentsValidationSummary } from './DemandAttachmentsValidationSummary';

type DemandValidationDialogProps<T extends Demand> = {
  demand: T;
  refreshDemand: () => void;
};

export const DemandValidationButton = <T extends Demand>({ demand, refreshDemand }: DemandValidationDialogProps<T>) => {
  const [dialogOpened, setDialogOpened] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const closeDialog = () => setDialogOpened(false);
  const confirmValidation = async () => {
    try {
      await ApiHelper.post(`/api/demands/${demand.id}/validate`, undefined);
      enqueueSnackbar(getDemandValidationSuccessSnackbar());
      closeDialog();
      refreshDemand();
    } catch (e) {
      enqueueSnackbar(getDemandValidationErrorSnackbar());
      Sentry.captureException(e);
    }
  };

  return (
    <>
      <MainActionButton dataTestId="validation-button" action={() => setDialogOpened(true)} label="Valider" />
      <Dialog open={dialogOpened} onClose={closeDialog} fullWidth>
        <DialogTitle>Validation de la demande</DialogTitle>
        <DialogContent>
          <DemandAttachmentsValidationSummary demand={demand} />
        </DialogContent>
        <DialogActions>
          <SecondaryActionButton label="Annuler" action={closeDialog} />
          <MainActionButton label="Valider" action={confirmValidation} />
        </DialogActions>
      </Dialog>
    </>
  );
};
