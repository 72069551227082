import { useForm } from 'react-final-form';
import { useVerdictTypeFormValue } from '@eogile/agrements-common/src/demand/components/actions/verdictinput/useVerdictTypeFormValue';
import { useEffect } from 'react';
import { VerdictType } from '@eogile/agrements-common/src/demand/demandTypes';
import { SocieteMereDemand } from '../../../societeMereDemandTypes';
import { subjectToIncomeAgreementTypes } from './societeMereVerdictInputFormUtils';

export const useReferenceTaxIncomeVerdictFormHelper = (demand: SocieteMereDemand) => {
  const { change } = useForm();
  const currentVerdictType = useVerdictTypeFormValue().value;
  const supportsReferenceTaxIncomeField = Boolean(
    currentVerdictType &&
      [VerdictType.GRANTED, VerdictType.REJECTED].includes(currentVerdictType) &&
      subjectToIncomeAgreementTypes.includes(demand.agreementType),
  );

  useEffect(() => {
    if (!supportsReferenceTaxIncomeField) {
      change('referenceTaxIncome', undefined);
    }
  }, [change, supportsReferenceTaxIncomeField]);

  return { supportsReferenceTaxIncomeField };
};
