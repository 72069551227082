import { Card, CardContent, getTableCellUtilityClass, styled, Table, TableBody, TableContainer } from '@mui/material';
import React from 'react';
import { PaginationAndSortCommand } from '@eogile/agrements-common/src/components/table/tableTypes';
import { PaginatedListResponse } from '@eogile/agrements-common/src/api/apiTypes';
import TableCell from '@eogile/agrements-common/src/components/table/TableCell';
import TablePagination from '@eogile/agrements-common/src/components/table/TablePagination';
import SocieteMereDemandsTableHeader from './SocieteMereDemandsTableHeader';
import {
  SocieteMereDemandSearchCommand,
  SocieteMereDemandsSortableFields,
  SocieteMereSimpleDemandView,
} from '../societeMereDemandListTypes';
import { displaySocieteMereAgreementType, societeMereNatureTypeLabels } from '../societeMereDemandUtils';
import { demandStatusLabels } from '@eogile/agrements-common/src/demand/demandUtils';
import dayjs from 'dayjs';
import { formatLocalDateForDisplay } from '@eogile/agrements-common/src/utils/dateUtils';
import { DemandListTableHeader } from '@eogile/agrements-common/src/demand/components/DemandListTableHeader';
import { DemandTableRow } from '@eogile/agrements-common/src/components/table/DemandTableRow';
import { useAuthenticatedCurrentUser } from '@eogile/agrements-common/src/security/CurrentUserContext';
import { isMinistryUser, userCompanyLabels } from '@eogile/agrements-common/src/user/userUtils';

type DemandsTableProps = {
  apiParams: SocieteMereDemandSearchCommand;
  data: PaginatedListResponse<SocieteMereSimpleDemandView>;
  onCommandChange: (newCommand: PaginationAndSortCommand<SocieteMereDemandsSortableFields>) => void;
  command: PaginationAndSortCommand<SocieteMereDemandsSortableFields>;
};

const StyledTable = styled(Table)(() => ({
  [`& .${getTableCellUtilityClass('root')}`]: {
    padding: '5px',
    fontSize: '13px',
  },
}));

const SocieteMereDemandsTableCard = ({ apiParams, data, command, onCommandChange }: DemandsTableProps) => {
  const { userDetails } = useAuthenticatedCurrentUser();
  const { numberOfResults, results } = data;

  if (numberOfResults === 0) {
    return (
      <Card data-testid="table-no-result">
        <DemandListTableHeader numberOfResults={numberOfResults} apiParams={apiParams} />
      </Card>
    );
  }

  return (
    <Card>
      <DemandListTableHeader numberOfResults={numberOfResults} apiParams={apiParams} />
      <CardContent>
        <TableContainer>
          <StyledTable size="small">
            <SocieteMereDemandsTableHeader command={command} onCommandChange={onCommandChange} />
            <TableBody>
              {results &&
                results.map((demandDetailsView) => (
                  <DemandTableRow key={demandDetailsView.id} demand={demandDetailsView}>
                    {isMinistryUser(userDetails) && (
                      <TableCell>{userCompanyLabels[demandDetailsView.company]}</TableCell>
                    )}
                    <TableCell>{demandDetailsView.chronoNumber}</TableCell>
                    <TableCell breakWord>{demandDetailsView.denominationName}</TableCell>
                    <TableCell breakWord>{demandDetailsView.requesterLastName}</TableCell>
                    <TableCell breakWord>{demandDetailsView.requesterFirstName}</TableCell>
                    <TableCell>
                      {demandDetailsView.requesterBirthDate
                        ? formatLocalDateForDisplay(dayjs(demandDetailsView.requesterBirthDate))
                        : ''}
                    </TableCell>
                    <TableCell>{demandDetailsView.requesterAddressCountry}</TableCell>
                    <TableCell breakWord>{displaySocieteMereAgreementType(demandDetailsView.agreementType)}</TableCell>
                    <TableCell>{societeMereNatureTypeLabels[demandDetailsView.nature]}</TableCell>
                    <TableCell>
                      {demandDetailsView.validationDate
                        ? formatLocalDateForDisplay(dayjs(demandDetailsView.validationDate))
                        : ''}
                    </TableCell>
                    <TableCell>
                      {demandDetailsView.receptionDate
                        ? formatLocalDateForDisplay(dayjs(demandDetailsView.receptionDate))
                        : ''}
                    </TableCell>
                    <TableCell>{demandStatusLabels[demandDetailsView.status]}</TableCell>
                    <TableCell>
                      {demandDetailsView.verdictDate
                        ? formatLocalDateForDisplay(dayjs(demandDetailsView.verdictDate))
                        : ''}
                    </TableCell>
                  </DemandTableRow>
                ))}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TablePagination<SocieteMereDemandsSortableFields>
          numberOfResults={numberOfResults}
          onCommandChange={onCommandChange}
          command={command}
        />
      </CardContent>
    </Card>
  );
};

export default SocieteMereDemandsTableCard;
