import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, styled, Typography } from '@mui/material';
import MainActionButton from '../../../components/buttons/MainActionButton';
import UserPasswordRules from '../../password/components/UserPasswordRules';
import SecondaryActionButton from '../../../components/buttons/SecondaryActionButton';

type Props = {
  onClose: () => void;
  plainTextPassword: string | null;
};

const PasswordDiv = styled('div')({
  border: '1px solid black',
  borderRadius: '5px',
  margin: '10px',
  padding: '10px',
  display: 'flex',
  justifyContent: 'space-between',
});

const UserGeneratedPasswordDialog = ({ onClose, plainTextPassword }: Props) => {
  const copyPassword = async () => {
    await navigator.clipboard.writeText(plainTextPassword!);
  };

  return (
    <Dialog open={Boolean(plainTextPassword)} onClose={onClose}>
      <DialogTitle>Mot de passe de l'utilisateur</DialogTitle>
      <DialogContent>
        <Typography>
          Notez bien ce mot de passe, il sera demandé à votre utilisateur lors de sa première connexion à l'application.
        </Typography>

        <PasswordDiv>
          <Typography variant="h5">{plainTextPassword}</Typography>
          <SecondaryActionButton label="Copier" action={copyPassword} />
        </PasswordDiv>

        <UserPasswordRules />
      </DialogContent>
      <DialogActions>
        <MainActionButton label="Fermer" action={onClose} />
      </DialogActions>
    </Dialog>
  );
};

export default UserGeneratedPasswordDialog;
