import React from 'react';
import { User } from '../../usersTypes';
import TableCell from '../../../components/table/TableCell';

type Props = {
  user: User;
};

const UserLastNameTableCell = ({ user }: Props) => {
  return <TableCell>{user.lastName}</TableCell>;
};

export default UserLastNameTableCell;
